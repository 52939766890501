import { setNestedObjectValues, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { checkKeyDown, FFormDebug, UPLOAD_RESTRICTION_ERROR_MESSAGE } from 'utils/FormUtils';
import { YMainExtension } from './ValidationStructure/YMainExtension';
import { FormCompanyData } from './FormCompanyData';
import { FormExpertList } from './FormExpertList';
import { FormWrapper } from 'components/Form/Wrapper/MyData/FormWrapper';
import { FormSubmitSection } from 'components/Form/FormSubmitSection';
import { replaceInstallerDateWithBool } from 'utils/DataUtils';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { getTenantId, TENANT_ID } from 'utils/tenantUtil';
import { FormCraftsChamberData } from './FormCraftsChamberData';
import { FormChangesInput } from './FormChangesInput';
import { FormBusinessData } from './FormBusinessData';
import { FormCommercialRegisterData } from './FormCommercialRegisterData';
import { FormGeneralInformationData } from '../Form/FormGeneralInformationData';
import { FormDocumentsUpload } from 'components/Form/FormDocumentsUpload';
import { FormDataPolicyData } from 'components/Form/FormDataPolicyData';
import { CONSTANTS } from 'global/constants';
import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { FormPopup } from 'components/FormPopup/FormPopup';

export const MainExtensionForm = ({
  initialValues,
  onSubmit,
  buttonDisabled,
  response,
  openSubmitPopup,
  setOpenSubmitPopup,
}) => {
  const [extraDocuments, setExtraDocuments] = useState([]);
  const [uploadRestrictionError, setUploadRestrictionError] = useState();

  const onSubmitForm = (values) => {
    const formData = {
      ...values,
      installers: values.installers
        .map((installer) => {
          const installerData = {
            firstname: installer.firstname,
            lastname: installer.lastname,
            birthdate: getTenantId() === TENANT_ID.BAG ? installer.birthdate : undefined,
          };

          if (installer.isDeleted) {
            installerData.isDeleted = true;
            installerData.deletedReason = installer.deletedReason;
            installerData.deletedDate = installer.deletedDate;
            return installerData;
          }

          return installerData;
        })
        .filter((installer) => installer !== null),
      documents: extraDocuments.map((file) => ({
        base64: file.base64.split(',')[1],
        contentType: file.contentType,
      })),
    };

    return onSubmit(formData);
  };

  const onDeleteSwitch = (index, e) => {
    handleChange(e);
  };

  const formik = useFormik({
    initialValues: {
      ...replaceInstallerDateWithBool(initialValues),
      hasChanges: false,
      changes: '',
    },
    validationSchema: YMainExtension,
    onSubmit: onSubmitForm,
  });

  const {
    isValid,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    handleSubmit,
    setTouched,
  } = formik;

  const onResetForm = () => {
    setExtraDocuments([]);
    resetForm();
    window.scrollTo(0, 0);
  };

  // needed to set touched on fields that have errors on mount (via API data)
  useEffect(() => {
    setTouched(setNestedObjectValues(errors, true));
  }, [errors]);

  useEffect(() => {
    const totalExtraDocumentsSize = extraDocuments.reduce((acc, curr) => acc + curr.size, 0);
    const numberOfFiles = extraDocuments.length;

    if (numberOfFiles > CONSTANTS.MAX_UPLOAD_FILE_COUNT) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_COUNT);
    } else if (totalExtraDocumentsSize > CONSTANTS.MAX_UPLOAD_TOTAL_FILE_SIZE) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_FILE_SIZE);
    } else {
      setUploadRestrictionError(undefined);
    }
  }, [extraDocuments]);

  return (
    <form onKeyDown={checkKeyDown} onSubmit={handleSubmit}>
      <FormWrapper>
        <FormSectionWrapper>
          <FormFieldWrapper className="main-extension__min-height-70">
            <ThemedTextField
              fullWidth
              disabled
              label="Installateurnummer"
              variant="standard"
              value={values.companyId}
            />
          </FormFieldWrapper>
        </FormSectionWrapper>
        {getTenantId() === TENANT_ID.BAG && <FormGeneralInformationData values={values} />}
        <FormCompanyData values={values} />
        {getTenantId() === TENANT_ID.BAG && <FormCraftsChamberData values={values} />}
        {getTenantId() === TENANT_ID.BAG && <FormBusinessData values={values} />}
        {getTenantId() === TENANT_ID.BAG && <FormCommercialRegisterData values={values} />}
        <FormExpertList
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          onDeleteSwitch={onDeleteSwitch}
        />
        <FormChangesInput
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
        />

        <FormDocumentsUpload files={extraDocuments} setFiles={setExtraDocuments} />
        <FormDataPolicyData values={values} handleBlur={handleBlur} handleChange={handleChange} />
        {response && <div className="main-extension__response-message">{response}</div>}
        {uploadRestrictionError && <FormErrorMessage>{uploadRestrictionError}</FormErrorMessage>}
        <FormSubmitSection
          onResetClicked={onResetForm}
          resetDisabled={isSubmitting}
          submitDisabled={!isValid || isSubmitting || buttonDisabled || uploadRestrictionError}
        />

        <FormPopup
          openSubmitPopup={openSubmitPopup}
          setOpenSubmitPopup={setOpenSubmitPopup}></FormPopup>
        <FFormDebug values={values} errors={errors} touched={touched} />
      </FormWrapper>
    </form>
  );
};

MainExtensionForm.displayName = 'MainExtensionForm';

MainExtensionForm.propTypes = {
  initialValues: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    companyName1: PropTypes.string.isRequired,
    companyName2: PropTypes.string.isRequired,
    companyName3: PropTypes.string.isRequired,
    companyOwner: PropTypes.string.isRequired,
    companyStreet: PropTypes.string.isRequired,
    companyHouseNumber: PropTypes.string.isRequired,
    companyPostal: PropTypes.string.isRequired,
    companyCity: PropTypes.string.isRequired,
    companyRegion: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    isAddressPublic: PropTypes.bool.isRequired,
    canSendMail: PropTypes.bool.isRequired,
    limitationGas: PropTypes.string.isRequired,
    limitationEnergy: PropTypes.string.isRequired,
    craftsChamberArea: PropTypes.string.isRequired,
    craftsChamberNumber: PropTypes.string.isRequired,
    craftsChamberDate: PropTypes.string.isRequired,
    businessRegistrationLocation: PropTypes.string.isRequired,
    businessRegistrationDate: PropTypes.string.isRequired,
    commercialRegisterNumber: PropTypes.string.isRequired,
    commercialRegisterExceptDate: PropTypes.string.isRequired,
    installers: PropTypes.arrayOf(
      PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        birthdate: PropTypes.string.isRequired,
        energyValidityDate: PropTypes.string,
        gasValidityDate: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  response: PropTypes.string,
  openSubmitPopup: PropTypes.bool,
  setOpenSubmitPopup: PropTypes.func,
};

MainExtensionForm.defaultProps = {};
