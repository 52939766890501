import { STAGE } from 'utils/StageUtils';
import { getStage } from 'utils/StageUtils';

export const EIV_ENDPOINT = {
  INSTALLER_INFO: () => `/api/external/installateur-info`,
  INSTALLER_BY_ID: (id) => `/api/external/installateure/${id}`,
  INSTALLER_CONTRACTS_BY_ID: (id) => `/api/external/installateure/${id}/vertraege`,
  INSTALLER_SEALS_BY_ID: (id) => `/api/external/installateure/${id}/plombierungen`,
  EXPERTS_BY_INSTALLER_ID: (id) => `/api/external/installateure/${id}/fachkraefte`,
  EINWILLIGUNG_BY_INSTALLER_ID: (id) => `/api/external/installateure/${id}/einwilligung`,
  CIAM_STATUS_BY_EMAIL: (email) => `/api/external/installateure/ciam-info-by-email/${email}`,
};

const EIV_BASE_URL = {
  DEVELOPMENT: 'https://eiv-api-dev.eon.com/eon-installer-directory-srv',
  STAGING: 'https://eiv-api-qa.eon.com/eon-installer-directory-srv',
  UAT: 'https://eiv-api-uat.eon.com/eon-installer-directory-srv',
  PRODUCTION: 'https://eiv-api.eon.com/eon-installer-directory-srv',
};

// Reason for this choice is the CIAM mapping
// CIAM DEV -> e-fix DEV -> EIV QA (& DEV)
// CIAM QA -> e-fix QA -> EIV UAT
// CIAM PROD -> e-fix PROD-> EIV PROD
export const getEIVApiBaseUrl = () => {
  let baseUrl = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      baseUrl = EIV_BASE_URL.STAGING;
      break;
    case STAGE.DEVELOPMENT:
      baseUrl = EIV_BASE_URL.STAGING;
      break;
    case STAGE.STAGING:
      baseUrl = EIV_BASE_URL.UAT;
      break;
    case STAGE.PRODUCTION:
      baseUrl = EIV_BASE_URL.PRODUCTION;
      break;
  }
  return baseUrl;
};

export const EIV_ENTRY_TYPE = {
  MAIN: 1,
  GUEST: 2,
};

// Note: status 5 and 6 are FE specific, not coming from EIV
export const EIV_STATUS = {
  NOT_SET: null,
  ARCHIVED: 1,
  INACTIVE: 2,
  IN_PROGRESS: 3,
  ACTIVE: 4,
  NEW: 5,
  DELETED: 6,
};

export const EIV_STATUS_TO_SHOW = [2, 3, 4, 5, 6];

export const EIV_STATUS_TO_STRING = {
  null: 'Nicht vorhanden',
  1: 'Archiviert',
  2: 'Inaktiv',
  3: 'In Bearbeitung',
  4: 'Aktiv',
  5: 'Neu hinzugefügt',
  6: 'Gelöscht',
};

export const EIV_OPERATING_MODE = {
  MAIN: 1,
  MAIN_AS_SIDE: 2,
  SIDE: 3,
  SIDE_AS_SIDE: 4,
  AUXILIARY: 5,
};

export const EIV_CHAR_LENGTH = {
  STANDARD_TEXT: 100,
  EMAIL: 64,
  FIRMENNAME1: 60,
  FIRMENNAME2: 60,
  FIRMENNAME3: 60,
  INHABER: 60,
  BUERO_ORTSTEIL: 32,
  BUERO_BUNDESLAND: 32,
  BUERO_LAND: 32,
  COMMUNICATION_EMAIL: 256,
  BUERO_STRASSE: 100,
  BUERO_HAUSNUMMER: 100,
  BUERO_PLZ: 100,
  BUERO_ORT: 100,
  TELEFON_GESCHAEFT: 100,
  TELEFON_WERKSTATT: 100,
  TELEFON_MOBIL: 100,
  FAX: 100,
  B_NETZBETREIBER_NAME: 400,
  AUSWEISNR_FREMD: 160,
  EXPERT_NAME: 32,
};

export const RESEAL_ALLOWED_CONTRACTS_IDS = {
  DEVELOPMENT: [1, 54],
  STAGING: [1, 54],
  UAT: [1, 54],
  PRODUCTION: [1, 52],
};

export const getResealAllowedIds = () => {
  let ids = [];
  switch (getStage()) {
    case STAGE.LOCAL:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.STAGING;
      break;
    case STAGE.DEVELOPMENT:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.STAGING;
      break;
    case STAGE.STAGING:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.UAT;
      break;
    case STAGE.PRODUCTION:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.PRODUCTION;
      break;
  }
  return ids;
};
