import { setNestedObjectValues } from 'formik';
import React, { useEffect, useState } from 'react';
import './DebForm.sass';
import PropTypes from 'prop-types';
import { InputRow } from 'components/DebFormComponents/InputRow';
import { FormSection } from 'components/DebFormComponents/FormSection';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import { InputField } from 'components/DebFormComponents/InputField';
import { ContractsTable } from 'components/DebFormComponents/Table/ContractsTable';
import { SealsTable } from 'components/DebFormComponents/Table/SealsTable';
import { ExpertTable } from 'components/DebFormComponents/Table/ExpertTable';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { parse, format } from 'date-fns';

import { useInstallerStatusSummary } from 'hooks/UseInstallerStatusSummary';
import {
  DEB_FIRST_PAGE_SECTIONS,
  shouldDebFirstPageSectionRender,
  checkErrors,
} from './Utils/DebFormSectionUtil';
import { AgreementSection } from 'components/DebFormComponents/AgreementSection';
import { ExtensionSection } from 'components/DebFormComponents/ExtensionSection';
import { FFormDebug, checkKeyDown } from 'utils/FormUtils';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';
import {
  BRANCH_TYPE,
  hasGuestBranchExtended,
  isBranchGuest,
  shouldDebFirstPageSectionFieldRender,
} from 'utils/DataUtils';
import { ERRORS } from './Utils/Errors';
import { useQuery } from '@apollo/client';
import { USER_STATUS_QUERY } from 'Queries/Queries';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { HintText } from 'components/HintText/HintText';

export const DebFormFirstPage = ({
  formik,
  initialValues,
  installer,
  energyAgreementLink,
  gasAgreementLink,
  setPage,
}) => {
  const [buttonErrorMessage, setButtonErrorMessage] = useState(ERRORS.DEFAULT);

  const { values, errors, setTouched, touched } = formik;
  const installerStatusSummary = useInstallerStatusSummary(installer);
  const tenantID = getTenantId();
  const { data: userStatusData, loading: userStatusLoading } = useQuery(USER_STATUS_QUERY, {
    gcTime: 0,
  });
  const userStatus = userStatusData && userStatusData.userStatus;

  // needed to set touched on fields that have errors on mount (via API data)
  useEffect(() => {
    setTouched(setNestedObjectValues(errors, true));
  }, [errors]);

  useEffect(() => {
    const requiredFields = formik.values.doesGasBranchExist + formik.values.doesEnergyBranchExist;
    // AVA extra postal code field is not considered a change but is a required field for GuestOnly
    const copiedValues = { ...values };
    delete copiedValues.avaGuestPostal;
    const error = checkErrors(copiedValues, initialValues, errors, requiredFields);
    setButtonErrorMessage(error);
  }, [values, errors]);

  useEffect(() => {
    if (isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)) {
      const hasExtended = hasGuestBranchExtended(
        formik.values.experts,
        initialValues.experts,
        BRANCH_TYPE.ENERGY
      );
      formik.setFieldValue('energyExtension', hasExtended);
    }
    if (isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)) {
      const hasExtended = hasGuestBranchExtended(
        formik.values.experts,
        initialValues.experts,
        BRANCH_TYPE.GAS
      );
      formik.setFieldValue('gasExtension', hasExtended);
    }
  }, [values.experts]);
  const cleanedDateString =
    userStatus &&
    userStatus.latestDebFormRequestDate &&
    userStatus.latestDebFormRequestDate.replace('T', ' ');
  if (userStatusLoading) {
    return <LoadingIndicator></LoadingIndicator>;
  } else
    return (
      <>
        <form
          onKeyDown={checkKeyDown}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="deb-form__first-page">
          <div className="deb-page">
            <div className="title-section">
              <PageTitle text="Eintragung bearbeiten und verlängern"></PageTitle>
              <div>
                Hier können Sie Ihre in unserem Installateurverzeichnis hinterlegten Daten
                bearbeiten und ggfs. auch Ihre Eintragung verlängern.
              </div>
              <br />
              {cleanedDateString && (
                <HintText>
                  <div>
                    Ihre letzte Anfrage wurde gesendet am{' '}
                    <b>
                      {format(
                        parse(cleanedDateString, 'yyyy-MM-dd HH:mm:ss.SSSSSS', new Date()),
                        'dd.MM.yyyy'
                      )}{' '}
                    </b>
                    um{' '}
                    <b>
                      {format(
                        parse(cleanedDateString, 'yyyy-MM-dd HH:mm:ss.SSSSSS', new Date()),
                        'HH:mm'
                      )}{' '}
                      Uhr
                    </b>
                  </div>
                </HintText>
              )}
            </div>
            {DEB_FIRST_PAGE_SECTIONS.map((section) => {
              if (shouldDebFirstPageSectionRender(section.id, installerStatusSummary)) {
                return (
                  <FormSection
                    key={section.id}
                    title={section.title}
                    subtitle={section.subtitle}
                    tooltip={section.tooltip}
                    navigationTooltip={section.navigationTooltip}>
                    <InputRow>
                      {section.fields.map((field) => {
                        return shouldDebFirstPageSectionFieldRender(
                          field.name,
                          installerStatusSummary
                        ) ? (
                          <InputField
                            infoText={field.infoText}
                            key={field.name}
                            name={field.name}
                            fullWidth
                            label={`${field.fieldName}${field.mandatory ? '*' : ''}`}
                            oldValue={initialValues[field.name]}
                            variant="standard"
                            disabled={field.readOnly}
                            value={values[field.name]}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors[field.name])}
                            helperText={formik.errors[field.name] || ''}
                          />
                        ) : null;
                      })}
                    </InputRow>
                  </FormSection>
                );
              } else return null;
            })}
            {tenantID === TENANT_ID.BAG && (
              <FormSection
                title="Zusatzverträge, Plombenzangen bzw. -stempel und Nüsse"
                subtitle={'Ausgegebene Plombenstempel/Plombenzangen/Nüsse'}>
                <SealsTable
                  data={values.seals ? values.seals : []}
                  oldData={initialValues.seals ? initialValues.seals : []}
                  formik={formik}></SealsTable>
                <div>
                  Sie haben noch weitere, von uns an Sie ausgegebene
                  Plombenstempel/Plombenzangen/Nüsse, die hier nicht aufgelistet sind? Dann teilen
                  Sie uns dies bitte beim nächsten Schritt in dem Kommentarfeld mit.
                </div>
              </FormSection>
            )}
            {[TENANT_ID.AVA, TENANT_ID.BAG].includes(tenantID) && (
              <FormSection subtitle={'Verträge'}>
                <ContractsTable
                  data={values.contracts ? values.contracts : []}
                  oldData={formik.initialValues.contracts ? formik.initialValues.contracts : []}
                  formik={formik}
                />
              </FormSection>
            )}
            <FormSection>
              <ExpertTable
                installer={installer}
                data={values.experts ? [...values.experts] : []}
                oldData={initialValues.experts ? initialValues.experts : []}
                formik={formik}></ExpertTable>
            </FormSection>
            <FormSection
              title={' Verlängerung der Eintragung im Installateurverzeichnis'}
              titleTooltip={`Gleichzeitig sind Sie bei folgenden Netzbetreibern mit einer Gasteintragung registriert:
              Avacon Netz GmbH, Bayernwerk Netz GmbH, E.DIS Netz GmbH, LEW Verteilnetz GmbH, Schleswig-Holstein Netz GmbH, ElbEnergie GmbH, NordNetz GmbH, HanseGas GmbH`}
              isExtension>
              <ExtensionSection formik={formik} installerStatusSummary={installerStatusSummary} />
            </FormSection>
            <FormSection>
              <AgreementSection
                formik={formik}
                installerStatusSummary={installerStatusSummary}
                energyAgreementLink={energyAgreementLink}
                gasAgreementLink={gasAgreementLink}></AgreementSection>
            </FormSection>
          </div>
          <FormActionButtons
            hasErrors={Boolean(buttonErrorMessage)}
            titleLeft="Zurücksetzen"
            titleRight="Weiter"
            errorMessage={buttonErrorMessage}
            onClickLeft={() => {
              formik.resetForm();
              window.scrollTo(0, 0);
            }}
            onClickRight={() => {
              setPage(1);
            }}
          />
          <FFormDebug values={values} errors={errors} touched={touched} />
        </form>
      </>
    );
};

DebFormFirstPage.displayName = 'DebFormFirstPage';

DebFormFirstPage.propTypes = {
  formik: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  installer: PropTypes.object.isRequired,
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  setPage: PropTypes.func.isRequired,
};

DebFormFirstPage.defaultProps = {};
