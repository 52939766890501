import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { TENANT_ID } from 'utils/tenantUtil';

const LOGO_SOURCES = {
  [TENANT_ID.EDI]: require('../../../assets/pics/logo_edis.png'),
  [TENANT_ID.SHNG]: require('../../../assets/pics/logo_sh-netz.png'),
  [TENANT_ID.AVA]: require('../../../assets/pics/logo_avacon.png'),
  [TENANT_ID.BAG]: require('../../../assets/pics/logo_bag.png'),
};

const TenantSwitchSelectionButton = ({ onClick, tenant, active }) => {
  const classes = classNames('tenant-switch-modal__tenant-button', {
    'tenant-switch-modal__tenant-button-active': active,
  });

  return (
    <div className={classes} onClick={onClick}>
      <img src={LOGO_SOURCES[tenant]} className="tenant-switch-modal__tenant-button-image" />
    </div>
  );
};

TenantSwitchSelectionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  tenant: PropTypes.oneOf([TENANT_ID.EDI, TENANT_ID.SHNG, TENANT_ID.AVA, TENANT_ID.BAG]).isRequired,
  active: PropTypes.bool,
};

export { TenantSwitchSelectionButton };
