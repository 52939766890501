import * as Yup from 'yup';
import { EIV_CHAR_LENGTH } from 'api/APIUtils';
// import { YContract } from './YContract';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';

export const YNEEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(VALIDATION_CONSTANTS.INVALID_EMAIL)
    .min(4, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EMAIL, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
});
