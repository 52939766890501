/* eslint-disable react/prop-types */
import { useLazyQuery } from '@apollo/client';
import CheckBox from '@material-ui/core/Checkbox';
import { Alert } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import React, { useEffect, useState } from 'react';

import { useToggle } from 'react-use';
import { errorMessage, successMessage } from '../../global/data/messageContent';

import MessageComponent from '../../components/MessageComponent';
import { ActionButton } from '../ActionButton/ActionButton';

import './contact-form.scss';
import { CONTACT_EXPERT_QUERY } from 'Queries/Queries';
import { useFormik } from 'formik';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { YContact } from './YContact';
import { FFormDebug } from 'utils/FormUtils';

const ContactForm = ({ modalclose, email, visibility }) => {
  const [openConfirm, toggleConfirm] = useToggle(false);
  const [status, setStatus] = useState(0);

  const handleMailSendSuccess = () => {
    visibility();
    setStatus(200);
    toggleConfirm(true);
  };

  const handleMailSendError = () => {
    setStatus(400);
  };

  const [sendMail] = useLazyQuery(CONTACT_EXPERT_QUERY, {
    onError: handleMailSendError,
    onCompleted: handleMailSendSuccess,
  });

  const onSubmitForm = (values) => {
    setStatus(0);

    const mailInput = {
      to: email,
      name: values.name,
      userTo: values.userTo,
      subject: 'e-fix: Anfrage Expertenkontakt',
      textPart: values.text,
      phone: values.phone,
      sendMail: values.sendMail,
    };

    sendMail({
      variables: {
        mailInput,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      userTo: '',
      text: '',
      phone: '',
      sendMail: false,
    },
    validationSchema: YContact,
    onSubmit: onSubmitForm,
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isValid,
    isSubmitting,
    validateForm,
  } = formik;

  useEffect(() => {
    (() => validateForm())();
  }, []);

  return (
    <>
      <form className="contact-form base-tenant-style" onSubmit={handleSubmit} data-testid="form">
        {status === 400 && (
          <Alert className="contact-form__alert contact-form__alert--error" severity="error">
            <div className="contact-form__alert-title">{errorMessage.title}</div>
            <span>{errorMessage.subtitle1}</span>
          </Alert>
        )}
        <div className="contact-form__input-container">
          <ThemedTextField
            fullWidth
            id={`text`}
            name={`text`}
            label="Meine Nachricht*"
            variant="standard"
            value={values.text}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.text && Boolean(errors.text)}
            helperText={touched.text && errors.text}
          />
          <ThemedTextField
            fullWidth
            id={`name`}
            name={`name`}
            label="Name*"
            variant="standard"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
          <ThemedTextField
            fullWidth
            id={`userTo`}
            name={`userTo`}
            label="Meine E-Mail Adresse*"
            variant="standard"
            value={values.userTo}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.userTo && Boolean(errors.userTo)}
            helperText={touched.userTo && errors.userTo}
          />
          <ThemedTextField
            fullWidth
            id={`phone`}
            name={`phone`}
            label="Meine Telefonnummer*"
            variant="standard"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </div>

        <hr className="contact-form__line" />
        <FormControlLabel
          className="contact-form__checkbox-label"
          control={
            <CheckBox
              size="medium"
              className="contact-form__checkbox"
              id="sendMail"
              name="sendMail"
              type="checkbox"
              value={values.sendMail}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          }
          label={'Ich möchte eine Bestätigungs-E-Mail erhalten.'}
        />

        <div className="contact-form__btns">
          <ActionButton
            title="Absenden"
            disabled={!isValid || isSubmitting}
            inverted
            className="contact-form__margin-right"
          />
          <ActionButton title="Abbrechen" type="button" onClick={modalclose} inverted />
        </div>
      </form>

      {status === 200 && (
        <MessageComponent
          email={email}
          data={successMessage}
          openConfirm={openConfirm}
          closeConfirm={toggleConfirm}
        />
      )}
      <FFormDebug values={values} errors={errors} touched={touched} />
    </>
  );
};

export default ContactForm;
