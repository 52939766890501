import * as Yup from 'yup';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';

export const YNEBranchSelectionValidation = Yup.object()
  .shape({
    isEnergyActive: Yup.boolean(),
    isGasActive: Yup.boolean(),
    companyTypeEnergy: Yup.string().when('isEnergyActive', {
      is: (isEnergyActive) => isEnergyActive === true,
      then: Yup.string().required(VALIDATION_CONSTANTS.MANDATORY),
    }),
    companyTypeGas: Yup.string().when('isGasActive', {
      is: (isGasActive) => isGasActive === true,
      then: Yup.string().required(VALIDATION_CONSTANTS.MANDATORY),
    }),
    branchTypeEnergy: Yup.string().when('isEnergyActive', {
      is: (isEnergyActive) => isEnergyActive === true,
      then: Yup.string().required(VALIDATION_CONSTANTS.MANDATORY),
    }),
    branchTypeGas: Yup.string().when('isGasActive', {
      is: (isGasActive) => isGasActive === true,
      then: Yup.string().required(VALIDATION_CONSTANTS.MANDATORY),
    }),
  })
  .test('checkAtLeastOneBranchSelected', null, (obj) => {
    if (obj.isEnergyActive || obj.isGasActive) {
      return true; // everything is fine
    }

    return new Yup.ValidationError('Please check one checkbox', null, 'myCustomFieldName');
  });
