const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const prepFilesForUpload = async (files) => {
  return Promise.all(
    Array.from(files).map(async (file) => ({
      name: file.name,
      contentType: file.type ? file.type : null,
      base64: await toBase64(file),
      size: file.size,
    }))
  );
};

function downloadPdfFromBase64(base64String, fileName) {
  // Convert base64 string to a Blob
  const binaryString = window.atob(base64String);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Create an object URL from the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and start the download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Release the object URL
  window.URL.revokeObjectURL(url);
}

export { toBase64, prepFilesForUpload, downloadPdfFromBase64 };
