export const CONSTANTS = {
  IMAGE_SIZE: 40,
  TAGS_EVENTS: {
    STROM: 'Strom',
    GAS: 'Gas',
    NEU: 'Neu',
    ONLINE: 'Online',
    KOSTENPFLICHTIG: 'Kostenpflichtig',
  },
  TAGS_ARTICLES: {
    STROM: 'Strom',
    GAS: 'Gas',
    NEU: 'Neu',
  },
  FREETEXT_INPUT: 500,
  ACCEPTED_FORM_INPUT_FILE_TYPES: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'application/pdf',
  ],
  MAX_UPLOAD_FILE_COUNT: 10,
  MAX_UPLOAD_TOTAL_FILE_SIZE: 5000000,
  OVERVIEW_LIST_TYPES: {
    event: 'event',
    article: 'article',
  },
  AEM_PAGE_TEMPLATE: {
    PUBLIC_PAGE: 'publicpage',
    CONTENT_PAGE: 'contentpage',
    HOMEPAGE: 'homepage',
    REACT_APP: 'react-app',
    ARTICLE: 'article',
    EVENT: 'event',
  },
};
