import * as Yup from 'yup';
import { YGuestInstaller } from './YGuestInstaller';
import { EIV_CHAR_LENGTH } from 'api/APIUtils';
import { CONSTANTS } from 'global/constants';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';

export const YGuestExtension = Yup.object().shape({
  companyName1: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyName2: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG),
  companyName3: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
  companyOwner: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
  companyStreet: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyHouseNumber: Yup.string()
    .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_HAUSNUMMER, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyPostal: Yup.string()
    .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_PLZ, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyCity: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_ORT, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyRegion: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_ORTSTEIL, VALIDATION_CONSTANTS.TOO_LONG)
    .nullable(),
  gridProviderEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG),
  foreignCompanyIdEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG),
  gridProviderGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG),
  foreignCompanyIdGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG),
  isAddressPublic: Yup.bool(),
  canSendMail: Yup.bool(),
  installers: Yup.array(YGuestInstaller),
  questionInput: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG),
});
export const YGuestExtensionAva = YGuestExtension.concat(
  Yup.object().shape({
    postalOfOrder: Yup.string()
      .length(5, 'Die Eingabe muss 5 Zeichen lang sein')
      .required(VALIDATION_CONSTANTS.MANDATORY),
  })
);
