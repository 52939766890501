import React, { useContext, useEffect, useMemo, useState } from 'react';

import './DataPolicy.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { DataPolicyContent } from './DataPolicyContent';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ActionButton } from 'components/ActionButton/ActionButton';
import {
  createPatchEinwilligungByInstallerIdData,
  patchEinwilligungByInstallerId,
} from 'api/installer/PatchEinwilligungByInstallerId';
import { DataPolicyPopup } from 'components/DataPolicyPopup/DataPolicyPopup';
import { useMutation } from '@apollo/client';
import { SET_SUBSCRIPTION_STATUS_MUTATION } from 'Queries/Mutations';
import { parseAEMDatapolicyProps } from 'utils/AEMPropUtils';
import PropTypes from 'prop-types';
import { booleanToGerman } from 'utils/DataUtils';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

export const DataPolicy = (props) => {
  const { isLoading: isInstallerLoading, installer, installerInfo } = useCurrentInstaller();
  const [dsgvoAddressPublic, setDsgvoAddressPublic] = useState(false);
  const [dsgvoCanSendMail, setDsgvoCanSendMail] = useState(false);
  const [response, setResponse] = useState('');
  const [openSubmitPopup, setOpenSubmitPopup] = useState(false);
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="DataPolicy" />;
  }
  const [setSubscriptionStatus] = useMutation(SET_SUBSCRIPTION_STATUS_MUTATION, {
    variables: { status: dsgvoCanSendMail },
  });

  useEffect(() => {
    if (installer) {
      if (installer.zu_adressveroeff && installer.zu_adressveroeff === 'Ja') {
        setDsgvoAddressPublic(true);
      }
      if (installer.zu_infoversand && installer.zu_infoversand === 'Ja') {
        setDsgvoCanSendMail(true);
      }
    }
  }, [installer]);

  useEffect(() => {
    setResponse('');
  }, [dsgvoAddressPublic, dsgvoCanSendMail]);

  const submitButtonDisabled = useMemo(() => {
    return (
      openSubmitPopup ||
      (booleanToGerman(dsgvoAddressPublic) == installer?.zu_adressveroeff &&
        booleanToGerman(dsgvoCanSendMail) == installer?.zu_infoversand)
    );
  }, [openSubmitPopup, dsgvoAddressPublic, dsgvoCanSendMail, installer]);
  const { setShowError } = useContext(ErrorHandlingContext);

  const onSubmitValues = async () => {
    try {
      const response = await patchEinwilligungByInstallerId(
        installer.id,
        createPatchEinwilligungByInstallerIdData(dsgvoAddressPublic, dsgvoCanSendMail, setShowError)
      );

      const subscriptionStatusResponse = await setSubscriptionStatus();

      if (response.errors || subscriptionStatusResponse.errors) {
        throw new Error(response.errors || subscriptionStatusResponse.errors);
      }
      setDsgvoAddressPublic(response.zu_adressveroeff === 'Ja');
      setDsgvoCanSendMail(response.zu_infoversand === 'Ja');
      setOpenSubmitPopup(true);
    } catch (e) {
      console.log(e);
      setResponse('Fehler beim Speichern');
    }
  };

  return (
    <div className="data-policy">
      {!installerInfo || isInstallerLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DataPolicyContent
            className="deb-page"
            dsgvoAddressPublic={dsgvoAddressPublic}
            dsgvoCanSendMail={dsgvoCanSendMail}
            dsgvoAddressPublicName="zu_adressveroeff"
            dsgvoCanSendMailName="zu_infoversand"
            onChangeDsvgoAddressPublic={(event) => {
              setDsgvoAddressPublic(event.target.checked);
            }}
            onChangeDsvgoCanSendMail={(event) => {
              setDsgvoCanSendMail(event.target.checked);
            }}
            documents={parseAEMDatapolicyProps(props)}
          />
          <div className="data-policy__response-message">{response}</div>
          <div className="data-policy__save-button-wrapper">
            <ActionButton
              disabled={submitButtonDisabled}
              title="Speichern"
              inverted
              type="button"
              onClick={onSubmitValues}
            />
          </div>
          <DataPolicyPopup
            openSubmitPopup={openSubmitPopup}
            setOpenSubmitPopup={setOpenSubmitPopup}
          />
        </>
      )}
    </div>
  );
};

DataPolicy.displayName = 'DataPolicy';

DataPolicy.propTypes = {
  datapolicyEDILink: PropTypes.string,
  datapolicyAVALink: PropTypes.string,
  datapolicySHNGLink: PropTypes.string,
  datapolicyBAGLink: PropTypes.string,
  datapolicyEENGLink: PropTypes.string,
  datapolicyHANSGLink: PropTypes.string,
  datapolicyNNNGLink: PropTypes.string,
  datapolicyEDILabel: PropTypes.string,
  datapolicyAVALabel: PropTypes.string,
  datapolicySHNGLabel: PropTypes.string,
  datapolicyBAGLabel: PropTypes.string,
  datapolicyEENGLabel: PropTypes.string,
  datapolicyHANSGLabel: PropTypes.string,
  datapolicyNNNGLabel: PropTypes.string,
};

DataPolicy.defaultProps = {};

MapTo(['e-fix/components/content/datapolicy'])(DataPolicy);
