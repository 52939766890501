import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';

const FormCommercialRegisterData = ({ values }) => {
  return (
    <div>
      <SubTitle text="Handelsregisterauszug" notFramed />
      <FormSectionWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="commercialRegisterNumber"
            name="commercialRegisterNumber"
            label="HRA/-B-Nummer und Amtsgericht"
            variant="standard"
            value={values.commercialRegisterNumber}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="commercialRegisterExceptDate"
            name="commercialRegisterExceptDate"
            label="Auszug vom"
            variant="standard"
            value={values.commercialRegisterExceptDate}
          />
        </FormFieldWrapper>
      </FormSectionWrapper>
    </div>
  );
};

FormCommercialRegisterData.propTypes = {
  values: PropTypes.shape({
    commercialRegisterNumber: PropTypes.string.isRequired,
    commercialRegisterExceptDate: PropTypes.string.isRequired,
  }).isRequired,
};

export { FormCommercialRegisterData };
