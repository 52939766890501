import React, { useEffect } from 'react';
import './SupportHotline.scss';
import { MapTo } from '@adobe/aem-react-editable-components';
import { PageTitleSection } from '../../PageTitleSection/PageTitleSection';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const CONTENT = {
  [TENANT_ID.AVA]: {
    title: 'mAP-Support-Hotline',
    description:
      'Bei Fragen rund um die Themen Registrierung, Log In und Passwort sowie bei allgemeinen technischen Problemen steht Ihnen unser mAP Support zur Verfügung.',
    schedule: [
      {
        key: 'Mo - Do',
        value: '07:30 - 16:00 Uhr',
      },
      {
        key: 'Fr',
        value: '07:30 - 14:00 Uhr',
      },
    ],
    contactPhoneNumberFormatted: '03022 0 22800',
    contactPhoneNumber: '+493022022800',
  },
  [TENANT_ID.BAG]: {
    title: 'Netzanschluss-Portal (mAP)',
    description:
      'Bei Fragen rund um die Themen Registrierung, Log In und Passwort sowie bei allgemeinen technischen Problemen steht Ihnen unser Netzanschluss-Portal (mAP) Support zur Verfügung.',
    schedule: [
      {
        key: 'Mo - Do',
        value: '07:30 - 16:00 Uhr',
      },
      {
        key: 'Fr',
        value: '07:30 - 14:00 Uhr',
      },
    ],
    contactPhoneNumberFormatted: '0941-8993699317',
    contactPhoneNumber: '+499418993699317',
  },
};

const SupportHotlineComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = CONTENT[getTenantId()];

  return (
    <div className=" expert-page supportHotlineComponent">
      <div className="expert-page-content-stage-text">
        <PageTitleSection title={data.title} subText={data.description} notFramed />

        <div className="schedule-wrapper">
          {data?.schedule.map((schedule) => (
            <div className="scheduleItem" key={schedule?.key}>
              <div className="scheduleKey">{schedule.key}</div>
              <div>{schedule.value}</div>
            </div>
          ))}
        </div>

        <div className="contactPhoneNumber">
          <a href={`tel:${data.contactPhoneNumber}`}>
            <span style={{ marginRight: 2 }}>T</span>
            {data?.contactPhoneNumberFormatted}
          </a>
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/supporthotline'])(SupportHotlineComponent);
