import * as Yup from 'yup';
import { YDate, YPastDate } from 'utils/YupUtils';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';
import { CONSTANTS } from 'global/constants';

export const YDebDeleteCompanyFormSchema = Yup.object().shape({
  deletedCheckbox: Yup.boolean()
    .required(VALIDATION_CONSTANTS.MANDATORY)
    .oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
  deleteDate: YDate,
  deleteReason: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG),
});
