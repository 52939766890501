import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';

const FormBusinessData = ({ values }) => {
  return (
    <div>
      <SubTitle text="Gewerbean-/ummeldung" notFramed />
      <FormSectionWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="businessRegistrationLocation"
            name="businessRegistrationLocation"
            label="Ort"
            variant="standard"
            value={values.businessRegistrationLocation}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="businessRegistrationDate"
            name="businessRegistrationDate"
            label="Ausstellungsdatum"
            variant="standard"
            value={values.businessRegistrationDate}
          />
        </FormFieldWrapper>
      </FormSectionWrapper>
    </div>
  );
};

FormBusinessData.propTypes = {
  values: PropTypes.shape({
    businessRegistrationLocation: PropTypes.string.isRequired,
    businessRegistrationDate: PropTypes.string.isRequired,
  }).isRequired,
};

export { FormBusinessData };
