/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './CardCollectionMyData.sass';
import CardComponent from '../../components/CardComponent';
import { Link } from 'react-router-dom';
import { parseCardsMyData } from '../../utils/CardDataUtils';
import { MapTo } from '@adobe/aem-react-editable-components';
import { useInstallerInfo } from 'context/InstallerInfoContext';
import { getInstallerById } from 'api/installer/GetInstallerById';
import { shouldShowMyDataCard } from 'utils/DataUtils';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Utils } from '@adobe/aem-react-editable-components';
import { PFLEGENDE_BU_ID_TO_NAME, isBUofCurrentTenant } from 'utils/tenantUtil';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const CardCollectionMyData = (props) => {
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="CardCollectionMyData" />;
  }

  const { installerInfo, isEIV } = useInstallerInfo();
  const [installerData, setInstallerData] = useState();

  const parsedCards = useMemo(() => parseCardsMyData(props.links), [props]);
  const { setShowError } = useContext(ErrorHandlingContext);

  useEffect(() => {
    (async () => {
      if (installerInfo) {
        try {
          const response = await getInstallerById(installerInfo.id, setShowError);
          setInstallerData(response);
        } catch (e) {
          console.log('myData installer info error', e);
        }
      }
    })();
  }, [installerInfo]);

  return (
    <div className="card-collection-my-data">
      <div className="mdl-grid card-collection-my-data__card-grid">
        {installerData ? (
          isBUofCurrentTenant(installerData.pflegende_bu) ? (
            parsedCards.map((card, index) => {
              if (shouldShowMyDataCard(card.id, installerData)) {
                return (
                  <Link key={index} to={card.linkedPage}>
                    <CardComponent
                      cardClass="search-pre-selection-card mdl-cell mdl-cell--4-col"
                      card={card}
                    />
                  </Link>
                );
              }
            })
          ) : (
            <SubTitle
              className="card-collection-my-data__sub-title-wrapper"
              text={`Nutzen Sie das Portal Ihres pflegenden Netzbetreibers ${
                PFLEGENDE_BU_ID_TO_NAME[installerData.pflegende_bu]
              }. Wählen Sie diesen über Portalwechsel aus.`}
            />
          )
        ) : (
          <SubTitle
            className="card-collection-my-data__sub-title-wrapper"
            text={
              isEIV === false
                ? 'Nutzen Sie das Installateurs-Login um Ihre Daten zu verwalten'
                : 'Lädt...'
            }
          />
        )}
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/cardcollectionMyData')(CardCollectionMyData);
