import React, { useEffect, useState } from 'react';
import './NEStepFormUpload.sass';
import { useNESteps } from 'context/NEStepsContext';
import { ACTIVE_STEP } from 'utils/NEUtis';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';
import { SubmitPopup } from 'components/DebFormComponents/Popup/SubmitPopup';
import { useFormik } from 'formik';
import { getTenantLandingPage } from 'utils/UrlUtil';

const NEStepFormUpload = () => {
  const { activeStep, setActiveStep } = useNESteps();
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: {},
    enableReinitialize: true,
    validateOnChange: true,
  });

  const { isSubmitting } = formik;

  const onBack = () => {
    setActiveStep(ACTIVE_STEP.FORM);
  };

  const onSubmit = () => {
    setSubmitPopupOpen(true);
  };

  if (activeStep !== ACTIVE_STEP.FORM_UPLOAD) return null;

  return (
    <>
      <div className="ne-step-form-upload">NEStepFormUpload works!</div>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={hasErrors}
          titleLeft="Zurück"
          titleRight={activeStep === ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={errorMessage}
        />
      </div>
      <SubmitPopup
        type="NEW_ENTRY"
        loading={isSubmitting}
        popupOpen={submitPopupOpen}
        setPopupOpen={() => setSubmitPopupOpen(false)}
        response={'SUCCESS'}
        redirectFullOnSuccessOrCancel={getTenantLandingPage()}
        onErrorRetry={() => {
          onSubmit();
        }}></SubmitPopup>
    </>
  );
};

export { NEStepFormUpload };
