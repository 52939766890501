import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './GuestExtension.sass';
import PropTypes from 'prop-types';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormExpertListExpertRow } from './FormExpertListExpertRow';
import { PFiles, PGuestExtensionInstaller } from 'utils/PropTypesUtils';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const DEFAULT_NEW_INSTALLER = {
  isNew: true,
  firstname: '',
  lastname: '',
  birthdate: getTenantId() === TENANT_ID.BAG ? '' : undefined,
  isGas: true,
  isEnergy: true,
  energyValidityDate: '',
  gasValidityDate: '',
};

const FormExpertList = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  files,
  onGasFileChange,
  onGasFileRemove,
  onEnergyFileChange,
  onEnergyFileRemove,
  onDeleteSwitch,
  initialInstallerCount,
  isSubmitting,
  setFieldValue,
  setFiles,
}) => {
  const onGasSwitch = (index, e) => {
    const installer = values.installers[index];
    if (!e.target.checked) {
      installer.isGas = false;
      installer.isEnergy = true;
      setFieldValue(`installers.${index}`, installer);
    } else {
      installer.isGas = true;
      setFieldValue(`installers.${index}`, installer);
    }
  };

  const onEnergySwitch = (index, e) => {
    const installer = values.installers[index];
    if (!e.target.checked) {
      installer.isEnergy = false;
      installer.isGas = true;
      setFieldValue(`installers.${index}`, installer);
    } else {
      installer.isEnergy = true;
      setFieldValue(`installers.${index}`, installer);
    }
  };

  const onAddNewInstaller = () => {
    const newFiles = [...files];
    newFiles.push({ gasFiles: [], energyFiles: [] });
    setFiles(newFiles);
    const newInstallers = [...values.installers];
    newInstallers.push({ ...DEFAULT_NEW_INSTALLER });
    setFieldValue('installers', newInstallers);
  };

  const onRemoveNewInstaller = (index) => {
    const newFiles = files.filter((file, iindex) => iindex !== index);
    setFiles(newFiles);
    const newInstallers = values.installers.filter((installer, iindex) => iindex !== index);
    setFieldValue('installers', newInstallers);
  };

  return (
    <>
      <FormSectionWrapper>
        <div className="guest-extension__input-part-1">
          <SubTitle text="Fachkräfte" notFramed />
          {values.installers.length > 0 &&
            values.installers.map(
              (installer, index) =>
                index < initialInstallerCount && (
                  <FormExpertListExpertRow
                    index={index}
                    installer={values.installers[index]}
                    errorInstaller={errors.installers && errors.installers[index]}
                    touchedInstaller={touched.installers && touched.installers[index]}
                    files={files}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onGasFileChange={onGasFileChange}
                    onGasFileRemove={onGasFileRemove}
                    onEnergyFileChange={onEnergyFileChange}
                    onEnergyFileRemove={onEnergyFileRemove}
                    onDeleteSwitch={onDeleteSwitch}
                    key={index}
                  />
                )
            )}
        </div>
      </FormSectionWrapper>
      <div className="guest-extension__add-new-experts-wrapper">
        <ActionButton
          title="Neue Fachkraft hinzufügen"
          disabled={isSubmitting}
          inverted
          type="button"
          onClick={onAddNewInstaller}
        />
      </div>
      <FormSectionWrapper>
        <div className="guest-extension__input-part-1">
          {values.installers.length > initialInstallerCount &&
            values.installers.map(
              (installer, index) =>
                index >= initialInstallerCount && (
                  <FormExpertListExpertRow
                    isNew
                    index={index}
                    installer={values.installers[index]}
                    errorInstaller={errors.installers && errors.installers[index]}
                    files={files}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onGasFileChange={onGasFileChange}
                    onGasFileRemove={onGasFileRemove}
                    onEnergyFileChange={onEnergyFileChange}
                    onEnergyFileRemove={onEnergyFileRemove}
                    key={index}
                    onEnergySwitch={onEnergySwitch}
                    onGasSwitch={onGasSwitch}
                    onRemoveNewInstaller={onRemoveNewInstaller}
                  />
                )
            )}
        </div>
      </FormSectionWrapper>
    </>
  );
};

FormExpertList.propTypes = {
  values: PropTypes.shape({
    installers: PropTypes.arrayOf(PGuestExtensionInstaller).isRequired,
  }).isRequired,
  touched: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.array])).isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  files: PFiles,
  onGasFileChange: PropTypes.func.isRequired,
  onEnergyFileChange: PropTypes.func.isRequired,
  onGasFileRemove: PropTypes.func.isRequired,
  onEnergyFileRemove: PropTypes.func.isRequired,
  onDeleteSwitch: PropTypes.func.isRequired,
  initialInstallerCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
};

export { FormExpertList };
