import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import React, { useContext, useState, useEffect } from 'react';
import TwoColumnsLayout from '../../Layouts/TwoColumnLayout/TwoColumnLayout';
import ArticleOverview from '../../components/Article/ArticleOverview/ArticleOverview';
import { SearchBox } from '../../components/SearchBox';
import { ActionButton } from '../ActionButton/ActionButton';
import '../../components/Article/ArticleCard/ArticleCard.scss';
import { TextField } from '@material-ui/core';
import { MessageBox } from '../../components/MessageBox/MessageBox';
import { ArticlesAndEventsContext } from '../../context/ArticlesAndEventsLimit';
import { PageTitleSection } from 'components/PageTitleSection/PageTitleSection';
import { CONSTANTS } from '../../global/constants';
import { GET_ARTICLES_QUERY, GET_TOTAL_ARTICLE_PAGES_QUERY } from '../../Queries/Queries';
import { TENANT_ID, getTenantId } from '../../utils/tenantUtil';
import { getEventsOverviewPath } from 'utils/UrlUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const DATA = {
  [TENANT_ID.EDI]: {
    searchTitle: 'ARTIKEL FILTERN',
    searchTagLabel: 'ARTIKEL DURSCHSUCHEN',
    articlesData: {
      title: 'Aktuelle Veranstaltungen',
      text: 'Sie möchten sich über alle Veranstaltungsangebote informieren? Oder sich für eine Veranstaltung anmelden? In unserer Übersicht finden Sie alle angebotenen Veranstaltungen – zu den verschiedensten Themen mit Ort und Zeit. Schauen Sie mal rein!',
      buttonText: 'Zu den Veranstaltungen',
      url: getEventsOverviewPath(),
    },
    title: 'Fachbeiträge',
    subText: 'Hier finden Sie alle Fachbeiträge rund um Strom, Gas und übergreifende Themen.',
  },
  [TENANT_ID.SHNG]: {
    searchTitle: 'ARTIKEL FILTERN',
    searchTagLabel: 'ARTIKEL DURSCHSUCHEN',
    articlesData: {
      title: 'Aktuelle Veranstaltungen',
      text: 'Sie möchten sich über alle Veranstaltungsangebote informieren? Oder sich für eine Veranstaltung anmelden? In unserer Übersicht finden Sie alle angebotenen Veranstaltungen – zu den verschiedensten Themen mit Ort und Zeit. Schauen Sie mal rein!',
      buttonText: 'Zu den Veranstaltungen',
      url: getEventsOverviewPath(),
    },
    title: 'Fachbeiträge',
    subText: 'Hier finden Sie alle Fachbeiträge rund um Strom, Gas und übergreifende Themen.',
  },
  [TENANT_ID.AVA]: {
    searchTitle: 'Nachrichten filtern',
    searchTagLabel: 'Nachrichten durchsuchen',
    articlesData: {
      title: 'Aktuelle Veranstaltungen',
      text: 'Sie möchten die Veranstaltungsangebote einsehen oder sich für einen Termin anmelden? In unserer Übersicht finden Sie alle aktuellen Veranstaltungen.',
      buttonText: 'Zu den Veranstaltungen',
      url: getEventsOverviewPath(),
    },
    title: 'Nachrichten',
    subText:
      'Hier finden Sie alle Nachrichten zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Avacon und aktuellen Themen.',
  },
  [TENANT_ID.BAG]: {
    searchTitle: 'Nachrichten filtern',
    searchTagLabel: 'Nachrichten durchsuchen',
    articlesData: {
      title: 'Aktuelle Veranstaltungen',
      text: 'Sie möchten die Veranstaltungsangebote einsehen oder sich für einen Termin anmelden? In unserer Übersicht finden Sie alle aktuellen Veranstaltungen.',
      buttonText: 'Zu den Veranstaltungen',
      url: getEventsOverviewPath(),
    },
    title: 'Nachrichten',
    subText:
      'Hier finden Sie alle Nachrichten zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Bayernwerk und aktuellen Themen.',
  },
};

const ArticlesOverviewPage = () => {
  const CONTENT = DATA[getTenantId()];

  const [searchCriteria, setSearchCriteria] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [cursor, setCursor] = useState(null);
  const { limit } = useContext(ArticlesAndEventsContext);
  const [action, setAction] = useState('FIRST');
  const [currentPage, setCurrentPage] = useState(1);
  const isEditor = Utils.isInEditor();

  const { loading, error, data } = useQuery(GET_ARTICLES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      searchCriteria,
      action: action,
      cursor: cursor,
      limit: limit,
      page: currentPage,
    },
  });

  const {
    loading: loadingForTotalPages,
    error: errorOnTotalPages,
    data: totalArticlePages,
  } = useQuery(GET_TOTAL_ARTICLE_PAGES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      searchCriteria,
      cursor: cursor,
      limit: limit,
    },
  });

  const updatePagination = (newAction, newCursor, page) => {
    setAction(newAction);
    setCursor(newCursor);
    setCurrentPage(page);
  };

  useEffect(() => {
    updatePagination('FIRST', null, 1);
  }, [searchCriteria]);

  let articles = [];
  let totalPages = 0;

  if (!loading && !error) {
    articles = data.articles;
  }

  if (!loadingForTotalPages && !errorOnTotalPages) {
    totalPages = totalArticlePages.articlesPagesNumber;
  }

  const getSearchCriteria = () => {
    const title = searchValue ? searchValue : null;
    const tags = selectedTags.length ? selectedTags : null;

    if (!title && !tags) return null;
    return Object.assign({}, { title, tags });
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    if (!value || !value.trim().length) return setSearchValue(null);
    setSearchValue(value);
  };

  const handleSearchSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    setSearchCriteria(getSearchCriteria());
  };

  const handleTagSelection = (tag) => {
    let tagSelection = [];
    const tagIsActive = selectedTags.indexOf(tag) !== -1;
    if (tagIsActive) {
      tagSelection = selectedTags.filter((activeTag) => activeTag !== tag);
    } else {
      tagSelection = [...selectedTags, tag];
    }
    setSelectedTags(tagSelection);

    if (!tagSelection.length && !searchValue) {
      setSearchCriteria(null);
    } else if (!tagSelection.length && searchValue) {
      setSearchCriteria({ title: searchValue, tags: null });
    } else {
      setSearchCriteria({ title: searchValue, tags: tagSelection });
    }
  };

  const searchForm = () => {
    return (
      <form onSubmit={handleSearchSubmit} className="news-page__search-form">
        <TextField
          onChange={handleSearchInput}
          className="search-field__articles"
          variant="standard"
        />
        <ActionButton title="Suchen" inverted />
      </form>
    );
  };

  const leftColumnContent = (
    <>
      <PageTitleSection title={CONTENT.title} subText={CONTENT.subText} notFramed />

      <SearchBox
        title={CONTENT.searchTitle}
        searchTagsLabel={CONTENT.searchTagLabel}
        formSlot={searchForm()}
        handleTagSelection={handleTagSelection}
        selectedTags={selectedTags}
        tags={Object.values(CONSTANTS.TAGS_ARTICLES)}
      />
      <ArticleOverview
        loading={loading}
        headline={CONTENT.title}
        data={articles}
        setPaginationVars={updatePagination}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </>
  );

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="newsoverview" />;
  }
  return (
    <TwoColumnsLayout
      leftColumn={leftColumnContent}
      rightColumn={<MessageBox data={CONTENT.articlesData} />}
    />
  );
};

MapTo('e-fix/components/content/newsoverview')(ArticlesOverviewPage);
