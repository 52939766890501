import { EIV_CHAR_LENGTH } from 'api/APIUtils';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';
import { YDate, YFutureDate, YPastDate } from 'utils/YupUtils';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import * as Yup from 'yup';

export const YGuestInstaller = Yup.object().shape({
  isEnergy: Yup.boolean(),
  isDeleted: Yup.boolean(),
  isNew: Yup.boolean(),
  firstname: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  lastname: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  birthdate:
    getTenantId() === TENANT_ID.BAG
      ? YPastDate.when(['isNew'], {
          is: true,
          then: YPastDate.required(VALIDATION_CONSTANTS.MANDATORY),
        })
      : YPastDate,
  hasSelectedEnergyFiles: Yup.boolean().when(['isEnergy'], {
    is: true,
    then: Yup.boolean().required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  energyValidityDate: Yup.string().when(['isEnergy'], {
    is: true,
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  isGas: Yup.boolean(),
  hasSelectedGasFiles: Yup.boolean().when(['isGas'], {
    is: true,
    then: Yup.boolean().required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  gasValidityDate: Yup.string().when(['isGas'], {
    is: true,
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  deletedDate: YDate,
  deletedReason: Yup.string()
    .min(3, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(100, VALIDATION_CONSTANTS.TOO_LONG),
});
