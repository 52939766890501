import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { FileInputSection } from 'components/FileInputSection/FileInputSection';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { FormExpertListWrapper } from 'components/Form/Wrapper/MyData/FormExpertListWrapper';
import { FormExpertListDeleteField } from 'components/Form/FormExpertListDeleteField';
import { FormSwitchField } from 'components/Form/FormSwitchField';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { PFiles, PGuestExtensionInstaller } from 'utils/PropTypesUtils';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const FormExpertListExpertRow = ({
  installer,
  errorInstaller,
  handleBlur,
  handleChange,
  files,
  onGasSwitch,
  onEnergySwitch,
  onGasFileChange,
  onGasFileRemove,
  onEnergyFileChange,
  onEnergyFileRemove,
  onRemoveNewInstaller,
  touchedInstaller,
  onDeleteSwitch,
  index,
  isNew,
}) => {
  return (
    <FormExpertListWrapper key={index}>
      <div>
        <FormFieldWrapper>
          <ThemedTextField
            fullWidth
            id={`installers.${index}.firstname`}
            name={`installers.${index}.firstname`}
            disabled={!isNew}
            label={`Vorname${isNew ? '*' : ''}`}
            variant="standard"
            value={installer.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              ((touchedInstaller && touchedInstaller.firstname) || isNew) &&
              errorInstaller &&
              Boolean(errorInstaller.firstname)
            }
            helperText={
              ((touchedInstaller && touchedInstaller.firstname) || isNew) &&
              errorInstaller &&
              errorInstaller.firstname
            }
          />
        </FormFieldWrapper>
        {getTenantId() === TENANT_ID.BAG && (
          <FormFieldWrapper>
            <ThemedTextField
              fullWidth
              disabled={!isNew}
              id={`installers.${index}.birthdate`}
              name={`installers.${index}.birthdate`}
              label={`Geburtsdatum${isNew ? '*' : ''}`}
              variant="standard"
              value={installer.birthdate}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                ((touchedInstaller && touchedInstaller.birthdate) || isNew) &&
                errorInstaller &&
                Boolean(errorInstaller.birthdate)
              }
              helperText={
                ((touchedInstaller && touchedInstaller.birthdate) || isNew) &&
                errorInstaller &&
                errorInstaller.birthdate
              }
            />
          </FormFieldWrapper>
        )}
      </div>
      <FormFieldWrapper>
        <ThemedTextField
          fullWidth
          disabled={!isNew}
          id={`installers.${index}.lastname`}
          name={`installers.${index}.lastname`}
          label={`Name${isNew ? '*' : ''}`}
          variant="standard"
          value={installer.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            ((touchedInstaller && touchedInstaller.lastname) || isNew) &&
            errorInstaller &&
            Boolean(errorInstaller.lastname)
          }
          helperText={
            ((touchedInstaller && touchedInstaller.lastname) || isNew) &&
            errorInstaller &&
            errorInstaller.lastname
          }
        />
      </FormFieldWrapper>

      <FormSwitchField
        disabled={installer.isDeleted}
        checked={!!installer.isGas}
        onChange={isNew ? (e) => onGasSwitch(index, e) : handleChange}
        onBlur={handleBlur}
        name={`installers.${index}.isGas`}
        title={'Gas'}
      />
      <div>
        <FormFieldWrapper>
          <ThemedTextField
            fullWidth
            disabled={!installer.isGas}
            id={`installers.${index}.gasValidityDate`}
            name={`installers.${index}.gasValidityDate`}
            label="Ausweis gültig bis*"
            variant="standard"
            value={installer.gasValidityDate}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errorInstaller && Boolean(errorInstaller.gasValidityDate)}
            helperText={errorInstaller && errorInstaller.gasValidityDate}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FileInputSection
            disabled={!installer.isGas}
            actionButtonTitle="Datei(en) auswählen..."
            id={`guest-extension.installers.${index}.isGas`}
            onFileChange={(e) => onGasFileChange(index, e)}
            onFileRemove={(iindex) => onGasFileRemove(index, iindex)}
            files={files[index].gasFiles}
          />

          <ErrorMessage error={errorInstaller && errorInstaller.hasSelectedGasFiles} touched />
        </FormFieldWrapper>
      </div>
      <FormSwitchField
        disabled={installer.isDeleted}
        checked={!!installer.isEnergy}
        onChange={isNew ? (e) => onEnergySwitch(index, e) : handleChange}
        onBlur={handleBlur}
        name={`installers.${index}.isEnergy`}
        title={'Strom'}
      />
      <div>
        <FormFieldWrapper>
          <ThemedTextField
            fullWidth
            disabled={!installer.isEnergy}
            id={`installers.${index}.energyValidityDate`}
            name={`installers.${index}.energyValidityDate`}
            label="Ausweis gültig bis*"
            variant="standard"
            value={installer.energyValidityDate}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errorInstaller && Boolean(errorInstaller.energyValidityDate)}
            helperText={errorInstaller && errorInstaller.energyValidityDate}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FileInputSection
            disabled={!installer.isEnergy}
            actionButtonTitle="Datei(en) auswählen..."
            id={`guest-extension.installers.${index}.isEnergy`}
            onFileChange={(e) => onEnergyFileChange(index, e)}
            onFileRemove={(iindex) => onEnergyFileRemove(index, iindex)}
            files={files[index].energyFiles}
          />

          <ErrorMessage error={errorInstaller && errorInstaller.hasSelectedEnergyFiles} touched />
        </FormFieldWrapper>
      </div>
      {isNew ? (
        <FormFieldWrapper>
          <ActionButton
            title="X"
            inverted
            type="button"
            onClick={() => onRemoveNewInstaller(index)}
          />
        </FormFieldWrapper>
      ) : (
        <FormExpertListDeleteField
          installer={installer}
          error={errorInstaller}
          handleBlur={handleBlur}
          handleChange={handleChange}
          index={index}
          onDeleteSwitch={onDeleteSwitch}
        />
      )}
    </FormExpertListWrapper>
  );
};

FormExpertListExpertRow.propTypes = {
  index: PropTypes.number.isRequired,
  installer: PGuestExtensionInstaller.isRequired,
  touchedInstaller: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.array])),
    PropTypes.bool,
  ]),
  errorInstaller: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  files: PFiles,
  onGasFileChange: PropTypes.func.isRequired,
  onEnergyFileChange: PropTypes.func.isRequired,
  onGasFileRemove: PropTypes.func.isRequired,
  onEnergyFileRemove: PropTypes.func.isRequired,
  onDeleteSwitch: PropTypes.func,
  onRemoveNewInstaller: PropTypes.func,
  isNew: PropTypes.bool,
  onGasSwitch: PropTypes.func,
  onEnergySwitch: PropTypes.func,
};

export { FormExpertListExpertRow };
