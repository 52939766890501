import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { FormSwitchField } from 'components/Form/FormSwitchField';

const FormChangesInput = ({ values, errors, touched, handleBlur, handleChange }) => {
  return (
    <div className="main-extension__extra-info-wrapper">
      <SubTitle text="Weitere Informationen" notFramed />

      <FormSwitchField
        className="main-extension__agree-switch-field"
        title="Haben sich die Eintragungsdaten geändert?"
        checked={values.hasChanges}
        onChange={handleChange}
        name={'hasChanges'}
        onBlur={handleBlur}
      />
      <div>Bitte teilen Sie uns hier die Änderungen mit:</div>
      <FormFieldWrapper className="main-extension__changes-wrapper">
        <ThemedTextField
          fullWidth
          id="changes"
          disabled={!values.hasChanges}
          name="changes"
          label="Änderungen"
          variant="standard"
          value={values.changes}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
          rows={6}
          rowsMax={6}
          error={values.hasChanges && Boolean(errors.changes)}
          helperText={touched.changes && errors.changes}
        />
      </FormFieldWrapper>
    </div>
  );
};

FormChangesInput.propTypes = {
  values: PropTypes.shape({
    hasChanges: PropTypes.bool.isRequired,
    changes: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    changes: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    changes: PropTypes.bool,
  }).isRequired,
};

export { FormChangesInput };
