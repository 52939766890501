import { useFormik, setNestedObjectValues } from 'formik';
import React, { useEffect, useState } from 'react';
import './GuestExtension.sass';
import { prepFilesForUpload } from 'utils/FileUtil';
import PropTypes from 'prop-types';
import { checkKeyDown, FFormDebug, UPLOAD_RESTRICTION_ERROR_MESSAGE } from 'utils/FormUtils';
import { YGuestExtension, YGuestExtensionAva } from './ValidationStructure/YGuestExtension';
import { FormCompanyData } from './FormCompanyData';
import { FormExpertList } from './FormExpertList';
import { FormWrapper } from 'components/Form/Wrapper/MyData/FormWrapper';
import { FormSubmitSection } from 'components/Form/FormSubmitSection';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { getTenantId, TENANT_ID } from 'utils/tenantUtil';
import { FormGeneralInformationData } from 'components/Form/FormGeneralInformationData';
import { FormQuestionInput } from './FormQuestionInput';
import { FormDocumentsUpload } from 'components/Form/FormDocumentsUpload';
import { FormDataPolicyData } from 'components/Form/FormDataPolicyData';
import { FormErrorMessage } from 'components/Form/FormErrorMessage';
import { CONSTANTS } from 'global/constants';
import { FormPopup } from 'components/FormPopup/FormPopup';
import { HelpOutline } from '@material-ui/icons';
import { HelpTooltip } from 'components/HelpTooltip/HelpTooltip';

export const GuestExtensionForm = ({
  initialValues,
  onSubmit,
  buttonDisabled,
  response,
  openSubmitPopup,
  setOpenSubmitPopup,
}) => {
  const [files, setFiles] = useState(
    initialValues.installers.map(() => ({ gasFiles: [], energyFiles: [] }))
  );
  const [extraDocuments, setExtraDocuments] = useState([]);
  const [uploadRestrictionError, setUploadRestrictionError] = useState();

  const onSubmitForm = async (values) => {
    const formData = {
      ...values,
      installers: values.installers
        .map((installer, index) => {
          const installerData = {
            firstname: installer.firstname,
            lastname: installer.lastname,
            birthdate: getTenantId() === TENANT_ID.BAG ? installer.birthdate : undefined,
            isNew: installer.isNew,
          };

          if (installer.isDeleted) {
            installerData.isDeleted = true;
            installerData.deletedReason = installer.deletedReason;
            installerData.deletedDate = installer.deletedDate;
            return installerData;
          }

          if (installer.isEnergy) {
            installerData.energyValidityDate = installer.energyValidityDate;
            installerData.energyDocuments = files[index].energyFiles.map((file) => ({
              base64: file.base64.split(',')[1],
              contentType: file.contentType,
            }));
          }

          if (installer.isGas) {
            installerData.gasValidityDate = installer.gasValidityDate;
            installerData.gasDocuments = files[index].gasFiles.map((file) => ({
              base64: file.base64.split(',')[1],
              contentType: file.contentType,
            }));
          }

          return installerData;
        })
        .filter((installer) => installer !== null),
      documents: extraDocuments.map((file) => ({
        base64: file.base64.split(',')[1],
        contentType: file.contentType,
      })),
    };

    onSubmit(formData);
  };

  const onEnergyFileChange = async (index, event) => {
    const newFiles = event.currentTarget.files;
    if (newFiles && newFiles.length > 0) {
      const newFilesB64 = await prepFilesForUpload(newFiles);
      const newEnergyFiles = [...files];
      newEnergyFiles[index].energyFiles = [...files[index].energyFiles, ...newFilesB64];
      setFiles(newEnergyFiles);
      setFieldValue(`installers.${index}.hasSelectedEnergyFiles`, true);
    }
  };

  const onEnergyFileRemove = (index, iindex) => {
    const newFiles = files[index].energyFiles.filter((_, iiindex) => iiindex !== iindex);
    const newEnergyFiles = [...files];
    newEnergyFiles[index].energyFiles = [...newFiles];
    setFiles(newEnergyFiles);
    if (newEnergyFiles[index].energyFiles.length === 0) {
      setFieldValue(`installers.${index}.hasSelectedEnergyFiles`, undefined);
    }
  };

  const onGasFileChange = async (index, event) => {
    const newFiles = event.currentTarget.files;
    if (newFiles && newFiles.length > 0) {
      const newFilesB64 = await prepFilesForUpload(newFiles);
      const newGasFiles = [...files];
      newGasFiles[index].gasFiles = [...files[index].gasFiles, ...newFilesB64];
      setFiles(newGasFiles);
      setFieldValue(`installers.${index}.hasSelectedGasFiles`, true);
    }
  };

  const onGasFileRemove = (index, iindex) => {
    const newFiles = files[index].gasFiles.filter((_, iiindex) => iiindex !== iindex);
    const newGasFiles = [...files];
    newGasFiles[index].gasFiles = [...newFiles];
    setFiles(newGasFiles);
    if (newGasFiles[index].gasFiles.length === 0) {
      setFieldValue(`installers.${index}.hasSelectedGasFiles`, undefined);
    }
  };

  const onDeleteSwitch = (index, e) => {
    handleChange(e);
    if (e.target.checked) {
      // INFO: setFieldValue is an async setState --> which means two+ calls in a row are bad practise
      const newValues = { ...values };
      newValues.installers[index].isGas = false;
      newValues.installers[index].isEnergy = false;
      newValues.installers[index].isDeleted = true;

      setValues(newValues);
    }
  };

  const hasTickedInstaller = (installers) => {
    const updatedInstallers = installers.filter(
      (installer) => installer.isEnergy || installer.isGas || installer.isDeleted
    );
    return updatedInstallers.length > 0;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getTenantId() === TENANT_ID.AVA ? YGuestExtensionAva : YGuestExtension,
    onSubmit: onSubmitForm,
    validateOnMount: true,
  });

  const {
    isValid,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
    setFieldValue,
    handleSubmit,
    setValues,
    setTouched,
  } = formik;

  const onResetForm = () => {
    setFiles(initialValues.installers.map(() => ({ gasFiles: [], energyFiles: [] })));
    setExtraDocuments([]);
    resetForm();
    window.scrollTo(0, 0);
  };

  // needed to set touched on fields that have errors on mount (via API data)
  useEffect(() => {
    setTouched(setNestedObjectValues(errors, true));
  }, [errors]);

  useEffect(() => {
    const newFiles = files.reduce(
      (acc, curr) => [...acc, ...curr.gasFiles, ...curr.energyFiles],
      []
    );

    const totalFileSize = newFiles.reduce((acc, curr) => acc + curr.size, 0);
    const totalExtraDocumentsSize = extraDocuments.reduce((acc, curr) => acc + curr.size, 0);
    const numberOfFiles = newFiles.length + extraDocuments.length;

    if (numberOfFiles > CONSTANTS.MAX_UPLOAD_FILE_COUNT) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_COUNT);
    } else if (totalFileSize + totalExtraDocumentsSize > CONSTANTS.MAX_UPLOAD_TOTAL_FILE_SIZE) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_FILE_SIZE);
    } else {
      setUploadRestrictionError(undefined);
    }
  }, [files, extraDocuments]);

  return (
    <form onKeyDown={checkKeyDown} onSubmit={handleSubmit}>
      <FormWrapper>
        <FormSectionWrapper>
          <FormFieldWrapper className="main-extension__min-height-70">
            <ThemedTextField
              fullWidth
              disabled
              label="Installateurnummer"
              variant="standard"
              value={values.companyId}
            />
          </FormFieldWrapper>
        </FormSectionWrapper>
        {getTenantId() === TENANT_ID.BAG && <FormGeneralInformationData values={values} />}
        <FormCompanyData
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
        />
        <div className="guest-extension__extra-info-wrapper">
          Wählen Sie mind. 1 Fachkraft aus und laden Sie den erforderlichen Ausweis je Fachkraft
          hoch.
          <div>
            <b>Hinweis:</b> Bitte Vorder- und Rückseite hochladen!
          </div>
          <div>Folgende Formate werden beim Upload unterstützt: PDF, JPG, JPEG, GIF, PNG</div>
        </div>
        <FormExpertList
          handleBlur={handleBlur}
          handleChange={handleChange}
          values={values}
          errors={errors}
          touched={touched}
          onDeleteSwitch={onDeleteSwitch}
          onGasFileChange={onGasFileChange}
          onGasFileRemove={onGasFileRemove}
          onEnergyFileChange={onEnergyFileChange}
          onEnergyFileRemove={onEnergyFileRemove}
          files={files}
          setFieldValue={setFieldValue}
          setFiles={setFiles}
          initialInstallerCount={initialValues.installers.length}
          isSubmitting={isSubmitting}
        />
        <FormQuestionInput
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
        />

        {getTenantId() === TENANT_ID.BAG && (
          <FormDocumentsUpload files={extraDocuments} setFiles={setExtraDocuments} />
        )}
        <FormDataPolicyData values={values} handleBlur={handleBlur} handleChange={handleChange} />
        {uploadRestrictionError && <FormErrorMessage>{uploadRestrictionError}</FormErrorMessage>}
        {response && <div className="main-extension__response-message">{response}</div>}
        {getTenantId() === TENANT_ID.AVA && (
          <FormFieldWrapper className="guest-extension__line">
            <ThemedTextField
              id="postalOfOrder"
              name="postalOfOrder"
              label="PLZ des Auftragsorts im Avacon Netzgebiet*"
              value={values.postalOfOrder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postalOfOrder && Boolean(errors.postalOfOrder)}
              helperText={errors.postalOfOrder && errors.postalOfOrder}
            />
            <HelpTooltip
              placement="right"
              title={
                <div className="guest-extension__alternating-table">
                  <div>
                    Diese Angabe benötigen wir für unsere interne Zuordnung. Sofern Sie die
                    Anmeldung von mehreren Installationsanlagen in unserem Gebiet planen, geben Sie
                    die PLZ eines beliebigen Auftragsortes an. Ihre Gasteintragung gilt für das
                    gesamte Gebiet der Avacon Netz GmbH. In der Tabelle einige Beispiele
                  </div>
                  <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                      <tr>
                        <th>Standort</th>
                        <th>PLZ</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Burgwedel</td>
                        <td>30938</td>
                      </tr>
                      <tr>
                        <td>Gardelegen</td>
                        <td>39638</td>
                      </tr>
                      <tr>
                        <td>Gehrden</td>
                        <td>30989</td>
                      </tr>
                      <tr>
                        <td>Lüneburg</td>
                        <td>21337</td>
                      </tr>
                      <tr>
                        <td>Salzwedel</td>
                        <td>29410</td>
                      </tr>
                      <tr>
                        <td>Sarstedt</td>
                        <td>31157</td>
                      </tr>
                      <tr>
                        <td>Schöningen</td>
                        <td>38364</td>
                      </tr>
                      <tr>
                        <td>Syke</td>
                        <td>28857</td>
                      </tr>
                      <tr>
                        <td>Oschersleben</td>
                        <td>39387</td>
                      </tr>
                      <tr>
                        <td>WEVG / Salzgitter</td>
                        <td>38226</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }>
              <HelpOutline></HelpOutline>
            </HelpTooltip>
          </FormFieldWrapper>
        )}
        <FormSubmitSection
          onResetClicked={onResetForm}
          resetDisabled={isSubmitting}
          onSubmitClick={() => {
            onSubmit();
          }}
          submitDisabled={
            !isValid ||
            isSubmitting ||
            !hasTickedInstaller(values.installers) ||
            buttonDisabled ||
            uploadRestrictionError
          }
        />

        <FormPopup openSubmitPopup={openSubmitPopup} setOpenSubmitPopup={setOpenSubmitPopup} />
        <FFormDebug values={values} errors={errors} touched={touched} />
      </FormWrapper>
    </form>
  );
};

GuestExtensionForm.displayName = 'GuestExtensionForm';

GuestExtensionForm.propTypes = {
  initialValues: PropTypes.shape({
    companyId: PropTypes.string.isRequired,
    companyName1: PropTypes.string.isRequired,
    companyName2: PropTypes.string,
    companyName3: PropTypes.string,
    companyOwner: PropTypes.string.isRequired,
    companyStreet: PropTypes.string.isRequired,
    companyHouseNumber: PropTypes.string.isRequired,
    companyPostal: PropTypes.string.isRequired,
    companyCity: PropTypes.string.isRequired,
    companyRegion: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    isAddressPublic: PropTypes.bool.isRequired,
    canSendMail: PropTypes.bool.isRequired,
    limitationGas: PropTypes.string.isRequired,
    limitationEnergy: PropTypes.string.isRequired,
    gridProviderEnergy: PropTypes.string,
    foreignCompanyIdEnergy: PropTypes.string,
    gridProviderGas: PropTypes.string,
    foreignCompanyIdGas: PropTypes.string,
    installers: PropTypes.arrayOf(
      PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
        birthdate: PropTypes.string.isRequired,
        energyValidityDate: PropTypes.string,
        gasValidityDate: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool,
  response: PropTypes.string,
  openSubmitPopup: PropTypes.bool,
  setOpenSubmitPopup: PropTypes.func,
};

GuestExtensionForm.defaultProps = {};
