import React from 'react';
import './FormSectionWrapper.sass';
import PropTypes from 'prop-types';

const FormSectionWrapper = (props) => {
  return <div className="form-section-wrapper">{props.children}</div>;
};

FormSectionWrapper.propTypes = {
  children: PropTypes.node,
};

export { FormSectionWrapper };
