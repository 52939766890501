import React, { useState } from 'react';
import './NEStepBranchSelection.sass';
import { useNESteps } from 'context/NEStepsContext';
import { ACTIVE_STEP } from 'utils/NEUtis';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import energyIcon from '../../../../assets/pics/energy_icon.svg';
import gasIcon from '../../../../assets/pics/gas_icon.svg';
import { InputRow } from 'components/DebFormComponents/InputRow';
import { useFormik } from 'formik';
import { NEBranchInformation } from './NEBranchInformation';
import { BRANCH_TYPE } from 'utils/DataUtils';
import { FFormDebug } from 'utils/FormUtils';
import { YNEBranchSelectionValidation } from 'components/NEValidation/NEBranchSelectionValidation';
const NEStepBranchSelection = () => {
  const { activeStep, setActiveStep, setSubmittedValues } = useNESteps();
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      isEnergyActive: false,
      isGasActive: false,
      companyTypeEnergy: '',
      companyTypeGas: '',
      branchTypeEnergy: '',
      branchTypeGas: '',
    },
    validationSchema: YNEBranchSelectionValidation,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
  });

  if (activeStep !== ACTIVE_STEP.BRANCH_SELCTION) return null;

  const onBack = () => {
    setActiveStep(ACTIVE_STEP.EMAIL_INPUT);
  };

  const onSubmit = () => {
    setSubmittedValues((oldValues) => {
      return { ...oldValues, ...formik.values };
    });
    setActiveStep(ACTIVE_STEP.FORM);
  };

  return (
    <>
      <div className="ne-step-branch-selection">
        Bitte wählen Sie Ihre Sparte(n) aus:
        <InputRow>
          <LabelCheckbox
            label="Strom"
            id="isEnergyActive"
            name="isEnergyActive"
            icon={energyIcon}
            value={formik.values.isEnergyActive}
            onChange={formik.handleChange}></LabelCheckbox>
          <LabelCheckbox
            label="Gas"
            id="isGasActive"
            name="isGasActive"
            icon={gasIcon}
            value={formik.values.isGasActive}
            onChange={formik.handleChange}></LabelCheckbox>
        </InputRow>
        {formik.values.isEnergyActive ? (
          <NEBranchInformation formik={formik} branch={BRANCH_TYPE.ENERGY}></NEBranchInformation>
        ) : null}
        {formik.values.isGasActive ? (
          <NEBranchInformation formik={formik} branch={BRANCH_TYPE.GAS}></NEBranchInformation>
        ) : null}
      </div>
      <FFormDebug values={formik.values} errors={formik.errors}></FFormDebug>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={Object.keys(formik.errors).length > 0}
          titleLeft="Zurück"
          titleRight={activeStep === ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={errorMessage}
        />
      </div>
    </>
  );
};

export { NEStepBranchSelection };
