import { objGetProp } from './objectUtils';
import { GRIDNET_PROVIDER, TENANT_ID, getTenantId } from './tenantUtil';

export const parseAEMDatapolicyProps = (props) => {
  switch (getTenantId()) {
    case TENANT_ID.EDI:
      return [{ link: props.datapolicyEDILink, label: props.datapolicyEDILabel }];
    case TENANT_ID.SHNG:
      return [
        { link: props.datapolicySHNGLink, label: props.datapolicySHNGLabel },
        { link: props.datapolicyEENGLink, label: props.datapolicyEENGLabel },
        { link: props.datapolicyHANSGLink, label: props.datapolicyHANSGLabel },
        { link: props.datapolicyNNNGLink, label: props.datapolicyNNNGLabel },
      ];
    case TENANT_ID.AVA:
      return [{ link: props.datapolicyAVALink, label: props.datapolicyAVALabel }];
    case TENANT_ID.BAG:
      return [{ link: props.datapolicyBAGLink, label: props.datapolicyBAGLabel }];
    default:
      return [];
  }
};

export const parseAEMInstallerContractProps = (props, installer) => {
  let link = props.installerContractLink;
  switch (getTenantId()) {
    case TENANT_ID.SHNG:
      if (installer.b_netzbetreiber_gas) {
        switch (installer.b_netzbetreiber_gas.id) {
          case GRIDNET_PROVIDER.SHNG.EENG:
            link = props.installerContractLinkEENGLink;
            break;
          case GRIDNET_PROVIDER.SHNG.HANSG:
            link = props.installerContractLinkHANSGLink;
            break;
          case GRIDNET_PROVIDER.SHNG.NNNG:
            link = props.installerContractLinkNNNGLink;
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }
  return link;
};

export const getMetaNavLinks = (props) => {
  let result = [];
  const links = objGetProp(props, 'metaNav.links');

  if (links) {
    result = [...links];
  }

  return result;
};

export const getFooterLinks = (props) => {
  const navRoot = props.navigation?.items?.length ? props.navigation.items[0] : [];
  const navLinks = [
    {
      title: navRoot?.title,
      url: navRoot?.url,
    },
    ...(navRoot?.children || []),
  ];

  return navRoot ? navLinks : [];
};
