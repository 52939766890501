import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './FormContactInfoTitleSection.sass';
import { NavigationTooltip } from 'components/NavigationTooltip/NavigationTooltip';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const CONTENT = {
  [TENANT_ID.EDI]: {
    TOOLTIP_LINK: 'https://www.e-dis-netz.de/de/meinauftragsportal.html',
  },
  [TENANT_ID.SHNG]: {
    TOOLTIP_LINK: 'https://www.sh-netz.com/de/meinauftragsportal.html',
  },
  [TENANT_ID.AVA]: {
    TOOLTIP_LINK: 'https://www.avacon-netz.de/de/meinauftragsportal.html',
  },
  [TENANT_ID.BAG]: {
    TOOLTIP_LINK: 'https://www.bayernwerk-netz.de/de/meinauftragsportal.html',
  },
};

const FormContactInfoTitleSection = () => {
  const { TOOLTIP_LINK } = CONTENT[getTenantId()];

  return (
    <div className="form-contact-info-title-section__wrapper">
      <SubTitle text="Kontaktinformationen" notFramed />
      <NavigationTooltip
        className="form-contact-info-title-section__nav-item"
        title="Wenn Sie ihre Kontaktdaten ändern wollen, machen Sie dies bitte über das mAP Portal."
        cta="Zum mAP!"
        to={TOOLTIP_LINK}
      />
    </div>
  );
};

FormContactInfoTitleSection.propTypes = {};

export { FormContactInfoTitleSection };
