import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import ContactForm from '../components/FormFieldComponent/contact-form';
import { useState } from 'react';
import '../components/css/GenericModal.scss';
import PropTypes from 'prop-types';
import { SubTitle } from './SubTitle/SubTitle';
import { getTenantId } from 'utils/tenantUtil';

const DialogTitle = (props) => {
  const { children, modalclose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className="dialog" {...other}>
      <SubTitle text={children} className={`dialog__title ${!modalclose && 'no-icon'}`} notFramed />
      {modalclose ? (
        <IconButton aria-label="close" onClick={modalclose}>
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.any,
  modalclose: PropTypes.func,
};

const DialogSubtitle = (props) => {
  const { children, ...other } = props;
  return (
    <MuiDialogContent {...other}>
      <DialogContentText>{children}</DialogContentText>
    </MuiDialogContent>
  );
};

DialogSubtitle.propTypes = {
  children: PropTypes.any,
};

const GenericModal = ({ data, openModal, closeModal }) => {
  const [visible, setVisibile] = useState(false);

  function visibility() {
    setVisibile(!visible);
    document.getElementById('visible').style.visibility = 'hidden';
  }

  return openModal
    ? ReactDOM.createPortal(
        <>
          <Dialog
            className={getTenantId()}
            id="visible"
            fullWidth={true}
            max-height="558px"
            open={openModal}
            onClose={closeModal}
            aria-labelledby="form-dialog-title">
            {data.title && <DialogTitle modalclose={closeModal}>{data.title}</DialogTitle>}
            {data.dummyNumber && (
              <DialogSubtitle>{`${data.subtitle} ${data.dummyNumber}`}</DialogSubtitle>
            )}
            <ContactForm email={data.email} modalclose={closeModal} visibility={visibility} />
          </Dialog>
        </>,
        document.body
      )
    : null;
};

export { GenericModal };
