import React from 'react';
import './HomepageCard.sass';
import PropTypes from 'prop-types';

import { Separator } from '../Separator/Separator';

import { getTenantId, TENANT_ID } from '../../utils/tenantUtil';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { HomepageCardContentItem } from './HomepageCardContentItem';
import { ActionButton } from '../ActionButton/ActionButton';
import { getLexikonUrl } from 'utils/UrlUtil';

const HomepageCard = ({ classNames, data, loading, disabled, isLexikon }) => {
  const tenantId = getTenantId();

  const renderCardContent = () => {
    return data.teaser.elementsList.map((element, index) => (
      <HomepageCardContentItem key={index} name={element.name} number={element.number} />
    ));
  };

  return (
    <div className={`mdl-card homepage-card-container ${classNames}`}>
      <div className={`homepage-card-title-container`}>
        <div>{data.title}</div>
        <div className="homepage-card-icon-container">
          <div className={`${tenantId}`}>{data.icon}</div>
          {data?.secondIcon && <div className={`${tenantId}`}>{data.secondIcon}</div>}
        </div>
      </div>
      <Separator color={tenantId === TENANT_ID.AVA ? 'homepage' : 'white'} />
      <div className={`homepage-card-content-container ${tenantId}`}>
        {loading ? (
          <LoadingIndicator className="card-component-loading-indicator" />
        ) : (
          renderCardContent()
        )}
      </div>
      <ActionButton
        disabled={disabled}
        link={data.actionLink.linkedPage}
        onClick={() => {
          if (isLexikon) {
            window.location.assign(getLexikonUrl());
          }
        }}
        title={!disabled ? data.actionLink.actionLinkTitle : 'Demnächst verfügbar'}
        className={tenantId === TENANT_ID.AVA ? 'homepage-card-action-button-avacon' : undefined}
      />
    </div>
  );
};

HomepageCard.propTypes = {
  data: PropTypes.object,
  classNames: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isLexikon: PropTypes.bool,
};

HomepageCard.defaultProps = {
  type: 'regular',
  loading: false,
  disabled: false,
  isLexikon: false,
};

export { HomepageCard };
