/* eslint-disable react/prop-types */
import React from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import downloadImg from './icon-download-18-x-18.svg';
import './PdfCard.scss';
import { SubTitle } from '../../SubTitle/SubTitle.jsx';

const PdfCard = ({ attachments }) => {
  const getSanitzedAttachmentUrl = (attachments) => {
    const NEEDLE = 'http://localhost:4502';
    const NODE_ENV_DEV = 'development';

    if (
      !attachments ||
      process.env.NODE_ENV === NODE_ENV_DEV ||
      !process.env.REACT_APP_GRAPHQL_ENDPOINT ||
      !process.env.REACT_APP_AEM_ENDPOINT.length ||
      attachments.indexOf(NEEDLE) === -1
    ) {
      return attachments;
    }

    return attachments.replace(NEEDLE, process.env.REACT_APP_AEM_ENDPOINT);
  };

  const pdfAttachments = attachments.map((el, key) => {
    // eslint-disable-next-line
    const attach = el.attachment.substr(el.attachment.lastIndexOf('/') + 1);
    return (
      <div className="pdfs__links" key={key}>
        <a
          key={key}
          href={getSanitzedAttachmentUrl(el.attachment)}
          download
          rel="noopener noreferrer">
          <i>
            <img alt="Downloads" src={downloadImg} className="pdfs__image" />
          </i>
          {attach}
        </a>
      </div>
    );
  });

  return (
    attachments && (
      <div className="pdfs">
        <SubTitle text="Downloads" notFramed />
        {pdfAttachments}
      </div>
    )
  );
};

MapTo('e-fix/components/content/attachments')(PdfCard);
