import { FormikProvider, useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './DebForm.sass';
import PropTypes from 'prop-types';
import { InputRow } from 'components/DebFormComponents/InputRow';
import { FormSection } from 'components/DebFormComponents/FormSection';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useInstallerStatusSummary } from 'hooks/UseInstallerStatusSummary';
import {
  calculateTotalFilesSize,
  createEmailSubject,
  getFileWithNames,
  shouldDebSecondPageSectionRender,
} from './Utils/DebFormSectionUtil';
import { FFormDebug, UPLOAD_RESTRICTION_ERROR_MESSAGE, checkKeyDown } from 'utils/FormUtils';
import { CONSTANTS } from 'global/constants';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';
import { DOCUMENT_NAME_TO_ID, documentsFields, expertsFields } from './Utils/DocumentsFields';
import { getTenantId } from 'utils/tenantUtil';
import { UploadField } from 'components/DebFormComponents/UploadField';
import { useDebFormSecondPageLogic } from './Utils/UseDebFormSecondPageLogic';
import { DataPolicyContent } from 'components/DataPolicy/DataPolicyContent';
import { useMutation } from '@apollo/client';
import { SEND_DEB_FORM, SET_SUBSCRIPTION_STATUS_MUTATION } from 'Queries/Mutations';
import {
  createPatchEinwilligungByInstallerIdData,
  patchEinwilligungByInstallerId,
} from 'api/installer/PatchEinwilligungByInstallerId';
import { InputField } from 'components/DebFormComponents/InputField';
import { INSTALLER_ENTRIES, prepDebData } from 'utils/DataUtils';
import { SubmitPopup } from 'components/DebFormComponents/Popup/SubmitPopup';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { getMyDataPath } from 'utils/UrlUtil';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { HintText } from 'components/HintText/HintText';
import { HINTTEXT_TYPE } from 'components/HintText/HintTextType';

export const DebFormSecondPage = ({
  valuesFirstPage,
  initialValuesFirstPage,
  installer,
  setPage,
  dataPolicyDocuments,
  selfDeclarationCompanyFacilityLink,
  installerContractLink,
  expertQualificationCertificateGasLink,
  expertQualificationCertificateEnergyLink,
}) => {
  const installerStatusSummary = useInstallerStatusSummary(installer);
  const { initialValues, validationSchema } = useDebFormSecondPageLogic(
    valuesFirstPage,
    initialValuesFirstPage,
    installerStatusSummary,
    installer
  );

  const [setSubscriptionStatus] = useMutation(SET_SUBSCRIPTION_STATUS_MUTATION);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
  });

  const { values, errors, touched, handleSubmit } = formik;

  const [uploadRestrictionError, setUploadRestrictionError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState('');
  const [openPopup, setOpenPopup] = useState(false);

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [values, errors, formik]);
  const [sendDebForm] = useMutation(SEND_DEB_FORM);
  const { setShowError } = useContext(ErrorHandlingContext);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const subject = createEmailSubject(values, installerStatusSummary);
    const files = getFileWithNames(values, valuesFirstPage, installer);
    const formData = prepDebData(
      initialValuesFirstPage,
      valuesFirstPage,
      values,
      subject,
      files,
      installerStatusSummary
    );
    try {
      const patchData = createPatchEinwilligungByInstallerIdData(
        values.isAddressPublic,
        values.canSendMail,
        setShowError
      );
      if (
        patchData.zu_adressveroeff !== installer.zu_adressveroeff ||
        patchData.zu_infoversand !== installer.zu_infoversand
      ) {
        const response = await patchEinwilligungByInstallerId(installer.id, patchData);

        const responseSetSubscriptionStatus = setSubscriptionStatus({
          variables: { status: values.canSendMail },
        });

        if (response.errors || responseSetSubscriptionStatus.errors) {
          throw new Error(response.errors || responseSetSubscriptionStatus.errors);
        }
      }
    } catch (e) {
      console.log(e);
    }
    sendDebForm({
      variables: {
        debInput: formData,
      },
    })
      .then(({ data }) => {
        setOpenPopup(true);
        setResponse(data.deb.status); //this can't be a constant as graphql sometimes goes to 200 even with errors
      })
      .catch(() => {
        setOpenPopup(true);
        setResponse('ERROR');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    //total file size calculation and validation
    let totalSize = calculateTotalFilesSize(values);
    if (totalSize > CONSTANTS.MAX_UPLOAD_TOTAL_FILE_SIZE) {
      setUploadRestrictionError(UPLOAD_RESTRICTION_ERROR_MESSAGE.TOTAL_FILE_SIZE);
    } else {
      setUploadRestrictionError('');
    }

    formik.validateForm();
  }, [values]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSectionLink = (section) => {
    //check documentFields.jsx and HWPORTAL-3692 to get more info on the sections. Match text on the story with infoText on the fields
    switch (section.id) {
      case DOCUMENT_NAME_TO_ID.businessEquipmentConfirmation:
        return selfDeclarationCompanyFacilityLink;
      case DOCUMENT_NAME_TO_ID.installerContractOwner:
        return installerContractLink;
      case DOCUMENT_NAME_TO_ID.installerContractGas:
        return installerContractLink;
      case DOCUMENT_NAME_TO_ID.installerContractGasResponsible:
        return installerContractLink;
      case DOCUMENT_NAME_TO_ID.qualificationProofElectricity:
        return expertQualificationCertificateEnergyLink;
      case DOCUMENT_NAME_TO_ID.qualificationProofGas:
        return expertQualificationCertificateGasLink;
    }
  };
  const renderedFields = useRef(0);
  return (
    <FormikProvider value={formik}>
      <form onKeyDown={checkKeyDown} onSubmit={handleSubmit} className="deb-form__second-page">
        <div className="deb-page">
          <div className="title-section">
            <PageTitle text="Dokumentenupload und weitere Angaben"></PageTitle>
            <div>
              Bitte laden Sie die geforderten Dokumente hoch. Aufgrund Ihrer Auswahl auf der
              vorherigen Seite benötigen wir folgende Dokumente. Am Ende überprüfen Sie bitte Ihre
              Datenschutzeinstellung und senden Sie uns nach Bedarf Ihre Fragen.
            </div>
            <br />
            {renderedFields.current > 0 ? (
              <HintText>
                Folgende Formate werden beim Upload unterstützt: PDF, JPG, JPEG, GIF, PNG
              </HintText>
            ) : null}
          </div>
          {documentsFields[installerStatusSummary.installerEntry][getTenantId()].map((section) => {
            if (shouldDebSecondPageSectionRender(section, formik.values.documentsTrigger)) {
              renderedFields.current += 1;
              let infoText = section.infoText;
              let infoLink = getSectionLink(section);
              if (section.id === DOCUMENT_NAME_TO_ID.gridOperatorRegistration) {
                if (
                  installerStatusSummary.installerEntry ===
                    INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS &&
                  !formik.values.documentsTrigger.every((id) => [1, 2, 3, 11].includes(id))
                ) {
                  infoText = '';
                } else if (
                  installerStatusSummary.installerEntry ===
                    INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS &&
                  !formik.values.documentsTrigger.every((id) => [1, 2, 3, 12].includes(id))
                ) {
                  infoText = '';
                }
              }
              return (
                <FormSection
                  key={section.id}
                  subtitle={`·  ${section.title} ${section.optional == false ? '*' : ''}`}
                  tooltip={section.tooltip}
                  navigationTooltip={section.navigationTooltip}>
                  <InputRow>
                    <UploadField
                      infoText={infoText}
                      key={section.name}
                      name={section.name}
                      optional={section.optional}
                      files={values[section.name]}
                      formik={formik}
                      infoLink={infoLink}
                    />
                  </InputRow>
                </FormSection>
              );
            } else return null;
          })}

          {Object.keys(values.expertTrigger).map((key) => {
            const fields = [];

            expertsFields[installerStatusSummary.installerEntry][getTenantId()].forEach(
              (section) => {
                let infoLink = getSectionLink(section);
                if (shouldDebSecondPageSectionRender(section, values.expertTrigger[key])) {
                  renderedFields.current += 1;
                  fields.push(
                    <FormSection
                      key={section.id}
                      subtitle={`·  ${section.title} ${section.optional == false ? '*' : ''}`}
                      tooltip={section.tooltip}
                      navigationTooltip={section.navigationTooltip}>
                      <InputRow>
                        <UploadField
                          infoText={section.infoText}
                          key={`${key}.${section.name}`}
                          name={`${key}.${section.name}`}
                          files={values[key]?.[section.name] || []}
                          onChange={formik.handleChange}
                          formik={formik}
                          error={Boolean(formik.errors[section.name])}
                          helperText={formik.errors[section.name] || ''}
                          infoLink={infoLink}
                        />
                      </InputRow>
                    </FormSection>
                  );
                }
              }
            );
            if (fields.length > 0) {
              const expert = valuesFirstPage.experts?.find((expert) => {
                return expert.id == key;
              });
              if (expert)
                return (
                  <FormSection title={`${expert.lastName}  ${expert.firstName}`} key={expert.id}>
                    {fields}
                  </FormSection>
                );
            }
          })}
          <FormSection key={'changes'} title={'Weitere Angaben'}>
            <div>
              Sie haben noch Fragen oder weitere Anmerkungen? Dann können Sie uns hier eine
              Nachrichten hinterlassen.
            </div>
            <InputField
              key={'changes'}
              name={'changes'}
              fullWidth
              label={'Freitext'}
              oldValue={''}
              variant="standard"
              value={values['changes']}
              onChange={formik.handleChange}
              error={Boolean(formik.errors['changes'])}
              helperText={formik.errors['changes'] || ''}
              multiline
              rows={6}
              rowsMax={6}
            />
          </FormSection>
          <FormSection key={'changesAndPrivacy'} title={'Datenschutz'}>
            <div>Hier können Sie ihre Zustimmungen bearbeiten</div>
            <div className="agreement-section__wrapper">
              <DataPolicyContent
                dsgvoAddressPublic={values.isAddressPublic}
                dsgvoCanSendMail={values.canSendMail}
                dsgvoAddressPublicName="isAddressPublic"
                dsgvoCanSendMailName="canSendMail"
                onChangeDsvgoAddressPublic={formik.handleChange}
                onChangeDsvgoCanSendMail={formik.handleChange}
                documents={dataPolicyDocuments}
              />
            </div>
          </FormSection>
        </div>

        {uploadRestrictionError && (
          <div className="deb-form__upload-restriction-error">
            <HintText type={HINTTEXT_TYPE.ERROR}>{uploadRestrictionError}</HintText>
          </div>
        )}
        {isSubmitting ? (
          <LoadingIndicator></LoadingIndicator>
        ) : (
          <FormActionButtons
            errorMessage="Bitte füllen Sie alle mit * gekennzeichneten Pflichtfelder sowie Uploadfelder."
            hasErrors={hasErrors || Boolean(uploadRestrictionError)}
            titleLeft="Zurück"
            titleRight="Absenden"
            onClickLeft={() => {
              setPage(0);
            }}
            onClickRight={() => {
              onSubmit();
            }}
          />
        )}

        <FFormDebug values={values} errors={errors} touched={touched} />
      </form>
      <SubmitPopup
        type="EDIT_COMPANY"
        loading={isSubmitting}
        popupOpen={openPopup}
        setPopupOpen={() => setOpenPopup(false)}
        response={response}
        redirectLinkOnSuccessOrCancel={getMyDataPath()}
        onErrorRetry={() => {
          onSubmit();
        }}></SubmitPopup>
    </FormikProvider>
  );
};

DebFormSecondPage.displayName = 'DebFormSecondPage';

DebFormSecondPage.propTypes = {
  valuesFirstPage: PropTypes.object.isRequired,
  initialValuesFirstPage: PropTypes.object.isRequired,
  installer: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
  expertQualificationCertificateGasLink: PropTypes.string,
  expertQualificationCertificateEnergyLink: PropTypes.string,
  installerContractLink: PropTypes.string,
  selfDeclarationCompanyFacilityLink: PropTypes.string,
  dataPolicyDocuments: PropTypes.array.isRequired,
};

DebFormSecondPage.defaultProps = {};
