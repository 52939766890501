import React from 'react';
import './ErrorMessage.sass';
import PropTypes from 'prop-types';
import { HintText } from 'components/HintText/HintText';

const ErrorMessage = ({ error, touched }) => {
  if (!touched || !error) {
    return null;
  }

  return <HintText type="error">{error}</HintText>;
};

ErrorMessage.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool.isRequired,
};

export { ErrorMessage };
