import React from 'react';
import { ActionButton } from 'components/ActionButton/ActionButton';
import PropTypes from 'prop-types';
import './FormSubmitSection.sass';

const FormSubmitSection = (props) => {
  const { resetDisabled, submitDisabled, onResetClicked } = props;
  return (
    <div className="form-submit-section">
      <ActionButton
        title="Zurücksetzen"
        disabled={resetDisabled}
        onClick={onResetClicked}
        type="button"
        className="form-submit-section__button-left"
        inverted
      />
      <ActionButton title="Anfrage absenden" disabled={submitDisabled} inverted />
    </div>
  );
};

FormSubmitSection.propTypes = {
  resetDisabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onResetClicked: PropTypes.func,
};

export { FormSubmitSection };
