import { EINSTALLER_STATUS } from 'components/GuestExtension/EInstallerStatus';
import {
  TENANT_ID,
  getTenantId,
  isBUofCurrentTenant,
  isGridnetProviderOfCurrentTenant,
  GRIDNET_PROVIDER,
} from './tenantUtil';
import { EIV_ENTRY_TYPE, EIV_STATUS, EIV_STATUS_TO_SHOW, getResealAllowedIds } from 'api/APIUtils';
import { parse, format, add, isBefore } from 'date-fns';
import { getInstallersContractsByInstallerId } from 'api/installer/GetInstallersContractsByInstallerId';
import { getInstallersSealsByInstallerId } from 'api/installer/GetInstallersSealsByInstallerId';
import { isEqual } from 'lodash';

export const KEYS_TO_GERMAN = {
  companyName1: 'Firmenname1',
  companyName2: 'Firmenname2',
  companyName3: 'Firmenname3',
  companyOwner: 'Firmeninhaber',
  companyStreet: 'Straße',
  companyHouseNumber: 'Hausnummer',
  companyPostal: 'PLZ',
  companyCity: 'Ort',
  companyRegion: 'Ortsteil',
  mobile: 'Mobil',
  fax: 'Fax',
  phone: 'Telefon',
  craftsChamberArea: 'Hwk-Bereich',
  craftsChamberNumber: 'Hwk-Nummer',
  craftsChamberDate: 'Hwk-Datum',
  businessRegistrationLocation: 'Gewerbeanmeldung Ort',
  businessRegistrationDate: 'Gewerbeanmeldung Datum',
  commercialRegisterNumber: 'HRA/B Nummer',
  commercialRegisterDate: 'Letzte Änderung (Register)',
  gridProviderEnergy: 'Netzbetreiber Strom',
  foreignCompanyIdEnergy: 'Ausweisnummer Fremd Strom',
  gridProviderGas: 'Netzbetreiber Gas',
  foreignCompanyIdGas: 'Ausweisnummer Fremd Gas',
};

const BAG_KEYS = [
  'craftsChamberArea',
  'craftsChamberNumber',
  'craftsChamberDate',
  'businessRegistrationLocation',
  'businessRegistrationDate',
  'commercialRegisterNumber',
  'commercialRegisterDate',
  'companyOwner',
];

export const BRANCH_TYPE = {
  GAS: 'GAS',
  ENERGY: 'ENERGY',
};
export const BRANCH_TYPE_TO_VARIABLE_NAME = {
  [BRANCH_TYPE.GAS]: 'Gas',
  [BRANCH_TYPE.ENERGY]: 'Energy',
};
export const INSTALLER_ENTRIES = {
  MAIN_ENERGY_ONLY: 'mainEnergyOnly',
  MAIN_GAS_ONLY: 'mainGasOnly',
  GUEST_ONLY: 'guestOnly',
  MAIN_BOTH: 'mainBoth',
  MAIN_ENERGY_GUEST_GAS: 'mainEnergyGuestGas',
  GUEST_ENERGY_MAIN_GAS: 'guestEnergyMainGas',
};

export const BRANCH_TYPE_TO_GERMAN_NAME = {
  [BRANCH_TYPE.GAS]: 'Gas',
  [BRANCH_TYPE.ENERGY]: 'Strom',
};

export const EIV_STATUS_TO_GERMAN_NAME = {
  [EIV_STATUS.ACTIVE]: 'Aktiv',
  [EIV_STATUS.INACTIVE]: 'Inaktiv',
  [EIV_STATUS.IN_PROGRESS]: 'In Bearbeitung',
};
export const EIV_STATUS_ALLOW_RENDER = [EIV_STATUS.ACTIVE, EIV_STATUS.INACTIVE];
export const EIV_ENTRY_TYPE_TO_GERMAN_NAME = {
  main: 'Stamm',
  guest: 'Gast',
};
export const branchToCapital = (word) => {
  if (word) {
    word = word.toLowerCase();
    // Capitalize the first letter and concatenate it with the rest of the word
    return word.charAt(0).toUpperCase() + word.slice(1);
  } else {
    // If the word is empty, return it as is
    return word;
  }
};
export const prepGuestExtensionData = (initialData, newValues) => {
  let result = {
    companyName1: { first: initialData.companyName1, second: newValues.companyName1 },
    companyName2: { first: initialData.companyName2, second: newValues.companyName2 },
    companyName3: { first: initialData.companyName3, second: newValues.companyName3 },
    companyOwner: { first: initialData.companyOwner, second: newValues.companyOwner },
    companyId: initialData.companyId,
    companyStreet: { first: initialData.companyStreet, second: newValues.companyStreet },
    companyHouseNumber: {
      first: initialData.companyHouseNumber,
      second: newValues.companyHouseNumber,
    },
    companyPostal: { first: initialData.companyPostal, second: newValues.companyPostal },
    companyCity: { first: initialData.companyCity, second: newValues.companyCity },
    companyRegion: { first: initialData.companyRegion, second: newValues.companyRegion },
    gridProviderEnergy: {
      first: initialData.gridProviderEnergy,
      second: newValues.gridProviderEnergy,
    },
    foreignCompanyIdEnergy: {
      first: initialData.foreignCompanyIdEnergy,
      second: newValues.foreignCompanyIdEnergy,
    },
    gridProviderGas: { first: initialData.gridProviderGas, second: newValues.gridProviderGas },
    foreignCompanyIdGas: {
      first: initialData.foreignCompanyIdGas,
      second: newValues.foreignCompanyIdGas,
    },
    dsgvoCanSendMail: { first: initialData.canSendMail, second: newValues.canSendMail },
    dsgvoAddressPublic: { first: initialData.isAddressPublic, second: newValues.isAddressPublic },
    installers: [],
    questionInput: newValues.questionInput?.trim(),
    documents: newValues.documents.length > 0 ? newValues.documents : undefined,
    postalOfOrder: newValues.postalOfOrder || '',
  };

  if (getTenantId() === TENANT_ID.BAG) {
    const additionalBAGField = {
      limitationGas: initialData.limitationGas,
      limitationEnergy: initialData.limitationEnergy,
    };
    result = { ...result, ...additionalBAGField };
  }

  newValues.installers.forEach((newInstaller, index) => {
    const oldInstaller = initialData.installers[index];

    const installer = {
      firstname: { first: oldInstaller && oldInstaller.firstname, second: newInstaller.firstname },
      lastname: { first: oldInstaller && oldInstaller.lastname, second: newInstaller.lastname },
      birthdate:
        getTenantId() === TENANT_ID.BAG
          ? { first: oldInstaller && oldInstaller.birthdate, second: newInstaller.birthdate }
          : undefined,
      energyValidityDate: {
        first: oldInstaller && oldInstaller.energyValidityDate,
        second: newInstaller.energyValidityDate,
      },
      energyDocuments: newInstaller.energyDocuments,
      gasValidityDate: {
        first: oldInstaller && oldInstaller.gasValidityDate,
        second: newInstaller.gasValidityDate,
      },
      gasDocuments: newInstaller.gasDocuments,
      deletedReason: newInstaller.deletedReason,
      deletedDate: newInstaller.deletedDate,
    };

    let status = EINSTALLER_STATUS.UNCHANGED;
    if (newInstaller.isDeleted) {
      status = EINSTALLER_STATUS.DELETED;
    } else if (newInstaller.isNew) {
      status = EINSTALLER_STATUS.NEW;
    } else if (
      !newInstaller.isNew &&
      ((newInstaller.energyDocuments && newInstaller.energyDocuments.length > 0) ||
        (newInstaller.gasDocuments && newInstaller.gasDocuments.length > 0))
    ) {
      status = EINSTALLER_STATUS.CHANGED;
    }

    installer.status = {
      first: EINSTALLER_STATUS.UNCHANGED,
      second: status,
    };

    result.installers.push(installer);
  });

  return result;
};

export const replaceInstallerDateWithBool = (initialData) => {
  const modifiedData = { ...initialData };

  modifiedData.installers = modifiedData.installers.map((installer) => {
    return {
      ...installer,
      isGas: installer.gasValidityDate ? true : false,
      isEnergy: installer.energyValidityDate ? true : false,
    };
  });
  return modifiedData;
};

//TODO: this needs refactoring onto a new file for circular imports
export const shouldDebFirstPageSectionFieldRender = (fieldName, installerStatusSummary) => {
  const bagFields = [
    'companyOwner',
    'craftsChamberArea',
    'craftsChamberNumber',
    'craftsChamberDate',
    'businessRegistrationLocation',
    'businessRegistrationDate',
    'commercialRegisterNumber',
    'craftsChamberDate',
  ];
  const energyGuestFields = ['gridProviderEnergy', 'foreignCompanyIdEnergy'];
  const gasGuestFields = ['gridProviderGas', 'foreignCompanyIdGas'];
  switch (getTenantId()) {
    case TENANT_ID.BAG:
      if (energyGuestFields.includes(fieldName)) {
        return (
          doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
          isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
        );
      } else if (gasGuestFields.includes(fieldName)) {
        return (
          doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
          isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
        );
      } else {
        return true;
      }
    default:
      if (bagFields.includes(fieldName)) {
        return false;
      } else {
        if (energyGuestFields.includes(fieldName)) {
          return (
            doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.ENERGY)
          );
        } else if (gasGuestFields.includes(fieldName)) {
          return (
            doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary) &&
            isBranchGuest(installerStatusSummary, BRANCH_TYPE.GAS)
          );
        } else {
          return true;
        }
      }
  }
};

export const doesInstallerHaveBranch = (branch, installerStatusSummary) => {
  return (
    installerStatusSummary[branch].entryType !== 'none' &&
    [EIV_STATUS.ACTIVE, EIV_STATUS.INACTIVE, EIV_STATUS.IN_PROGRESS].includes(
      installerStatusSummary[branch].status
    )
  );
};

export const prepDebData = (
  initialData,
  firstPageValues,
  secondPageValues,
  subject,
  documents,
  installerStatusSummary
) => {
  const formDataEntries = [];
  const expertEntries = prepDebExperts(initialData, firstPageValues, installerStatusSummary);

  Object.keys(KEYS_TO_GERMAN).forEach((key) => {
    if (shouldDebFirstPageSectionFieldRender(key, installerStatusSummary))
      if (BAG_KEYS.includes(key)) {
        if (getTenantId() == TENANT_ID.BAG) {
          formDataEntries.push({
            first: KEYS_TO_GERMAN[key],
            second: {
              first: firstPageValues[key] || '-',
              second: initialData[key] || '-',
            },
          });
        }
      } else {
        formDataEntries.push({
          first: KEYS_TO_GERMAN[key],
          second: {
            first: firstPageValues[key] || '-',
            second: initialData[key] || '-',
          },
        });
      }
  });

  const documentEntries = documents.map((document) => {
    return {
      contentType: document.contentType,
      base64: document.base64.split(',')[1],
      fileName: document.name,
    };
  });

  const gasType =
    EIV_ENTRY_TYPE_TO_GERMAN_NAME[installerStatusSummary[BRANCH_TYPE.GAS].entryType] || '-';
  const energyType =
    EIV_ENTRY_TYPE_TO_GERMAN_NAME[installerStatusSummary[BRANCH_TYPE.ENERGY].entryType] || '-';
  const isEnergyExtended = firstPageValues.energyExtension;

  const isGasExtented = firstPageValues.gasExtension;
  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

  const baseDataEntries = [
    { first: 'Email', second: initialData.email },
    { first: 'Eintragungsnummer', second: initialData.companyId },
    ...(getTenantId() == TENANT_ID.BAG && hasEnergyRow
      ? [{ first: 'Beschränkung Strom', second: initialData.limitationEnergy }]
      : []),
    ...(getTenantId() == TENANT_ID.BAG && hasGasRow
      ? [{ first: 'Beschränkung Gas', second: initialData.limitationGas }]
      : []),
    ...(hasEnergyRow
      ? [
          {
            first: 'Status Strom Eintragung',
            second: `${energyType} ${isEnergyExtended ? 'verlängert' : ''}`,
          },
        ]
      : []),
    ...(hasEnergyRow
      ? [
          {
            first: 'Strom gültig bis',
            second: installerStatusSummary[BRANCH_TYPE.ENERGY].expireDate,
          },
        ]
      : []),
    ...(hasGasRow
      ? [
          {
            first: 'Status Gas Eintragung',
            second: `${gasType} ${isGasExtented ? 'verlängert' : ''}`,
          },
        ]
      : []),
    ...(hasGasRow
      ? [
          {
            first: 'Gas gültig bis',
            second: installerStatusSummary[BRANCH_TYPE.GAS].expireDate,
          },
        ]
      : []),
    { first: 'Anregungen', second: secondPageValues.changes || '-' },
  ];

  if (getTenantId() == TENANT_ID.AVA) {
    baseDataEntries.push({
      first: 'PLZ Auftragsort',
      second: firstPageValues.doesNeedAvaGuestPostal
        ? firstPageValues.avaGuestPostal
        : firstPageValues.companyPostal,
    });
  }

  const sealsEntries = prepDebSeals(initialData, firstPageValues);
  return {
    subject,
    baseDataEntries,
    formDataEntries,
    expertEntries,
    sealsEntries,
    documents: documentEntries,
  };
};

const prepDebSeals = (initialData, firstPageValues) => {
  const sealsEntries = firstPageValues.seals.map((seal) => {
    const oldSeal = initialData.seals.find((searchSeal) => searchSeal.id == seal.id);
    let sealAction = '';
    if (isEqual(oldSeal, seal)) {
      sealAction = 'Unverändert';
    } else {
      if (seal.lostDate !== oldSeal.lostDate) {
        sealAction = 'Verloren';
      }
    }
    return [
      {
        first: 'Änderung',
        second: {
          first: sealAction,
          second: 'Unverändert',
        },
      },
      {
        first: 'Id',
        second: {
          first: seal.id,
          second: oldSeal.id,
        },
      },
      {
        first: 'Zangen/Nuss Nr.',
        second: {
          first: seal.sealNumber,
          second: oldSeal.sealNumber,
        },
      },
      ...(seal.lostDate
        ? [
            {
              first: 'Verlustdatum',
              second: {
                first: seal.lostDate,
                second: '-',
              },
            },
          ]
        : []),
      ...(seal.lostDate
        ? [
            {
              first: 'Grund des Verlustes',
              second: {
                first: seal.lostReason || '-',
                second: '-',
              },
            },
          ]
        : []),
    ];
  });
  return sealsEntries;
};

const prepDebExperts = (initialData, firstPageValues, installerStatusSummary) => {
  const experts = [...firstPageValues.experts];
  const expertEntries = [];
  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);
  experts.forEach((expert) => {
    if (
      expert &&
      ((hasGasRow && EIV_STATUS_TO_SHOW.includes(expert.statusGas)) ||
        (hasEnergyRow && EIV_STATUS_TO_SHOW.includes(expert.statusEnergy)))
    ) {
      const oldExpert = initialData.experts.find((search) => {
        return search.id == expert.id;
      });
      const userActions = getExpertActions(
        expert,
        oldExpert,
        firstPageValues,
        installerStatusSummary
      );
      const shouldTryShowingValidityDate = ['Verlängert', 'Neu'];
      const eivStatusexisting = [
        EIV_STATUS.ACTIVE,
        EIV_STATUS.ARCHIVED,
        EIV_STATUS.DELETED,
        EIV_STATUS.IN_PROGRESS,
        EIV_STATUS.INACTIVE,
      ];
      const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);
      const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

      expertEntries.push([
        ...(hasEnergyRow
          ? [
              {
                first: 'Änderung Strom',
                second: { first: userActions.energyAction, second: 'Unverändert' },
              },
            ]
          : []),
        ...(hasGasRow
          ? [
              {
                first: 'Änderung Gas',
                second: { first: userActions.gasAction, second: 'Unverändert' },
              },
            ]
          : []),

        ...(eivStatusexisting.includes(expert.statusGas) ||
        eivStatusexisting.includes(expert.statusEnergy)
          ? [
              {
                first: 'Id',
                second: { first: expert.id, second: expert.id },
              },
            ]
          : []),
        {
          first: 'Vorname',
          second: { first: expert.firstName, second: oldExpert?.firstName || '-' },
        },
        {
          first: 'Nachname',
          second: { first: expert.lastName, second: oldExpert?.lastName || '-' },
        },
        ...(shouldTryShowingValidityDate.includes(userActions.energyAction) &&
        expert.expertEnergyValidityDate
          ? [
              {
                first: 'Stromausweis Gültigkeitsdatum',
                second: {
                  first: expert.expertEnergyValidityDate || '-',
                  second: oldExpert?.expertEnergyValidityDate || '-',
                },
              },
            ]
          : []),
        ...(shouldTryShowingValidityDate.includes(userActions.gasAction) &&
        expert.expertGasValidityDate
          ? [
              {
                first: 'Gasausweis Gültigkeitsdatum',
                second: {
                  first: expert.expertGasValidityDate || '-',
                  second: oldExpert?.expertGasValidityDate || '-',
                },
              },
            ]
          : []),
        ...(getTenantId() === TENANT_ID.BAG
          ? [
              {
                first: 'Geburtsdatum',
                second: { first: expert.birthdate || '-', second: oldExpert?.birthdate || '-' },
              },
            ]
          : []),
        ...(expert.statusEnergy === EIV_STATUS.DELETED
          ? [
              {
                first: 'Grund der Löschung Strom',
                second: { first: expert.deletionReasonEnergy || '-', second: '-' },
              },
            ]
          : []),
        ...(expert.statusEnergy === EIV_STATUS.DELETED
          ? [
              {
                first: 'Löschdatum Strom',
                second: { first: expert.deletionDateEnergy || '-', second: '-' },
              },
            ]
          : []),
        ...(expert.statusGas === EIV_STATUS.DELETED
          ? [
              {
                first: 'Grund der Löschung Gas',
                second: { first: expert.deletionReasonGas || '-', second: '-' },
              },
            ]
          : []),

        ...(expert.statusGas === EIV_STATUS.DELETED
          ? [
              {
                first: 'Löschdatum Gas',
                second: { first: expert.deletionDateGas || '-', second: '-' },
              },
            ]
          : []),
      ]);
    }
  });
  return expertEntries;
};

const isExpertBranchExtendedFromCheckbox = (
  expert,
  branch,
  installerStatusSummary,
  branchCheckboxValue
) => {
  if (
    !isBranchGuest(installerStatusSummary, branch) &&
    EIV_STATUS_TO_SHOW.includes(expert[`status${BRANCH_TYPE_TO_VARIABLE_NAME[branch]}`])
  ) {
    return branchCheckboxValue;
  } else {
    return false;
  }
};

const getExpertActions = (expert, oldExpert, firstPageValues, installerStatusSummary) => {
  //NEW ACTION
  let energyAction = 'Unverändert';
  let gasAction = 'Unverändert';

  if (!oldExpert) {
    if (expert.statusGas == EIV_STATUS.NEW) {
      gasAction = 'Neu';
    }
    if (expert.statusEnergy === EIV_STATUS.NEW) {
      energyAction = 'Neu';
    }
    return {
      energyAction,
      gasAction,
    };
  }

  const isEnergyBranchExtendedFromCheckbox = isExpertBranchExtendedFromCheckbox(
    expert,
    BRANCH_TYPE.ENERGY,
    installerStatusSummary,
    firstPageValues.energyExtension
  );
  const isGasBranchExtendedFromCheckbox = isExpertBranchExtendedFromCheckbox(
    expert,
    BRANCH_TYPE.GAS,
    installerStatusSummary,
    firstPageValues.gasExtension
  );
  if (
    !isEnergyBranchExtendedFromCheckbox &&
    !isGasBranchExtendedFromCheckbox &&
    isEqual(expert, oldExpert)
  ) {
    energyAction = 'Unverändert';
    gasAction = 'Unverändert';
  }

  //EXTENDED ACTION FOR GAS AND BOTH

  if (EIV_STATUS_TO_SHOW.includes(expert.statusGas)) {
    if (isGasBranchExtendedFromCheckbox) {
      gasAction = 'Verlängert';
    }
    if (
      expert.expertGasValidityDate &&
      expert.expertGasValidityDate != oldExpert?.expertGasValidityDate
    ) {
      gasAction = 'Verlängert';
    }
  }
  //EXTENDED ACTION FOR ENERGY

  if (EIV_STATUS_TO_SHOW.includes(expert.statusEnergy)) {
    if (isEnergyBranchExtendedFromCheckbox) {
      energyAction = 'Verlängert ';
    }
    if (
      expert.expertEnergyValidityDate &&
      expert.expertEnergyValidityDate != oldExpert?.expertEnergyValidityDate
    ) {
      energyAction = 'Verlängert';
    }
  }

  //DELETED ACTION
  if (expert.statusGas == EIV_STATUS.DELETED) {
    gasAction = 'Gelöscht';
  }
  if (expert.statusEnergy == EIV_STATUS.DELETED) {
    energyAction = 'Gelöscht';
  }

  return {
    energyAction,
    gasAction,
  };
};

export const prepMainExtensionData = (initialData, newValues) => {
  let result = {
    companyName1: { first: initialData.companyName1, second: undefined },
    companyName2: { first: initialData.companyName2, second: undefined },
    companyName3: { first: initialData.companyName3, second: undefined },
    companyOwner: { first: initialData.companyOwner, second: undefined },
    companyId: initialData.companyId,
    companyStreet: { first: initialData.companyStreet, second: undefined },
    companyHouseNumber: {
      first: initialData.companyHouseNumber,
      second: undefined,
    },
    companyPostal: { first: initialData.companyPostal, second: undefined },
    companyCity: { first: initialData.companyCity, second: undefined },
    companyRegion: { first: initialData.companyRegion, second: undefined },
    gridProviderEnergy: {
      first: initialData.gridProviderEnergy,
      second: undefined,
    },
    gridProviderGas: { first: initialData.gridProviderGas, second: undefined },
    dsgvoCanSendMail: { first: initialData.canSendMail, second: newValues.canSendMail },
    dsgvoAddressPublic: { first: initialData.isAddressPublic, second: newValues.isAddressPublic },
    installers: [],
    changes: newValues.hasChanges ? newValues.changes.trim() : undefined,
    documents: newValues.documents.length > 0 ? newValues.documents : undefined,
  };

  if (getTenantId() === TENANT_ID.BAG) {
    const additionalBAGField = {
      limitationGas: initialData.limitationGas,
      limitationEnergy: initialData.limitationEnergy,
      craftsChamberArea: {
        first: initialData.craftsChamberArea,
        second: undefined,
      },
      craftsChamberNumber: {
        first: initialData.craftsChamberNumber,
        second: undefined,
      },
      craftsChamberDate: {
        first: initialData.craftsChamberDate,
        second: undefined,
      },
      businessRegistrationLocation: {
        first: initialData.businessRegistrationLocation,
        second: undefined,
      },
      businessRegistrationDate: {
        first: initialData.businessRegistrationDate,
        second: undefined,
      },
      commercialRegisterNumber: {
        first: initialData.commercialRegisterNumber,
        second: undefined,
      },
      commercialRegisterExceptDate: {
        first: initialData.commercialRegisterExceptDate,
        second: undefined,
      },
    };
    result = { ...result, ...additionalBAGField };
  }

  newValues.installers.forEach((newInstaller, index) => {
    const oldInstaller = initialData.installers[index];

    const installer = {
      firstname: { first: oldInstaller.firstname, second: undefined },
      lastname: { first: oldInstaller.lastname, second: undefined },
      birthdate:
        getTenantId() === TENANT_ID.BAG
          ? { first: oldInstaller.birthdate, second: undefined }
          : undefined,
      energyValidityDate: {
        first: oldInstaller.energyValidityDate,
        second: undefined,
      },
      energyDocuments: undefined,
      gasValidityDate: {
        first: oldInstaller.gasValidityDate,
        second: undefined,
      },
      gasDocuments: undefined,
      deletedReason: newInstaller.deletedReason,
      deletedDate: newInstaller.deletedDate,
    };

    let status = EINSTALLER_STATUS.UNCHANGED;
    if (newInstaller.isDeleted) {
      status = EINSTALLER_STATUS.DELETED;
    }

    installer.status = {
      first: EINSTALLER_STATUS.UNCHANGED,
      second: status,
    };

    result.installers.push(installer);
  });

  return result;
};

export const prepareDocumentDownloadData = (installer, experts, contracts) => {
  const data = {
    companyName1: installer.firmenname1,
    companyName2: installer.firmenname2 || '',
    companyName3: installer.firmenname3 || '',
    companyId: `${installer.id}`,
    companyStreet: installer.buero_strasse,
    companyHouseNumber: installer.buero_hausnummer,
    companyPostal: installer.buero_plz,
    referenznummer_strom: installer.referenznummer_strom || '',
    companyCity: installer.buero_ort,
    companyTypeEnergy: installer.b_betriebsart_strom?.name || '',
    companyTypeGas: installer.b_betriebsart_gas?.name || '',
    meterInstallationGas: parseMeterInstallation(installer.zaehlermontage_gas),
    meterInstallationEnergy: parseMeterInstallation(installer.zaehlermontage_strom),
    gridProviderEnergy: installer.b_netzbetreiber_strom,
    gridProviderGas: installer.b_netzbetreiber_gas,
    entryTypeEnergy: installer.eintragungsart_strom,
    entryTypeGas: installer.eintragungsart_gas,
    pflegendeBU: installer.pflegende_bu,
    limitationGas: installer.eintragungsart_erg_gas || '',
    limitationEnergy: installer.eintragungsart_erg_strom || '',
    isEnergyActive: installer.status_strom === EIV_STATUS.ACTIVE,
    isGasActive: installer.status_gas === EIV_STATUS.ACTIVE,
    canReseal: canReseal(contracts),
    installers: experts
      .filter((expert) => {
        const isValidEnergyStatus =
          expert.status_strom === EIV_STATUS.ACTIVE || expert.status_strom === EIV_STATUS.INACTIVE;
        const isValidGasStatus =
          expert.status_gas === EIV_STATUS.ACTIVE || expert.status_gas === EIV_STATUS.INACTIVE;

        return isValidEnergyStatus || isValidGasStatus;
      })
      .map((expert) => ({
        firstname: expert.vorname,
        lastname: expert.nachname,
        isEnergyActive: expert.status_strom === EIV_STATUS.ACTIVE,
        isGasActive: expert.status_gas === EIV_STATUS.ACTIVE,
        energyValidityDate:
          expert.status_strom === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_strom || installer.ausw_strom_gueltig || ''
              )
            : '',
        gasValidityDate:
          expert.status_gas === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_gas || installer.ausw_gas_gueltig || ''
              )
            : '',
      })),
  };

  return data;
};

export const shouldShowMyDataCard = (id, installerData) => {
  switch (id) {
    case 'guest-extension':
      return hasGuestEntry(installerData);
    case 'main-extension':
      return hasMainEntry(installerData);
    case 'document-download':
      return hasDownloadEntry(installerData);
    default:
      return true;
  }
};

export const prepareGuestExtensionInitialData = (installer, experts) => {
  const data = {
    companyName1: installer.firmenname1,
    companyName2: installer.firmenname2 || '',
    companyName3: installer.firmenname3 || '',
    companyOwner: installer.firmeninhaber || '',
    companyId: `${installer.id}`,
    companyStreet: installer.buero_strasse,
    companyHouseNumber: installer.buero_hausnummer,
    companyPostal: installer.buero_plz,
    companyCity: installer.buero_ort,
    companyRegion: installer.buero_ortsteil,
    gridProviderEnergy: installer?.b_netzbetreiber_strom?.name || '',
    foreignCompanyIdEnergy: installer.ausweisnr_fremd_strom || '',
    gridProviderGas: installer?.b_netzbetreiber_gas?.name || '',
    foreignCompanyIdGas: installer.ausweisnr_fremd_gas || '',
    email: installer.email,
    phone: installer.telefon_geschaeft || '',
    mobile: installer.telefon_mobil || '',
    isAddressPublic: installer.zu_adressveroeff === 'Ja',
    canSendMail: installer.zu_infoversand === 'Ja',
    limitationGas: installer.eintragungsart_erg_gas || '',
    limitationEnergy: installer.eintragungsart_erg_strom || '',
    installers: experts
      .filter((expert) => {
        const isValidEnergyStatus =
          expert.status_strom === EIV_STATUS.ACTIVE || expert.status_strom === EIV_STATUS.INACTIVE;
        const isValidGasStatus =
          expert.status_gas === EIV_STATUS.ACTIVE || expert.status_gas === EIV_STATUS.INACTIVE;

        return isValidEnergyStatus || isValidGasStatus;
      })
      .map((expert) => ({
        firstname: expert.vorname || '',
        lastname: expert.nachname || '',
        birthdate: parseEIVDateStringToEfixDateString(expert.geburtsdatum || ''),
        energyValidityDate:
          expert.status_strom === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_strom || installer.ausw_strom_gueltig || ''
              )
            : '',
        gasValidityDate:
          expert.status_gas === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_gas || installer.ausw_gas_gueltig || ''
              )
            : '',
        deletedReason: '',
        deletedDate: '',
        isGas: false,
        isEnergy: false,
        isDeleted: false,
      })),
    questionInput: '',
    postalOfOrder: '',
  };

  return data;
};
export const prepareDEBInitialData = (installer, experts) => {
  const data = {
    companyName1: installer.firmenname1 || '',
    companyName2: installer.firmenname2 || '',
    companyName3: installer.firmenname3 || '',
    companyOwner: installer.firmeninhaber || '',
    companyId: `${installer.id}`,
    companyStreet: installer.buero_strasse || '',
    companyHouseNumber: installer.buero_hausnummer || '',
    companyPostal: installer.buero_plz || '',
    companyCity: installer.buero_ort || '',
    companyRegion: installer.buero_ortsteil || '',
    gridProviderEnergy: installer?.b_netzbetreiber_strom?.name || '',
    gridProviderGas: installer?.b_netzbetreiber_gas?.name || '',
    foreignCompanyIdEnergy: installer.ausweisnr_fremd_strom || '',
    foreignCompanyIdGas: installer.ausweisnr_fremd_gas || '',
    email: installer.email || '',
    phone: installer.telefon_geschaeft || '',
    mobile: installer.telefon_mobil || '',
    isAddressPublic: installer.zu_adressveroeff === 'Ja',
    canSendMail: installer.zu_infoversand === 'Ja',
    limitationGas: installer.eintragungsart_erg_gas || '',
    limitationEnergy: installer.eintragungsart_erg_strom || '',
    installerGasValidityDate: parseEIVDateStringToEfixDateString(installer.ausw_gas_gueltig) || '',
    installerEnergyValidityDate:
      parseEIVDateStringToEfixDateString(installer.ausw_strom_gueltig) || '',
    energyAgreement: false,
    gasAgreement: false,
    energyExtension: false,
    gasExtension: false,
    craftsChamberArea: installer.b_hwkbereich?.name || '',
    craftsChamberNumber: installer.hwknummer || '',
    craftsChamberDate: parseEIVDateStringToEfixDateString(installer.hwkdatum),
    businessRegistrationLocation: installer.gewerbeanmeldung_ort || '',
    businessRegistrationDate: parseEIVDateStringToEfixDateString(installer.gewerbeanmeldung_datum),
    commercialRegisterNumber: installer.hrabnummer
      ? `${installer.hrabnummer} ${installer.registergericht || ''}`
      : '',
    commercialRegisterDate: parseEIVDateStringToEfixDateString(installer.register_letzte_aenderung),
    experts: experts.map((expert) => {
      return {
        firstName: expert.vorname || '',
        lastName: expert.nachname || '',
        expertGasValidityDate: parseEIVDateStringToEfixDateString(
          expert.gueltig_bis_gas || installer.ausw_gas_gueltig
        ),
        expertEnergyValidityDate: parseEIVDateStringToEfixDateString(
          expert.gueltig_bis_strom || installer.ausw_strom_gueltig
        ),
        birthdate: parseEIVDateStringToEfixDateString(expert.geburtsdatum),
        statusGas: expert.status_gas ? expert.status_gas : EIV_STATUS.NOT_SET,
        statusEnergy: expert.status_strom ? expert.status_strom : EIV_STATUS.NOT_SET,
        deletionReasonEnergy: '',
        deletionReasonGas: '',
        deletionDateEnergy: '',
        deletionDateGas: '',
        id: expert.id || '',
      };
    }),
  };

  return data;
};

export const prepareDEBCompleteData = async (installer, experts, status, setShowError) => {
  let finalObject = prepareDEBInitialData(installer, experts);
  finalObject.doesEnergyBranchExist = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, status);
  finalObject.doesGasBranchExist = doesInstallerHaveBranch(BRANCH_TYPE.GAS, status);

  const doesNeedAvaGuestPostal =
    getTenantId() === TENANT_ID.AVA && status.installerEntry === INSTALLER_ENTRIES.GUEST_ONLY;

  finalObject.doesNeedAvaGuestPostal = doesNeedAvaGuestPostal;
  finalObject.avaGuestPostal = '';

  const expertsFixed = finalObject.experts.map((expert) => {
    return {
      ...expert,
      isMainGas: status[BRANCH_TYPE.GAS].entryType === 'main',
      isMainEnergy: status[BRANCH_TYPE.ENERGY].entryType === 'main',
      editingStatusGas: '',
      editingStatusEnergy: '',
    };
  });

  finalObject.experts = expertsFixed;

  await getInstallersContractsByInstallerId(installer.id, setShowError).then(
    async (installerContractsRes) => {
      finalObject.contracts = installerContractsRes.map((contract) => {
        let contractReturn = {};

        contractReturn.id = contract.id;
        contractReturn.contractStatus = contract.vertragsstatus || '';
        contractReturn.contractType = {
          name: contract.vertragsart.name,
        };
        contractReturn.validFrom = parseEIVDateStringToEfixDateString(contract.abgeschlossen_am);
        contractReturn.cancelledOn = parseEIVDateStringToEfixDateString(contract.gekundigtzum);

        return contractReturn;
      });
      await getInstallersSealsByInstallerId(installer.id, setShowError).then((sealsRes) => {
        finalObject.seals = sealsRes.map((seal) => {
          let sealsReturn = {};

          sealsReturn.id = seal.id;
          sealsReturn.sealNumber = seal.zangennr;
          sealsReturn.status = { name: seal.status?.name || '' };
          sealsReturn.lostDate = '';
          sealsReturn.lostReason = '';
          sealsReturn.issuedOn = parseEIVDateStringToEfixDateString(seal.ausgegeben_am);
          sealsReturn.returnedOn = parseEIVDateStringToEfixDateString(seal.zurueckgegeben_am);
          sealsReturn.lostOn = parseEIVDateStringToEfixDateString(seal.verlust_am);

          return sealsReturn;
        });
      });
    }
  );
  return finalObject;
};
export const prepareMainExtensionInitialData = (installer, experts) => {
  const data = {
    companyName1: installer.firmenname1,
    companyName2: installer.firmenname2 || '',
    companyName3: installer.firmenname3 || '',
    companyOwner: installer.firmeninhaber || '',
    postalOfOrder: '',
    companyId: `${installer.id}`,
    companyStreet: installer.buero_strasse,
    companyHouseNumber: installer.buero_hausnummer,
    companyPostal: installer.buero_plz,
    companyCity: installer.buero_ort,
    companyRegion: installer.buero_ortsteil || '',
    gridProviderEnergy: installer?.b_netzbetreiber_strom?.name || '',
    gridProviderGas: installer?.b_netzbetreiber_gas?.name || '',
    email: installer.email,
    fax: installer.fax,
    phone: installer.telefon_geschaeft || '',
    mobile: installer.telefon_mobil || '',
    isAddressPublic: installer.zu_adressveroeff === 'Ja',
    canSendMail: installer.zu_infoversand === 'Ja',
    limitationGas: installer.eintragungsart_erg_gas || '',
    limitationEnergy: installer.eintragungsart_erg_strom || '',
    craftsChamberArea: installer.b_hwkbereich?.name || '',
    craftsChamberNumber: installer.hwknummer || '',
    craftsChamberDate: parseEIVDateStringToEfixDateString(installer.hwkdatum),
    businessRegistrationLocation: installer.gewerbeanmeldung_ort || '',
    businessRegistrationDate: parseEIVDateStringToEfixDateString(installer.gewerbeanmeldung_datum),
    commercialRegisterNumber: installer.hrabnummer
      ? `${installer.hrabnummer} ${installer.registergericht || ''}`
      : '',
    commercialRegisterExceptDate: parseEIVDateStringToEfixDateString(
      installer.register_letzte_aenderung
    ),
    installers: experts
      .filter((expert) => {
        const isValidEnergyStatus =
          expert.status_strom === EIV_STATUS.ACTIVE || expert.status_strom === EIV_STATUS.INACTIVE;
        const isValidGasStatus =
          expert.status_gas === EIV_STATUS.ACTIVE || expert.status_gas === EIV_STATUS.INACTIVE;

        return isValidEnergyStatus || isValidGasStatus;
      })
      .map((expert) => ({
        firstname: expert.vorname,
        lastname: expert.nachname,
        birthdate: parseEIVDateStringToEfixDateString(expert.geburtsdatum || ''),
        energyValidityDate:
          expert.status_strom === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_strom || installer.ausw_strom_gueltig || ''
              )
            : '',
        gasValidityDate:
          expert.status_gas === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_gas || installer.ausw_gas_gueltig || ''
              )
            : '',
        deletedReason: '',
        deletedDate: '',
      })),
  };

  return data;
};

export const isDocumentDownloadElegible = (documentInformation, parsedData, selectedExpert) => {
  const { type, id: documentId, guest } = documentInformation;
  const {
    isEnergyActive: isEnergyActiveInstaller,
    isGasActive: isGasActiveInstaller,
    gridProviderEnergy,
    gridProviderGas,
    pflegendeBU,
  } = parsedData;
  const {
    isEnergyActive: isEnergyActiveExpert,
    energyValidityDate,
    isGasActive: isGasActiveExpert,
    gasValidityDate,
  } = selectedExpert;
  if (
    type === BRANCH_TYPE.ENERGY &&
    getEntryType(parsedData.entryTypeEnergy) === 'guest' &&
    guest
  ) {
    if (isEnergyActiveInstaller && isEnergyActiveExpert && energyValidityDate) {
      return true;
    }
  } else if (
    type === BRANCH_TYPE.GAS &&
    getEntryType(parsedData.entryTypeGas) === 'guest' &&
    guest
  ) {
    if (isGasActiveInstaller && isGasActiveExpert && gasValidityDate) {
      return true;
    }
  } else if (
    type === BRANCH_TYPE.ENERGY &&
    getEntryType(parsedData.entryTypeEnergy) === 'main' &&
    !guest
  ) {
    if (isEnergyActiveInstaller && isEnergyActiveExpert && energyValidityDate) {
      if ([TENANT_ID.SHNG, TENANT_ID.AVA, TENANT_ID.BAG].includes(getTenantId())) {
        // NB is one of grid providers of SHNG
        if (isGridnetProviderOfCurrentTenant(gridProviderEnergy.id)) {
          if (gridProviderEnergy.id === documentId) {
            return true;
          }
        } else {
          const mappedGridnetProvider = GRIDNET_PROVIDER.SHNG[pflegendeBU];
          if (!mappedGridnetProvider) {
            if (documentId === GRIDNET_PROVIDER.SHNG.SHNG) {
              return true;
            }
          } else {
            if (documentId === mappedGridnetProvider) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    }
  } else if (
    type === BRANCH_TYPE.GAS &&
    getEntryType(parsedData.entryTypeGas) === 'main' &&
    !guest
  ) {
    if (isGasActiveInstaller && isGasActiveExpert && gasValidityDate) {
      if ([TENANT_ID.SHNG, TENANT_ID.AVA, TENANT_ID.BAG].includes(getTenantId())) {
        // NB is one of grid providers of SHNG
        if (isGridnetProviderOfCurrentTenant(gridProviderGas.id)) {
          if (gridProviderGas.id === documentId) {
            return true;
          }
        } else {
          const mappedGridnetProvider = GRIDNET_PROVIDER.SHNG[pflegendeBU];
          if (!mappedGridnetProvider) {
            if (documentId === GRIDNET_PROVIDER.SHNG.SHNG) {
              return true;
            }
          } else {
            if (documentId === mappedGridnetProvider) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
    }
  }
  return false;
};

export const getInstallerStatusbarData = (installer) => {
  let energyEntry;
  let gasEntry;
  let limitationEntry;

  const installerStatusSummary = getInstallerStatusSummary(installer);

  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);

  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

  if (hasEnergyRow) {
    energyEntry = {
      ...installerStatusSummary[BRANCH_TYPE.ENERGY],
      shouldExtend: isBefore(
        parse(installerStatusSummary[BRANCH_TYPE.ENERGY].expireDate, 'dd.MM.yyyy', new Date()),
        add(new Date(), { days: 30 })
      ),
      branch: BRANCH_TYPE_TO_GERMAN_NAME[BRANCH_TYPE.ENERGY],
    };
  }

  if (hasGasRow) {
    gasEntry = {
      ...installerStatusSummary[BRANCH_TYPE.GAS],
      shouldExtend: isBefore(
        parse(installerStatusSummary[BRANCH_TYPE.GAS].expireDate, 'dd.MM.yyyy', new Date()),
        add(new Date(), { days: 30 })
      ),
      branch: BRANCH_TYPE_TO_GERMAN_NAME[BRANCH_TYPE.GAS],
    };
  }

  let companyName = installer.firmenname2
    ? installer.firmenname1 + ' ' + installer.firmenname2
    : installer.firmenname1;
  if (getTenantId() === TENANT_ID.BAG) {
    limitationEntry = {
      energy: hasEnergyRow ? installer.eintragungsart_erg_strom : undefined,
      gas: hasGasRow ? installer.eintragungsart_erg_gas : undefined,
    };
    if (installer.firmenname3) {
      companyName += ' ' + installer.firmenname3;
    }
  }

  return {
    id: installer.id,
    name: companyName,
    energyEntry,
    gasEntry,
    limitationEntry,
  };
};

export const getInstallerStatusSummary = (installer) => {
  const [energyStatus, energyExpireDate] = getBranchStatusAndExpireDate(
    installer,
    BRANCH_TYPE.ENERGY
  );
  const [gasStatus, gasExpireDate] = getBranchStatusAndExpireDate(installer, BRANCH_TYPE.GAS);

  const entryTypeEnergy = getEntryType(installer.eintragungsart_strom);
  const entryTypeGas = getEntryType(installer.eintragungsart_gas);
  const installerEntry = getInstallerEntry(entryTypeEnergy, entryTypeGas, energyStatus, gasStatus);

  return {
    [BRANCH_TYPE.ENERGY]: {
      status: energyStatus,
      entryType: getEntryType(installer.eintragungsart_strom),
      companyType: installer.b_betriebsart_strom?.name,
      expireDate: parseEIVDateStringToEfixDateString(energyExpireDate),
    },
    [BRANCH_TYPE.GAS]: {
      status: gasStatus,
      entryType: getEntryType(installer.eintragungsart_gas),
      companyType: installer.b_betriebsart_gas?.name,
      expireDate: parseEIVDateStringToEfixDateString(gasExpireDate),
    },
    installerEntry,
  };
};

export const getBranchStatusAndExpireDate = (installer, branchType) => {
  const branchTypeVar = branchType === BRANCH_TYPE.ENERGY ? 'strom' : 'gas';

  return [installer[`status_${branchTypeVar}`], installer[`ausw_${branchTypeVar}_gueltig`]];
};

export const parseEIVDateStringToEfixDateString = (dateString) => {
  if (!dateString) {
    return '';
  }
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yyyy');
};

export const isBranchGuest = (installerStatusSummary, branch) => {
  return installerStatusSummary[branch].entryType === 'guest';
};

export const canBranchRenderByStatus = (installerStatusSummary, branch) => {
  return EIV_STATUS_ALLOW_RENDER.includes(installerStatusSummary[branch].status);
};

export const hasGuestBranchExtended = (experts, initialExperts, branch) => {
  let hasExtended;
  if (branch === BRANCH_TYPE.ENERGY) {
    hasExtended = experts.some((expert, index) => {
      if (
        initialExperts[index] &&
        expert.expertEnergyValidityDate !== initialExperts[index].expertEnergyValidityDate
      ) {
        return true;
      }
      return false;
    });
  }
  if (branch === BRANCH_TYPE.GAS) {
    hasExtended = experts.some((expert, index) => {
      if (
        initialExperts[index] &&
        expert.expertGasValidityDate !== initialExperts[index].expertGasValidityDate
      ) {
        return true;
      }
      return false;
    });
  }

  return hasExtended;
};

export function booleanToGerman(boolValue) {
  if (boolValue) {
    return 'Ja';
  } else {
    return 'Nein';
  }
}
// PRIVATE

// YYYY-MM-DD to dd.MM.yyyy (e.g. 2020-01-01 to 01.01.2020)

const hasMainEntry = (installerData) => {
  const {
    pflegende_bu,
    b_netzbetreiber_strom,
    b_netzbetreiber_gas,
    eintragungsart_strom,
    eintragungsart_gas,
  } = installerData;

  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }
  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  if (energyEntryType !== 'main' && gasEntryType !== 'main') {
    return false;
  }
  // SHNG and EDIS are skipped due to changing list of grid providers
  if ([TENANT_ID.SHNG, TENANT_ID.EDI].includes(getTenantId())) {
    return true;
  } else {
    if (energyEntryType === 'main' && isGridnetProviderOfCurrentTenant(b_netzbetreiber_strom.id)) {
      return true;
    }
    if (gasEntryType === 'main' && isGridnetProviderOfCurrentTenant(b_netzbetreiber_gas.id)) {
      return true;
    }
  }

  return false;
};

const hasDownloadEntry = (installerData) => {
  const { pflegende_bu, eintragungsart_strom, eintragungsart_gas } = installerData;

  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }

  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  const [energyStatus] = getBranchStatusAndExpireDate(installerData, BRANCH_TYPE.ENERGY);
  const [gasStatus] = getBranchStatusAndExpireDate(installerData, BRANCH_TYPE.GAS);

  // checking if both of them are guest to block the download tile showing as SHNG only want main entries downloads for now
  if (getTenantId() === TENANT_ID.SHNG) {
    if (
      (energyEntryType === 'guest' && gasEntryType === 'guest') ||
      (energyEntryType === 'guest' && gasEntryType === 'none') ||
      (energyEntryType === 'none' && gasEntryType === 'guest')
    ) {
      return false;
    } else {
      if (
        energyEntryType === 'main' &&
        gasEntryType !== 'main' &&
        energyStatus !== EIV_STATUS.ACTIVE
      ) {
        return false;
      } else if (
        gasEntryType === 'main' &&
        energyEntryType !== 'main' &&
        gasStatus !== EIV_STATUS.ACTIVE
      ) {
        return false;
      }
    }
  }

  return true;
};

const hasGuestEntry = (installerData) => {
  const { pflegende_bu, eintragungsart_strom, eintragungsart_gas } = installerData;
  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }

  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  if (energyEntryType !== 'guest' && gasEntryType !== 'guest') {
    return false;
  }

  return true;
};

const getEntryType = (eintragungsart) => {
  if (!eintragungsart) {
    return 'none';
  }

  if (eintragungsart === EIV_ENTRY_TYPE.MAIN) {
    return 'main';
  }

  if (eintragungsart === EIV_ENTRY_TYPE.GUEST) {
    return 'guest';
  }
};

//return one of the following:
//   mainEnergyOnly
// mainGasOnly
// guestOnly
// mainBoth
// mainEnergyGuestGas
// guestEnergyMainGas
// based on the values of enrgyEntry and gasEntry that can be 'main', 'guest' or 'none'

const getInstallerEntry = (energyEntry, gasEntry, statusEnergy, statusGas) => {
  const allowedStatuses = [EIV_STATUS.ACTIVE, EIV_STATUS.IN_PROGRESS, EIV_STATUS.INACTIVE];
  let energyType = '';
  let gasType = '';
  if (allowedStatuses.includes(statusEnergy)) {
    energyType = energyEntry;
  }
  if (allowedStatuses.includes(statusGas)) {
    gasType = gasEntry;
  }

  if (energyType === 'main' && gasType === 'main') {
    return INSTALLER_ENTRIES.MAIN_BOTH;
  } else if (energyType === 'main' && gasType === 'guest') {
    return INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS;
  } else if (energyType === 'guest' && gasType === 'main') {
    return INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS;
  } else if (energyType === 'main' && gasType === '') {
    return INSTALLER_ENTRIES.MAIN_ENERGY_ONLY;
  } else if (energyType === '' && gasType === 'main') {
    return INSTALLER_ENTRIES.MAIN_GAS_ONLY;
  } else if (energyType === 'guest' || gasType === 'guest') {
    return INSTALLER_ENTRIES.GUEST_ONLY;
  }
  return null; // or a default value if none of the conditions are met
};

const parseMeterInstallation = (meterInstallation) => {
  if (meterInstallation === '01' || meterInstallation === '02') {
    return 'Ja';
  } else {
    return 'Nein';
  }
};

const canReseal = (contracts) => {
  let active = 'Nein';
  contracts.forEach((contract) => {
    if (
      getResealAllowedIds().includes(contract.vertragsart.id) &&
      contract.vertragsstatus === 'Aktiv'
    ) {
      active = 'Ja';
      return;
    }
  });
  return active;
};
