import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import TwoColumnsLayout from '../../Layouts/TwoColumnLayout/TwoColumnLayout';
import EventsOverview from '../../components/Event/EventsOverview/EventsOverview';
import { MessageBox } from '../../components/MessageBox/MessageBox';
import { SearchBox } from '../../components/SearchBox';
import './EventsOverviewPage.scss';
import DatePicker from '../../components/DatePicker';
import { ArticlesAndEventsContext } from '../../context/ArticlesAndEventsLimit';
import { PageTitleSection } from '../../components/PageTitleSection/PageTitleSection';
import { CONSTANTS } from '../../global/constants';
import {
  GET_TOTAL_EVENT_PAGES_QUERY,
  GET_EVENT_QUERY,
  GET_PAST_EVENTS_QUERY,
  USER_STATUS_QUERY,
} from '../../Queries/Queries';
import { TENANT_ID, getTenantId } from '../../utils/tenantUtil';
import { format } from 'date-fns';
import { getArticlesOverviewPath } from 'utils/UrlUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const DATA = {
  [TENANT_ID.EDI]: {
    searchTitle: 'VERANSTALTUNGEN FILTERN',
    searchTagLabel: 'VERANSTALTUNGEN DURSCHSUCHEN',
    newsData: {
      title: 'Aktuelle Fachbeiträge',
      text: 'Sie möchten immer aktuell informiert sein? Oder suchen nach Fachbeiträgen zum Nachlesen? Wir informieren Sie regelmäßig aus dem Netzgebiet der E.DIS zu den Themen Strom, Gas oder Sonstiges. Hier erhalten Sie alle News auf einen Blick!',
      buttonText: 'Zu den Fachbeiträgen',
      url: getArticlesOverviewPath(),
    },
    title: 'Veranstaltungen',
    subText: 'Hier finden Sie alle Veranstaltungen rund um Strom, Gas und übergreifende Themen.',
  },
  [TENANT_ID.SHNG]: {
    searchTitle: 'VERANSTALTUNGEN FILTERN',
    searchTagLabel: 'VERANSTALTUNGEN DURSCHSUCHEN',
    newsData: {
      title: 'Aktuelle Fachbeiträge',
      text: 'Sie möchten sich über aktuelle Themen informieren oder technische Informationen nachlesen? In unserer Übersicht finden Sie alle aktuellen Nachrichten.',
      buttonText: 'Zu den Fachbeiträgen',
      url: getArticlesOverviewPath(),
    },
    title: 'Veranstaltungen',
    subText: 'Hier finden Sie alle Veranstaltungen rund um Strom, Gas und übergreifende Themen.',
  },
  [TENANT_ID.AVA]: {
    searchTitle: 'Veranstaltungen filtern',
    searchTagLabel: 'Veranstaltungszeitraum filtern',
    newsData: {
      title: 'Aktuelle Nachrichten',
      text: 'Sie möchten sich über aktuelle Themen informieren oder technische Informationen nachlesen? In unserer Übersicht finden Sie alle aktuellen Nachrichten.',
      buttonText: 'Zu den Nachrichten',
      url: getArticlesOverviewPath(),
    },
    title: 'Veranstaltungen',
    subText:
      'Hier finden Sie alle Veranstaltungen zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Avacon und aktuellen Themen.',
  },
  [TENANT_ID.BAG]: {
    searchTitle: 'Veranstaltungen filtern',
    searchTagLabel: 'Veranstaltungszeitraum filtern',
    newsData: {
      title: 'Aktuelle Nachrichten',
      text: 'Sie möchten sich über aktuelle Themen informieren oder technische Informationen nachlesen? In unserer Übersicht finden Sie alle aktuellen Nachrichten.',
      buttonText: 'Zu den Nachrichten',
      url: getArticlesOverviewPath(),
    },
    title: 'Veranstaltungen',
    subText:
      'Hier finden Sie alle Veranstaltungen zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Bayernwerk und aktuellen Themen.',
  },
};

const EventsOverviewPage = () => {
  const CONTENT = DATA[getTenantId()];

  const [searchCriteria, setSearchCriteria] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});
  const [cursor, setCursor] = useState(null);
  const { limit } = useContext(ArticlesAndEventsContext);
  const [action, setAction] = useState('FIRST');
  const [datePickerValue, setDatePickerValue] = useState([null, null]);
  const [currentPage, setCurrentPage] = useState(1);

  const updatePagination = (newAction, newCursor, page) => {
    setAction(newAction);
    setCursor(newCursor);
    setCurrentPage(page);
  };

  const { data: userStatusData, loading: userStatusLoading } = useQuery(USER_STATUS_QUERY);

  const { loading, error, data } = useQuery(GET_EVENT_QUERY, {
    variables: {
      searchCriteria: searchCriteria,
      action: action,
      cursor: cursor,
      limit: limit,
    },
  });

  const {
    loading: loadingForTotalPages,
    error: errorOnTotalPages,
    data: totalEventPages,
  } = useQuery(GET_TOTAL_EVENT_PAGES_QUERY, {
    variables: {
      searchCriteria: searchCriteria,
      limit: limit,
    },
  });

  const [
    fetchPastEvents,
    { loading: pastEventsLoading, data: pastEventsData, error: pastEventsError },
  ] = useLazyQuery(GET_PAST_EVENTS_QUERY);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userStatusLoading === false) {
      if (userStatusData?.userStatus?.iamType === 'EIAM') {
        fetchPastEvents();
      }
    }
  }, [userStatusLoading]);

  useEffect(() => {
    updatePagination('FIRST', null, 1);
  }, [searchCriteria]);

  let events = [];
  let totalPages = 0;
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="EventsOverview" />;
  }
  if (!loading && !error && !loadingForTotalPages && !errorOnTotalPages) {
    events = data.events;
    totalPages = totalEventPages.eventsPagesNumber;
  } else {
    return null;
  }

  const handleDateSubmit = ([startDate, endDate]) => {
    const tags = selectedTags.length ? selectedTags : null;

    if (startDate || endDate) {
      const startDateFormatted = startDate
        ? format(startDate, 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd');
      const endDateFormatted = endDate ? format(endDate, 'yyyy-MM-dd') : null;
      setSelectedDates({ startDate: startDateFormatted, endDate: endDateFormatted });
      setSearchCriteria({ startDate: startDateFormatted, endDate: endDateFormatted, tags });
    } else {
      setSelectedDates({});
      if (tags) {
        setSearchCriteria({ tags });
      } else {
        setSearchCriteria(null);
      }
    }
  };

  const handleTagSelection = (tag) => {
    let tagSelection = [];
    const tagIsActive = selectedTags.indexOf(tag) !== -1;

    tagIsActive
      ? (tagSelection = selectedTags.filter((activeTag) => activeTag !== tag))
      : (tagSelection = [...selectedTags, tag]);

    setSelectedTags(tagSelection);

    const dates = Object.prototype.hasOwnProperty.call(selectedDates, 'startDate')
      ? selectedDates
      : null;

    if (dates && tagSelection.length) {
      setSearchCriteria({ ...dates, tags: tagSelection });
    } else if (dates && !tagSelection.length) {
      setSearchCriteria({ ...dates, tags: null });
    } else if (!dates && tagSelection.length) {
      setSearchCriteria({ tags: tagSelection });
    } else {
      setSearchCriteria(null);
    }
  };

  const renderPageEventsList = () => {
    if (
      !userStatusLoading &&
      (userStatusData?.userStatus?.iamType !== 'EIAM' ||
        userStatusData?.userStatus?.role !== 'POWER_USER')
    )
      return null;

    if (pastEventsError) {
      console.error('pastEventsError', pastEventsError);
      return 'Error';
    }

    return (
      <>
        <PageTitleSection
          title={'Vergangene Events'}
          subText={'Maximal 30 Tage in der Vergangenheit geendete Veranstaltungen.'}
          notFramed
        />
        <EventsOverview
          loading={pastEventsLoading || !pastEventsData}
          eventData={pastEventsData?.pastEvents}
          totalPages={1}
          currentPage={0}
          isPastEvent
        />
      </>
    );
  };

  const leftColumnContent = (
    <>
      {renderPageEventsList()}
      <PageTitleSection title={CONTENT.title} subText={CONTENT.subText} notFramed />
      <SearchBox
        title={CONTENT.searchTitle}
        searchTagsLabel={CONTENT.searchTagLabel}
        formSlot={
          <DatePicker
            onSubmit={handleDateSubmit}
            value={datePickerValue}
            setValue={setDatePickerValue}
          />
        }
        handleTagSelection={handleTagSelection}
        selectedTags={selectedTags}
        tags={Object.values(CONSTANTS.TAGS_EVENTS)}
      />

      <EventsOverview
        loading={loading}
        eventData={events}
        setPaginationVars={updatePagination}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </>
  );

  return (
    <TwoColumnsLayout
      leftColumn={leftColumnContent}
      rightColumn={<MessageBox data={CONTENT.newsData} />}
    />
  );
};

MapTo('e-fix/components/content/eventsoverview')(EventsOverviewPage);
