import React from 'react';
import './FormErrorMessage.sass';
import PropTypes from 'prop-types';

const FormErrorMessage = ({ children }) => {
  return <div className="form-error-message">{children}</div>;
};

FormErrorMessage.propTypes = {
  children: PropTypes.string,
};

export { FormErrorMessage };
