import React from 'react';

import './FormGeneralInformationData.sass';
import PropTypes from 'prop-types';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';

const FormGeneralInformationData = ({ values }) => {
  return (
    <FormSectionWrapper>
      <FormFieldWrapper className="form-general-information-data__extra-data-field">
        <ThemedTextField
          fullWidth
          disabled
          id="limitationEnergy"
          name="limitationEnergy"
          label="Beschränkung Strom"
          variant="standard"
          value={values.limitationEnergy}
        />
      </FormFieldWrapper>
      <FormFieldWrapper className="form-general-information-data__extra-data-field">
        <ThemedTextField
          fullWidth
          disabled
          id="limitationGas"
          name="limitationGas"
          label="Beschränkung Gas"
          variant="standard"
          value={values.limitationGas}
        />
      </FormFieldWrapper>
    </FormSectionWrapper>
  );
};

FormGeneralInformationData.propTypes = {
  values: PropTypes.shape({
    limitationEnergy: PropTypes.string.isRequired,
    limitationGas: PropTypes.string.isRequired,
  }).isRequired,
};

export { FormGeneralInformationData };
