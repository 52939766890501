/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import { array, bool, string } from 'prop-types';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import Truncate from 'react-truncate';
import {
  REMOVE_NEW_TAG_FROM_ARTICLE_MUTATION,
  REMOVE_NEW_TAG_FROM_EVENT_MUTATION,
} from '../../Queries/Mutations';
import formatDate from '../../utils/formatDate';
import { ActionButton } from '../ActionButton/ActionButton';
import { SubTitle } from '../SubTitle/SubTitle';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import OuterBox from '../OuterBox/OuterBox';
import Tags from '../Tags/Tags';
import { CONSTANTS } from '../../global/constants';
import './OverviewList.scss';
import isNewEntry from './utils/isNewEntry';
import { ParticipantsListContext } from '../../context/ParticipantsList';
import { EventIdContext } from '../../context/EventIdContext';
import { Separator } from '../Separator/Separator';
import { getTenantId, TENANT_ID } from '../../utils/tenantUtil';

const OverviewList = ({
  history,
  headline,
  isLoading,
  listData = [],
  className,
  listType,
  buttonLabel,
  buttonLink,
}) => {
  const listClasses = classnames('list', {
    [`${className}`]: !!className,
  });

  const { setList } = useContext(ParticipantsListContext);
  const { setId } = useContext(EventIdContext);

  const [removeNewTagArticle] = useMutation(REMOVE_NEW_TAG_FROM_ARTICLE_MUTATION);
  const [removeNewTagEvents] = useMutation(REMOVE_NEW_TAG_FROM_EVENT_MUTATION);

  async function handleClick(e, { path, id }) {
    setList(false);
    setId(id);
    if (!id || !path) return;

    if (listType === CONSTANTS.OVERVIEW_LIST_TYPES.article) {
      await removeNewTagArticle({
        variables: {
          articleId: id,
        },
      });
    }

    if (listType === CONSTANTS.OVERVIEW_LIST_TYPES.event) {
      await removeNewTagEvents({
        variables: {
          eventId: id,
        },
      });
    }

    e.preventDefault();
    let _path = path;
    if (path.charAt(0) !== '/') _path = `/${path}`;
    history.push(_path);
  }

  const getDateString = (date) => {
    return date ? `Veröffentlicht am ${formatDate(date)}` : null;
  };

  return (
    <div className={listClasses}>
      <OuterBox>
        <SubTitle text={headline} notFramed />
        <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
        {isLoading && <LoadingIndicator className="list-loading-indicator" />}
        {!isLoading && (
          <div className="list-group">
            {listData.map((listItem, index) => {
              const { id, creationDate, tags, title, path } = listItem;

              const isNew = isNewEntry(tags);
              const listItemClasses = classnames('list-group-item', {
                'list-group-item--new': isNew,
              });

              const dateInfo = getDateString(creationDate);

              return (
                <div
                  key={path}
                  onClick={(e) => handleClick(e, { path, id })}
                  className="overview-list-item">
                  <div key={headline + index} className={listItemClasses}>
                    <div className="list-group-item__meta-data">
                      {dateInfo && <div className="list-group-item__date">{dateInfo}</div>}
                      <Tags tags={tags} />
                    </div>
                    <div className="list-group-item__excerpt">
                      <Truncate lines={2} ellipsis="">
                        {title}
                      </Truncate>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <ActionButton link={buttonLink} title={buttonLabel} inverted />
      </OuterBox>
    </div>
  );
};

OverviewList.defaultProps = {
  listType: CONSTANTS.OVERVIEW_LIST_TYPES.event,
};

OverviewList.propTypes = {
  headline: string,
  className: string,
  listType: string,
  listData: array,
  buttonLabel: string.isRequired,
  buttonLink: string.isRequired,
  isLoading: bool,
};

export default withRouter(OverviewList);
