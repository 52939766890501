import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './GuestExtension.sass';
import PropTypes from 'prop-types';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';

const FormQuestionInput = ({ values, errors, touched, handleBlur, handleChange }) => {
  return (
    <div className="guest-extension__extra-info-wrapper">
      <SubTitle text="Weitere Angaben" notFramed />

      <div>
        Sie haben noch Fragen oder Anmerkungen? Dann können Sie uns hier eine Nachricht
        hinterlassen:
      </div>
      <FormFieldWrapper className="guest-extension__question-input-wrapper">
        <ThemedTextField
          fullWidth
          id="questionInput"
          name="questionInput"
          label="Nachricht hinterlassen"
          variant="standard"
          value={values.questionInput}
          onChange={handleChange}
          onBlur={handleBlur}
          multiline
          rows={6}
          rowsMax={6}
          error={touched.questionInput && Boolean(errors.questionInput)}
          helperText={touched.questionInput && errors.questionInput}
        />
      </FormFieldWrapper>
    </div>
  );
};

FormQuestionInput.propTypes = {
  values: PropTypes.shape({
    questionInput: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    questionInput: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    questionInput: PropTypes.bool,
  }).isRequired,
};

export { FormQuestionInput };
