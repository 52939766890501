import { MapTo } from '@adobe/aem-spa-component-mapping';
import { Utils } from '@adobe/aem-react-editable-components';
import React, { useEffect, useState } from 'react';
import './InstallerStatusbar.sass';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import {
  EIV_ENTRY_TYPE_TO_GERMAN_NAME,
  EIV_STATUS_TO_GERMAN_NAME,
  getInstallerStatusbarData,
} from 'utils/DataUtils';
import { ReactComponent as GasEntry } from '../../assets/pics/gas_entry.svg';
import { ReactComponent as EnergyEntry } from '../../assets/pics/energy_entry.svg';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const InstallerStatusbar = () => {
  const { installer, isLoading, isEIV } = useCurrentInstaller();
  const [installerData, setInstallerData] = useState({});

  useEffect(() => {
    if (!installer) return;
    const installerDataResponse = getInstallerStatusbarData(installer);
    setInstallerData(installerDataResponse);
  }, [installer]);

  const isEditor = Utils.isInEditor();

  const { id, name, gasEntry, energyEntry, limitationEntry } = installerData;

  const getTitle = () => {
    if (isEditor) {
      return 'Test Installer';
    }
    if (isLoading) return '';
    if (isEIV === false)
      return 'Statusanzeige Installateur - Sie sind nicht im Installateurverzeichnis als Installateur hinterlegt';

    return name || '';
  };

  const generateRow = ({
    icon,
    branch,
    status,
    expireDate,
    entryType,
    companyType,
    shouldExtend,
  }) => (
    <tr>
      <td data-label="Sparte">
        <div className="installer-statusbar__table-row-branch">
          {icon}
          <div>{branch}</div>
        </div>
      </td>
      <td data-label="Status">{EIV_STATUS_TO_GERMAN_NAME[status]}</td>
      <td
        data-label="Gültig bis"
        className={shouldExtend ? 'installer-statusbar__table-row-expired' : ''}>
        <div>
          <div>{expireDate}</div>
          {shouldExtend && (
            <div className="installer-statusbar__table-row-expired-text">
              Bitte verlängern Sie Ihre Eintragung
            </div>
          )}
        </div>
      </td>
      <td data-label="Art der Eintragung">{EIV_ENTRY_TYPE_TO_GERMAN_NAME[entryType]}</td>
      <td data-label="Betriebsart">{companyType}</td>
    </tr>
  );

  const generateLimitationsTable = ({ energy, gas }) => (
    <table className="installer-statusbar__table installer-statusbar__table-special">
      <tbody>
        {energy && (
          <tr>
            <td>Beschränkung Strom</td>
            <td>{energy}</td>
          </tr>
        )}
        {gas && (
          <tr>
            <td>Beschränkung Gas</td>
            <td>{gas}</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const generateInstallerIdTable = (id) => (
    <table className="installer-statusbar__table installer-statusbar__table-special">
      <tbody>
        <tr>
          <td>Installateurnummer</td>
          <td>{id}</td>
        </tr>
      </tbody>
    </table>
  );

  const generateStatusTable = (energyEntry, gasEntry) => (
    <table className="installer-statusbar__table">
      <thead>
        <tr>
          <th>Sparte</th>
          <th>Status</th>
          <th>Gültig bis</th>
          <th>Art der Eintragung</th>
          <th>Betriebsart</th>
        </tr>
      </thead>
      <tbody>
        {energyEntry &&
          generateRow({
            ...energyEntry,

            icon: <EnergyEntry width={30} height={30} />,
          })}
        {gasEntry &&
          generateRow({
            ...gasEntry,

            icon: <GasEntry width={30} height={30} />,
          })}
      </tbody>
    </table>
  );
  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="InstallerStatusBar" />;
  }
  return (
    <div className="installer-statusbar installer-statusbar__outer-container">
      <div className="installer-statusbar__content-container">
        <div>
          <SubTitle text={getTitle()} notFramed />
        </div>
        <div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {id && generateInstallerIdTable(id)}
              {(energyEntry || gasEntry) && generateStatusTable(energyEntry, gasEntry)}
              {limitationEntry && generateLimitationsTable(limitationEntry)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/installerstatusbar'])(InstallerStatusbar);

InstallerStatusbar.displayName = 'InstallerStatusbar';

InstallerStatusbar.propTypes = {};

InstallerStatusbar.defaultProps = {};

export { InstallerStatusbar };
