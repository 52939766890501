/* eslint-disable react/prop-types */
import React from 'react';
import Modal from 'react-modal';
import './CustomModal.sass';
import '../global/multiThemingStyling/multiClientGlobalVariables.scss';
import '../global/style/BaseTenantStyle.sass';
import { getTenantId } from 'utils/tenantUtil';
import { useMediaQuery } from 'usehooks-ts';

const CustomModal = ({ title, content, closeCancelEventModal = () => {}, isOpen, ctaButtons }) => {
  const matches = useMediaQuery('(min-width: 500px)');

  const customStyles = {
    content: {
      top: '50%',
      left: matches ? '50%' : '0%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-30%',
      transform: matches ? 'translate(-50%, -50%)' : 'translate(0, -50%)',
      maxWidth: 'min(100vw, 666px)',
      maxHeight: '85%',
    },
  };

  return (
    <div className={`modal`}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeCancelEventModal}
        style={customStyles}
        portalClassName="modal base-tenant-style"
        ariaHideApp={false}>
        <div className={`custom-modal ${getTenantId()}`}>
          <h2 className="custom-modal__title">{title}</h2>
          <div className="custom-modal__content">{content}</div>
          {ctaButtons && <div className="custom-modal__btn">{ctaButtons}</div>}
        </div>
      </Modal>
    </div>
  );
};

export { CustomModal };
