import React from 'react';
import PropTypes from 'prop-types';
import './FormExpertListDeleteField.sass';
import { FormFieldWrapper } from './Wrapper/MyData/FormFieldWrapper';
import { FormSwitchField } from './FormSwitchField';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';

const FormExpertListDeleteField = (props) => {
  const { installer, error, index, onDeleteSwitch, handleBlur, handleChange } = props;
  return (
    <>
      <FormSwitchField
        checked={!!installer.isDeleted}
        onChange={(e) => onDeleteSwitch(index, e)}
        onBlur={handleBlur}
        name={`installers.${index}.isDeleted`}
      />
      <FormFieldWrapper className="form-expert-list-delete-field__wrapper">
        <div
          className={`form-expert-list-delete-field ${
            installer.isDeleted ? 'form-expert-list-delete-field__active' : ''
          }`}>
          Fachkraft löschen
        </div>
        <ThemedTextField
          fullWidth
          disabled={!installer.isDeleted}
          id={`installers.${index}.deletedReason`}
          name={`installers.${index}.deletedReason`}
          label="Grund der Löschung"
          variant="standard"
          value={installer.deletedReason}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error && Boolean(error.deletedReason)}
          helperText={error && error.deletedReason}
        />
        <ThemedTextField
          fullWidth
          disabled={!installer.isDeleted}
          id={`installers.${index}.deletedDate`}
          name={`installers.${index}.deletedDate`}
          label="Firmenaustritt am"
          variant="standard"
          value={installer.deletedDate}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error && Boolean(error.deletedDate)}
          helperText={error && error.deletedDate}
        />
      </FormFieldWrapper>
    </>
  );
};

FormExpertListDeleteField.propTypes = {
  installer: PropTypes.shape({
    isDeleted: PropTypes.bool,
    deletedDate: PropTypes.string,
    deletedReason: PropTypes.string,
  }).isRequired,
  error: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
  index: PropTypes.number,
  onDeleteSwitch: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { FormExpertListDeleteField };
