import React from 'react';
import './FormExpertListWrapper.sass';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FormExpertListWrapper = (props) => {
  const classes = classnames('form-expert-list-wrapper', {
    [`${props.className}`]: !!props.className,
  });
  return <div className={classes}>{props.children}</div>;
};

FormExpertListWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { FormExpertListWrapper };
