import * as Yup from 'yup';
import { YMainInstaller } from './YMainInstaller';
import { CONSTANTS } from 'global/constants';
import { VALIDATION_CONSTANTS } from 'utils/FormUtils';

export const YMainExtension = Yup.object().shape({
  installers: Yup.array(YMainInstaller),
  hasChanges: Yup.bool(),
  changes: Yup.string().when(['hasChanges'], {
    is: true,
    then: Yup.string()
      .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
      .max(CONSTANTS.FREETEXT_INPUT, VALIDATION_CONSTANTS.TOO_LONG)
      .required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  isAddressPublic: Yup.bool(),
  canSendMail: Yup.bool(),
});
