/* eslint-disable react/prop-types */
import React, { useState, createContext, useMemo, useContext } from 'react';

const NESteps = createContext({});

export const useNESteps = () => {
  return useContext(NESteps);
};

export const NEStepsContext = ({ children }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [submittedValues, setSubmittedValues] = useState({});

  const contextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      submittedValues,
      setSubmittedValues,
    }),
    [activeStep, setActiveStep, submittedValues, setSubmittedValues]
  );

  return <NESteps.Provider value={contextValue}>{children}</NESteps.Provider>;
};
