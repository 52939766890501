import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './FormDocumentsUpload.sass';
import PropTypes from 'prop-types';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { FileInputSection } from 'components/FileInputSection/FileInputSection';
import { prepFilesForUpload } from 'utils/FileUtil';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import { getBaseUrl } from 'utils/UrlUtil';

const DOCUMENTS_PATHS = {
  [TENANT_ID.EDI]: '/pdfs/MainExtension/EDIS_documents_matrix.pdf',
  [TENANT_ID.SHNG]: '/pdfs/MainExtension/SHNG_documents_matrix.pdf',
  [TENANT_ID.AVA]: '/pdfs/MainExtension/AVA_documents_matrix.pdf',
  [TENANT_ID.BAG]: '/pdfs/MainExtension/BAG_documents_matrix.pdf',
};

const FormDocumentsUpload = ({ setFiles, files }) => {
  const documentPath = DOCUMENTS_PATHS[getTenantId()];

  const onFileChange = async (event) => {
    const newFiles = event.currentTarget.files;
    if (newFiles) {
      const newFilesB64 = await prepFilesForUpload(newFiles);

      setFiles([...files, ...newFilesB64]);
    }
  };

  const onFileRemove = (index) => {
    const newFiles = files.filter((_, iindex) => iindex !== index);
    setFiles([...newFiles]);
  };

  const downloadDocumentsMatrix = async () => {
    const path = getBaseUrl() + documentPath;

    var tempLink = document.createElement('a');
    tempLink.href = path;
    tempLink.setAttribute('download', `Hilfestellung_Dokumente_${getTenantId()}.pdf`);
    tempLink.click();
  };

  return (
    <div className="form-documents-upload__extra-info-wrapper">
      <SubTitle text="Dokumente" notFramed />

      <div>Bitte laden Sie die notwendigen Unterlagen hoch.</div>
      <div>Folgende Formate werden beim Upload unterstützt: PDF, JPG, JPEG, GIF, PNG</div>
      <div className="form-documents-upload__small-text">
        Hilfestellung dazu finden sich in diesem{' '}
        <div className="form-documents-upload__download-text" onClick={downloadDocumentsMatrix}>
          Dokument{' '}
          <img
            src={require('../../assets/pics/pdf-download.png')}
            className="form-documents-upload__pdf-download-img"
          />
        </div>
      </div>

      <FormFieldWrapper>
        <FileInputSection
          actionButtonTitle="Datei(en) auswählen..."
          id={`files`}
          onFileChange={onFileChange}
          onFileRemove={onFileRemove}
          files={files}
        />
      </FormFieldWrapper>
    </div>
  );
};

FormDocumentsUpload.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
};

export { FormDocumentsUpload };
