const { useMemo } = require('react');
const { getInstallerStatusSummary } = require('utils/DataUtils');

export const useInstallerStatusSummary = (installer) =>
  useMemo(() => {
    if (!installer) {
      return { installerSummary: undefined };
    }
    return getInstallerStatusSummary(installer);
  }, [installer]);
