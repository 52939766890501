import React from 'react';
import './FormSwitchField.sass';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Switch } from '@material-ui/core';

const FormSwitchField = (props) => {
  const { className, title, disabled, error, checked, onChange, name, onBlur, rowDirection } =
    props;
  const classes = classnames('form-switch-field', {
    [`${className}`]: !!className,
    'form-switch-field__row-direction': rowDirection,
  });
  return (
    <div className={classes}>
      <Switch
        className="form-switch-field__switch"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      />
      {error && <div className="form-switch-field__error-text">{error}</div>}
      {title && (
        <div
          className={`form-switch-field${rowDirection ? '__switch-text-left' : '__switch-text'} `}>
          {title}
        </div>
      )}
    </div>
  );
};

FormSwitchField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  rowDirection: PropTypes.bool,
};

FormSwitchField.defaultProps = {
  disabled: false,
};

export { FormSwitchField };
