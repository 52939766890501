import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './FormActionButtons.sass';
import { HintText } from 'components/HintText/HintText';
import { HINTTEXT_TYPE } from 'components/HintText/HintTextType';

const FormActionButtons = ({
  hasErrors,
  titleLeft,
  titleRight,
  onClickLeft,
  onClickRight,
  errorMessage,
}) => {
  return (
    <>
      {hasErrors && errorMessage && (
        <div className="form-action-buttons__error-hint">
          <HintText type={HINTTEXT_TYPE.ERROR}>{errorMessage}</HintText>
        </div>
      )}
      <div className="form-action-buttons__buttons-wrap">
        <ActionButton inverted onClick={onClickLeft} title={titleLeft}></ActionButton>
        <ActionButton
          inverted
          onClick={onClickRight}
          type="submit"
          title={titleRight}
          disabled={hasErrors}></ActionButton>
      </div>
    </>
  );
};

FormActionButtons.propTypes = {
  hasErrors: PropTypes.bool,
  titleLeft: PropTypes.string.isRequired,
  titleRight: PropTypes.string.isRequired,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  errorMessage: PropTypes.string || PropTypes.object,
};

FormActionButtons.displayName = 'FormActionButtons';

FormActionButtons.defaultProps = {
  hasErrors: false,
};

export { FormActionButtons };
