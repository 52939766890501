import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

export const HelpTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(Tooltip);
