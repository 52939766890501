import React, { useEffect, useState } from 'react';
import './MainExtension.sass';
import { MapTo } from '@adobe/aem-react-editable-components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useMutation } from '@apollo/client';
import { SEND_MAIN_EXTENSION_FORM, SET_SUBSCRIPTION_STATUS_MUTATION } from 'Queries/Mutations';
import { prepMainExtensionData, prepareMainExtensionInitialData } from 'utils/DataUtils';
import { MainExtensionForm } from './MainExtensionForm';
import { useCurrentExperts } from 'hooks/UseCurrentExperts';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import {
  createPatchEinwilligungByInstallerIdData,
  patchEinwilligungByInstallerId,
} from 'api/installer/PatchEinwilligungByInstallerId';
import { isLocal, isStaging } from 'utils/StageUtils';

export const MainExtension = () => {
  const [initialData, setInitialData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { installer, isLoading: isInstallerLoading } = useCurrentInstaller();
  const { experts, isLoading: isExpertsLoading } = useCurrentExperts(
    !isInstallerLoading && installer?.id
  );
  const [response, setResponse] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [setSubscriptionStatus] = useMutation(SET_SUBSCRIPTION_STATUS_MUTATION);

  useEffect(() => {
    (async () => {
      if (!isInstallerLoading && installer && !isExpertsLoading && experts) {
        try {
          const data = prepareMainExtensionInitialData(installer, experts);

          setInitialData(data);
          setIsLoading(false);
        } catch (e) {
          console.log('main extension installer data error', e);
        }
      }
    })();
  }, [installer, experts, isInstallerLoading, isExpertsLoading]);

  const [sendMainExtensionForm] = useMutation(SEND_MAIN_EXTENSION_FORM);
  const [openSubmitPopup, setOpenSubmitPopup] = useState(false);

  const onExpertListSubmit = async (values) => {
    setButtonDisabled(true);
    setResponse('');
    const prepedValues = prepMainExtensionData(initialData, values);

    try {
      const response = await patchEinwilligungByInstallerId(
        installer.id,
        createPatchEinwilligungByInstallerIdData(
          prepedValues.dsgvoAddressPublic.second,
          prepedValues.dsgvoCanSendMail.second
        )
      );

      const responseSetSubscriptionStatus = setSubscriptionStatus({
        variables: { status: prepedValues.dsgvoCanSendMail.second },
      });

      if (response.errors || responseSetSubscriptionStatus.errors) {
        throw new Error(response.errors || responseSetSubscriptionStatus.errors);
      }
    } catch (e) {
      console.log(e);
    }

    const formData = {
      ...prepedValues,
    };

    try {
      const response = await sendMainExtensionForm({
        variables: {
          formData: formData,
        },
      });

      if (response.errors) {
        throw new Error(response.errors);
      }
      setOpenSubmitPopup(true);
      if (isLocal()) {
        setButtonDisabled(false);
      }

      if (isStaging()) {
        alert('Formular wird an efix.eon@gmail.com gesendet');
      }
    } catch (e) {
      console.log(e);
      setResponse('Fehler beim Speichern');
      setButtonDisabled(false);
    }
  };

  return (
    <div className="main-extension main-extension__container">
      <div className="main-extension__inner-container">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <MainExtensionForm
              initialValues={initialData}
              onSubmit={onExpertListSubmit}
              buttonDisabled={buttonDisabled}
              response={response}
              openSubmitPopup={openSubmitPopup}
              setOpenSubmitPopup={setOpenSubmitPopup}
            />
          </>
        )}
      </div>
    </div>
  );
};

MainExtension.displayName = 'MainExtension';

MainExtension.propTypes = {};

MainExtension.defaultProps = {};

MapTo(['e-fix/components/content/mainextension'])(MainExtension);
