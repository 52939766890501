import PropTypes from 'prop-types';

export const PFile = PropTypes.shape({
  name: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  base64: PropTypes.string.isRequired,
});

export const PFiles = PropTypes.arrayOf(
  PropTypes.shape({
    gasFiles: PropTypes.arrayOf(PFile),
    energyFiles: PropTypes.arrayOf(PFile),
  })
);

export const PGuestExtensionInstaller = PropTypes.shape({
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  birthdate: PropTypes.string,
  energyValidityDate: PropTypes.string,
  gasValidityDate: PropTypes.string,
  isGas: PropTypes.bool,
  isEnergy: PropTypes.bool,
  isDeleted: PropTypes.bool,
  deletedDate: PropTypes.string,
  deletedReason: PropTypes.string,
});
