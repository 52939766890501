import React from 'react';
import './FormFieldWrapper.sass';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FormFieldWrapper = (props) => {
  const classes = classnames('form-field-wrapper', {
    [`${props.className}`]: !!props.className,
  });
  return <div className={classes}>{props.children}</div>;
};

FormFieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { FormFieldWrapper };
