import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import { FormContactInfoTitleSection } from 'components/Form/FormContactInfoTitleSection';

const FormCompanyData = ({ values }) => {
  return (
    <FormSectionWrapper>
      <div className="main-extension__input-part-1">
        <SubTitle text="Firmendaten" notFramed />
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            disabled
            id="companyName1"
            name="companyName1"
            label="Firmenname1"
            variant="standard"
            value={values.companyName1}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            disabled
            id="companyName2"
            name="companyName2"
            label="Firmenname2"
            variant="standard"
            value={values.companyName2}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            disabled
            id="companyName3"
            name="companyName3"
            label="Firmenname3"
            variant="standard"
            value={values.companyName3}
          />
        </FormFieldWrapper>
        {getTenantId() === TENANT_ID.BAG && (
          <FormFieldWrapper className="main-extension__min-height-70">
            <ThemedTextField
              fullWidth
              disabled
              id="companyOwner"
              name="companyOwner"
              label="Firmeninhaber"
              variant="standard"
              value={values.companyOwner}
            />
          </FormFieldWrapper>
        )}

        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyStreet"
            name="companyStreet"
            label="Straße"
            variant="standard"
            value={values.companyStreet}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyHouseNumber"
            name="companyHouseNumber"
            label="Hausnummer"
            variant="standard"
            value={values.companyHouseNumber}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyPostal"
            name="companyPostal"
            label="PLZ"
            variant="standard"
            value={values.companyPostal}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyCity"
            name="companyCity"
            label="Ort"
            variant="standard"
            value={values.companyCity}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyRegion"
            name="companyRegion"
            label="Ortsteil"
            variant="standard"
            value={values.companyRegion || ''}
            disabled
          />
        </FormFieldWrapper>
      </div>
      <div className="main-extension__input-part-2">
        <FormContactInfoTitleSection />
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            disabled
            id="email"
            name="email"
            label="E-mail Adresse"
            variant="standard"
            value={values.email}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="phone"
            name="phone"
            label="Telefon"
            variant="standard"
            value={values.phone}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="mobile"
            name="mobile"
            label="Mobiltelefon"
            variant="standard"
            value={values.mobile}
            disabled
          />
        </FormFieldWrapper>
      </div>
    </FormSectionWrapper>
  );
};

FormCompanyData.propTypes = {
  values: PropTypes.shape({
    companyName1: PropTypes.string.isRequired,
    companyName2: PropTypes.string.isRequired,
    companyName3: PropTypes.string.isRequired,
    companyOwner: PropTypes.string.isRequired,
    companyStreet: PropTypes.string.isRequired,
    companyHouseNumber: PropTypes.string.isRequired,
    companyPostal: PropTypes.string.isRequired,
    companyCity: PropTypes.string.isRequired,
    companyRegion: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    mobile: PropTypes.string,
  }).isRequired,
};

export { FormCompanyData };
