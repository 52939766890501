import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import React, { useState } from 'react';

import { ExpertSearchDropdownCity } from './edis/ExpertSearchDropdownCity';
import { ExpertSearchDropdownExpert } from './edis/ExpertSearchDropdownExpert';
import './ExpertSearch.sass';
import { SubTitle } from '../SubTitle/SubTitle';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { YExpertSearchDialog } from './edis/YExpertSearchDialog';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const ExpertSearch = (props) => {
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [error, setError] = useState('');

  const filterCity = (location) => {
    setCity(location.city);
    setDistrict(location.district);
  };

  const formik = useFormik({
    initialValues: { zip: '' },
    validationSchema: YExpertSearchDialog,
  });

  const { values, handleChange, handleBlur, touched, errors, isValid, resetForm } = formik;

  const onReset = () => {
    resetForm();
    setCity('');
    setDistrict('');
    setError('');
  };
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="ExpertSearch" />;
  }
  return (
    <div className={`expert-page`}>
      <div className="mdl-grid expert-page-content-sites">
        <div
          className={`expert-page-content-search${getTenantId() === TENANT_ID.BAG ? '__bag' : ''}`}>
          {error ? (
            <div className="expert-page-error-message">{error}</div>
          ) : (
            <div className="hidden"></div>
          )}
          <div className="expert-page-content-search-box">
            <SubTitle text={props.headline} notFramed />

            <div className="expert-search-dialog__form-field-wrapper">
              <div className="expert-search-dialog__zip-form-wrapper">
                <ThemedTextField
                  fullWidth
                  id="zip"
                  name="zip"
                  label="PLZ *"
                  variant="standard"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                />
              </div>

              {values.zip && isValid && (
                <ExpertSearchDropdownCity
                  zip={values.zip}
                  tile={props.tile}
                  backLink={filterCity}
                  errorBackLink={setError}
                />
              )}
            </div>

            {city.length > 0 ? (
              <ExpertSearchDropdownExpert
                zip={values.zip}
                city={city}
                district={district}
                tile={props.tile}
                errorBackLink={setError}
                onReset={onReset}
              />
            ) : (
              <div className="hidden"> </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExpertSearch.propTypes = {
  headline: PropTypes.string,
  tile: PropTypes.string,
};

ExpertSearch.defaultProps = {
  headline: 'Angaben zum XXX',
  tile: 'A',
};

MapTo(['e-fix/components/content/expertcontacts'])(ExpertSearch);
