import React, { useEffect, useState } from 'react';
import './NEStepForm.sass';
import { useNESteps } from 'context/NEStepsContext';
import { ACTIVE_STEP } from 'utils/NEUtis';
import { FormActionButtons } from 'components/DebFormComponents/FormActionButtons';

const NEStepForm = () => {
  const { activeStep, setActiveStep } = useNESteps();
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (activeStep !== ACTIVE_STEP.FORM) return null;

  const onBack = () => {
    setActiveStep(ACTIVE_STEP.BRANCH_SELCTION);
  };

  const onSubmit = () => {
    setActiveStep(ACTIVE_STEP.FORM_UPLOAD);
  };

  return (
    <>
      <div className="ne-step-form">NEStepForm works!</div>
      <div className="ne-form__action-buttons">
        <FormActionButtons
          hasErrors={hasErrors}
          titleLeft="Zurück"
          titleRight={activeStep === ACTIVE_STEP.FORM_UPLOAD ? 'Eintrag einreichen' : 'Weiter'}
          onClickLeft={onBack}
          onClickRight={onSubmit}
          errorMessage={errorMessage}
        />
      </div>
    </>
  );
};

export { NEStepForm };
