// local, development, staging, production
export const getStage = () => {
  return process.env.REACT_APP_STAGE;
};

// Has to be in sync with env variables
export const STAGE = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const DOMAIN_PREFIX = {
  LOCAL: 'dev.',
  DEVELOPMENT: 'dev.',
  STAGING: 'qa.',
  PRODUCTION: '',
};

export const DOMAIN_CIAM_LOGIN_PREFIX = {
  LOCAL: 'login-dev.',
  DEVELOPMENT: 'login-dev.',
  STAGING: 'login-qa.',
  PRODUCTION: 'login.',
};

export const getDomainPrefixByStage = () => {
  let prefix = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      prefix = DOMAIN_PREFIX.LOCAL;
      break;
    case STAGE.DEVELOPMENT:
      prefix = DOMAIN_PREFIX.DEVELOPMENT;
      break;
    case STAGE.STAGING:
      prefix = DOMAIN_PREFIX.STAGING;
      break;
    case STAGE.PRODUCTION:
      prefix = DOMAIN_PREFIX.PRODUCTION;
      break;
  }
  return prefix;
};

export const getDomainCIAMLoginPrefixByStage = () => {
  let prefix = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.LOCAL;
      break;
    case STAGE.DEVELOPMENT:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.DEVELOPMENT;
      break;
    case STAGE.STAGING:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.STAGING;
      break;
    case STAGE.PRODUCTION:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.PRODUCTION;
      break;
  }
  return prefix;
};

export const isLocal = () => {
  return getStage() === 'local';
};

export const isDevelopment = () => {
  return getStage() === 'development';
};

export const isStaging = () => {
  return getStage() === 'staging';
};

export const isProduction = () => {
  return getStage() === 'production';
};
