import React from 'react';

import './FormDataPolicyData.sass';
import PropTypes from 'prop-types';
import { DataPolicyContent } from 'components/DataPolicy/DataPolicyContent';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const DOCUMENTS = {
  [TENANT_ID.EDI]: [
    { link: '/pdfs/DataPolicy/edis_Information_DSGVO.pdf', label: 'Datenschutz E.Dis' },
  ],
  [TENANT_ID.SHNG]: [
    {
      link: '/pdfs/DataPolicy/shng_sh-netz_datenschutz_installateure.pdf',
      label: 'Datenschutz SH-Netz',
    },
    {
      link: '/pdfs/DataPolicy/shng_elbenergie_information_datenschutz_installateure.pdf',
      label: 'Datenschutz ElbEnergie',
    },
    {
      link: '/pdfs/DataPolicy/shng_nordnetz_datenschutz_installateure.pdf',
      label: 'Datenschutz Nordnetz',
    },
    {
      link: '/pdfs/DataPolicy/shng_hansegas_partner_information_datenschutz_gas.pdf',
      label: 'Datenschutz Hansegas',
    },
  ],
  [TENANT_ID.AVA]: [
    {
      link: '/pdfs/DataPolicy/AVA_Installateurinformation_EU-DSGVO.pdf',
      label: 'Datenschutz Avacon',
    },
  ],
  [TENANT_ID.BAG]: [
    { link: '/pdfs/DataPolicy/BAG_DS_Hinweise_2023-05-12.pdf', label: 'Datenschutz Bayernwerk' },
  ],
};

const FormDataPolicyData = ({ values, handleBlur, handleChange }) => {
  return (
    <div className="form-data-policy-data__input-wrapper">
      <div className="form-data-policy-data__input-container">
        <DataPolicyContent
          dsgvoAddressPublic={values.isAddressPublic}
          dsgvoCanSendMail={values.canSendMail}
          dsvgoAddressPublicOnBlur={handleBlur}
          dsvgoCanSendMailOnBlur={handleBlur}
          dsgvoAddressPublicName="isAddressPublic"
          dsgvoCanSendMailName="canSendMail"
          onChangeDsvgoAddressPublic={handleChange}
          onChangeDsvgoCanSendMail={handleChange}
          documents={DOCUMENTS[getTenantId()]}
        />
      </div>
    </div>
  );
};

FormDataPolicyData.propTypes = {
  values: PropTypes.shape({
    isAddressPublic: PropTypes.bool.isRequired,
    canSendMail: PropTypes.bool.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export { FormDataPolicyData };
