import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxRow.sass';
import { FormSection } from './FormSection';
import { getBaseUrl } from 'utils/UrlUtil';
import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
export function CheckboxRow({
  name,
  id,
  value,
  label,
  title,
  onChange,
  documentLink,
  error,
  editedHighlight,
  disabled,
  hoverText,
}) {
  let isEdited = false;

  if (editedHighlight) {
    isEdited = value === true;
  }

  return (
    <div className="checkbox-row">
      <FormSection subtitle={title}>
        <LabelCheckbox
          tooltipText={hoverText}
          disabled={disabled}
          isEdited={isEdited}
          error={error}
          id={id || name}
          name={name}
          value={value}
          onChange={onChange}
          label={
            <>
              <span>{label}</span>{' '}
              {documentLink && (
                <a className={'download-item'} href={getBaseUrl() + documentLink} download>
                  Link zu den Dateien
                </a>
              )}
            </>
          }
        />
      </FormSection>
    </div>
  );
}
CheckboxRow.defaultProps = {
  disabled: false,
};

CheckboxRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  documentLink: PropTypes.string,
  error: PropTypes.bool,
  editedHighlight: PropTypes.bool,
  disabled: PropTypes.bool,
  hoverText: PropTypes.string,
};
