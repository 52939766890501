import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';

const FormCraftsChamberData = ({ values }) => {
  return (
    <div>
      <SubTitle text="Eintragung bei der Handwerkskammer" notFramed />
      <FormSectionWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="craftsChamberArea"
            name="craftsChamberArea"
            label="ausgestellt"
            variant="standard"
            value={values.craftsChamberArea}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="craftsChamberNumber"
            name="craftsChamberNumber"
            label="Betriebsnummer"
            variant="standard"
            value={values.craftsChamberNumber}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__extra-data-field">
          <ThemedTextField
            fullWidth
            disabled
            id="craftsChamberDate"
            name="craftsChamberDate"
            label="Karte ausgestellt am"
            variant="standard"
            value={values.craftsChamberDate}
          />
        </FormFieldWrapper>
      </FormSectionWrapper>
    </div>
  );
};

FormCraftsChamberData.propTypes = {
  values: PropTypes.shape({
    craftsChamberArea: PropTypes.string.isRequired,
    craftsChamberNumber: PropTypes.string.isRequired,
    craftsChamberDate: PropTypes.string.isRequired,
  }).isRequired,
};

export { FormCraftsChamberData };
