import React from 'react';
import './FormWrapper.sass';
import PropTypes from 'prop-types';

const FormWrapper = (props) => {
  return <div className="form-wrapper">{props.children}</div>;
};

FormWrapper.propTypes = {
  children: PropTypes.node,
};

export { FormWrapper };
