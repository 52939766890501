import React from 'react';
import PropTypes from 'prop-types';

import '../../global/style/BaseTenantStyle.sass';
import '../../global/multiThemingStyling/multiClientGlobalVariables.scss';
import { CustomModal } from 'components/CustomModal';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './FormPopup.sass';
import { getMyDataPath } from 'utils/UrlUtil';
export const FormPopup = ({ openSubmitPopup, setOpenSubmitPopup }) => {
  return (
    <CustomModal
      isOpen={openSubmitPopup}
      title="Antrag erfolgreich gesendet!"
      content={
        <div className="form-popup__content">
          Wir werden ihren Antrag prüfen und ihre Änderungen vornehmen. <br />
          <br />
          Falls wir Rückfragen haben, werden wir Sie in der Regel per E-Mail kontaktieren und bei
          Bedarf Dokumente nachfordern. <br />
          <br />
          Die Bearbeitung wird einige Zeit in Anspruch nehmen.
        </div>
      }
      ctaButtons={
        <ActionButton
          title={<span className="form-popup__cta-button-ok">OK</span>}
          link={getMyDataPath()}
          onClick={() => {
            setOpenSubmitPopup(false);
          }}
          inverted
        />
      }
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"></CustomModal>
  );
};

FormPopup.propTypes = {
  openSubmitPopup: PropTypes.bool,
  setOpenSubmitPopup: PropTypes.func,
};
