import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './MainExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { FormExpertListWrapper } from 'components/Form/Wrapper/MyData/FormExpertListWrapper';
import { FormExpertListDeleteField } from 'components/Form/FormExpertListDeleteField';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';

const FormExpertList = ({ values, errors, handleBlur, handleChange, onDeleteSwitch }) => {
  return (
    <>
      <FormSectionWrapper>
        <div className="main-extension__input-part-1">
          <SubTitle text="Fachkräfte" notFramed />
          {values.installers.length > 0 &&
            values.installers.map((installer, index) => (
              <FormExpertListWrapper key={index}>
                <FormFieldWrapper>
                  <ThemedTextField
                    fullWidth
                    id={`installers.${index}.firstname`}
                    name={`installers.${index}.firstname`}
                    disabled
                    label="Vorname"
                    variant="standard"
                    value={values.installers[index].firstname}
                  />
                </FormFieldWrapper>
                <FormFieldWrapper>
                  <ThemedTextField
                    fullWidth
                    disabled
                    id={`installers.${index}.lastname`}
                    name={`installers.${index}.lastname`}
                    label="Name"
                    variant="standard"
                    value={values.installers[index].lastname}
                  />
                </FormFieldWrapper>
                {getTenantId() === TENANT_ID.BAG && (
                  <FormFieldWrapper>
                    <ThemedTextField
                      fullWidth
                      disabled
                      id={`installers.${index}.birthdate`}
                      name={`installers.${index}.birthdate`}
                      label="Geburtsdatum"
                      variant="standard"
                      value={values.installers[index].birthdate}
                    />
                  </FormFieldWrapper>
                )}
                <FormFieldWrapper>
                  <div className="main-extension__work-area-inner-wrapper">
                    <div className="main-extension__work-area">Gas</div>{' '}
                    {values.installers[index].isGas ? (
                      <CheckCircleOutlineIcon className="main-extension__check_yes" />
                    ) : (
                      <CancelOutlinedIcon className="main-extension__check-no" />
                    )}
                    {values.installers[index].isGas && (
                      <>
                        <div>Ausweis gültig bis:</div>
                        <div>{values.installers[index].gasValidityDate}</div>
                      </>
                    )}
                  </div>
                </FormFieldWrapper>
                <FormFieldWrapper>
                  <div className="main-extension__work-area-inner-wrapper">
                    <div className="main-extension__work-area">Strom</div>{' '}
                    {values.installers[index].isEnergy ? (
                      <CheckCircleOutlineIcon className="main-extension__check_yes" />
                    ) : (
                      <CancelOutlinedIcon className="main-extension__check-no" />
                    )}
                    {values.installers[index].isEnergy && (
                      <>
                        <div>Ausweis gültig bis:</div>
                        <div>{values.installers[index].energyValidityDate}</div>
                      </>
                    )}
                  </div>
                </FormFieldWrapper>
                <div className="main-extension__delete-field-wrapper">
                  <FormExpertListDeleteField
                    installer={values.installers[index]}
                    error={errors.installers && errors.installers[index]}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    index={index}
                    onDeleteSwitch={onDeleteSwitch}
                  />
                </div>
              </FormExpertListWrapper>
            ))}
        </div>
      </FormSectionWrapper>
    </>
  );
};

FormExpertList.propTypes = {
  values: PropTypes.shape({
    installers: PropTypes.arrayOf(
      PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        birthdate: PropTypes.string,
        isGas: PropTypes.bool,
        isEnergy: PropTypes.bool,
        energyValidityDate: PropTypes.string,
        gasValidityDate: PropTypes.string,
        isDeleted: PropTypes.bool,
        deletedDate: PropTypes.string,
        deletedReason: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onDeleteSwitch: PropTypes.func.isRequired,
};

export { FormExpertList };
