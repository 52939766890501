import React, { useState } from 'react';
import './FileInputSection.sass';

import PropTypes from 'prop-types';
import { CONSTANTS } from 'global/constants';

export const FileInputSection = ({
  actionButtonTitle,
  onFileChange,
  files,
  onFileRemove,
  id,
  disabled,
}) => {
  const [unsupportedFilesCount, setUnsupportedFilesCount] = useState(0);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files).filter((file) =>
      CONSTANTS.ACCEPTED_FORM_INPUT_FILE_TYPES.includes(file.type)
    );
    setUnsupportedFilesCount(event.target.files.length - selectedFiles.length);

    const newEvent = {
      ...event,
      target: { ...event.target, files: selectedFiles },
      currentTarget: { ...event.currentTarget, files: selectedFiles },
    };
    onFileChange(newEvent);
  };

  return (
    <div className="file-input-section__field-wrapper">
      <div
        className={`file-input-section__file-upload-button ${
          disabled ? 'file-input-section__file-upload-button-disabled' : ''
        }`}
        onClick={() => {
          if (!disabled) {
            document.getElementById('file-upload' + id).click();
          }
        }}>
        {actionButtonTitle}
      </div>
      <input
        type="file"
        id={'file-upload' + id}
        className="file-input-section__file-input"
        accept={CONSTANTS.ACCEPTED_FORM_INPUT_FILE_TYPES.join(',')}
        multiple
        onChange={handleFileChange}
      />
      {unsupportedFilesCount > 0 && (
        <div className="file-input-section__warning-message-unsupported">
          Nicht unterstützte Dateitypen wurden gefiltert ({unsupportedFilesCount} Datein(en))
        </div>
      )}
      {!disabled && files.length > 0 && (
        <div className="file-input-section__file-input-list-wrapper">
          {files.map((file, index) => (
            <div key={index} className="file-input-section__file-input-list-item">
              <div className="file-input-section__file-input-list-item-text">{file.name}</div>
              <div
                className="file-input-section__file-input-list-item-remove"
                onClick={() => onFileRemove(index)}>
                X
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

FileInputSection.displayName = 'FileInputSection';

FileInputSection.propTypes = {
  id: PropTypes.string.isRequired,
  actionButtonTitle: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  files: PropTypes.array,
  onFileRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FileInputSection.defaultProps = {
  files: [],
  disabled: false,
};
