import React from 'react';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import PropTypes from 'prop-types';
import './InputField.sass';
export function InputField(props) {
  const { oldValue, infoText, ...filteredProps } = props;
  return (
    <div className="input-field">
      <ThemedTextField
        className={props.error ? 'error' : oldValue != props.value ? 'edited' : 'normal'}
        {...filteredProps}></ThemedTextField>
      {infoText && (
        <div className={`info-text ${props.disabled ? 'info-text__disabled' : ''}`}>{infoText}</div>
      )}
    </div>
  );
}

InputField.propTypes = {
  oldValue: PropTypes.string,
  value: PropTypes.string,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
