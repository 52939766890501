import { MapTo } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import TwoColumnsLayout from '../../Layouts/TwoColumnLayout/TwoColumnLayout';
import {
  GET_ARTICLES_OVERVIEW_DATA_QUERY,
  GET_EVENTS_OVERVIEW_DATA_QUERY,
} from '../../Queries/Queries';
import { CONSTANTS } from '../../global/constants';
import OverviewList from '../../components/OverviewLists/OverviewList';
import { SubscribeBox } from '../SubscribeBox/SubscribeBox.jsx';
import './EventsAndArticlesOverview.scss';
import { PageTitleSection } from '../../components/PageTitleSection/PageTitleSection';
import { TENANT_ID, getTenantId, isBUofCurrentTenant } from '../../utils/tenantUtil.js';
import { getArticlesOverviewPath, getEventsOverviewPath } from 'utils/UrlUtil';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';

const DATA = {
  [TENANT_ID.EDI]: {
    title: 'News & Veranstaltungen',
    subText:
      'Hier finden Sie alle Fachbeiträge und Veranstaltungen rund um Strom, Gas und übergreifende Themen.',
    subTitleArticles: 'Aktuelle Fachbeiträge',
    actionButtonArticles: 'Alle Fachbeiträge',
    subTitleEvents: 'Aktuelle Veranstaltungen',
    actionButtonEvents: 'Alle Veranstaltungen',
  },
  [TENANT_ID.SHNG]: {
    title: 'News & Veranstaltungen',
    subText:
      'Hier finden Sie alle Fachbeiträge und Veranstaltungen rund um Strom, Gas und übergreifende Themen.',
    subTitleArticles: 'Aktuelle Fachbeiträge',
    actionButtonArticles: 'Alle Fachbeiträge',
    subTitleEvents: 'Aktuelle Veranstaltungen',
    actionButtonEvents: 'Alle Veranstaltungen',
  },
  [TENANT_ID.AVA]: {
    title: 'News & Veranstaltungen',
    subText:
      'Hier finden Sie alle Nachrichten und Veranstaltungen zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Avacon und aktuellen Themen.',
    subTitleArticles: 'Aktuelle Nachrichten',
    actionButtonArticles: 'Alle Nachrichten',
    subTitleEvents: 'Aktuelle Veranstaltungen',
    actionButtonEvents: 'Alle Veranstaltungen',
  },
  [TENANT_ID.BAG]: {
    title: 'News & Veranstaltungen',
    subText:
      'Hier finden Sie alle Nachrichten und Veranstaltungen zu technischen Anforderungen, dem Installateurportal, Besonderheiten im Netz der Bayernwerk und aktuellen Themen.',
    subTitleArticles: 'Aktuelle Nachrichten',
    actionButtonArticles: 'Alle Nachrichten',
    subTitleEvents: 'Aktuelle Veranstaltungen',
    actionButtonEvents: 'Alle Veranstaltungen',
  },
};

const EventsAndArticlesOverview = () => {
  const { installer, isLoading: isInstallerLoading, isEIV } = useCurrentInstaller();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const CONTENT = DATA[getTenantId()];

  const { loading: articlesLoading, data: articlesData } = useQuery(
    GET_ARTICLES_OVERVIEW_DATA_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { loading: eventsLoading, data: eventData } = useQuery(GET_EVENTS_OVERVIEW_DATA_QUERY, {
    fetchPolicy: 'network-only',
  });

  const leftColumnContent = (
    <>
      <PageTitleSection title={CONTENT.title} subText={CONTENT.subText} notFramed />

      <OverviewList
        className="events-and-articles-overview__list"
        headline={CONTENT.subTitleArticles}
        listData={articlesData?.articles}
        isLoading={articlesLoading}
        listType={CONSTANTS.OVERVIEW_LIST_TYPES.article}
        buttonLabel={CONTENT.actionButtonArticles}
        buttonLink={getArticlesOverviewPath()}
      />
      <OverviewList
        className="events-and-articles-overview__list"
        headline={CONTENT.subTitleEvents}
        listData={eventData?.events}
        isLoading={eventsLoading}
        buttonLabel={CONTENT.actionButtonEvents}
        buttonLink={getEventsOverviewPath()}
      />
    </>
  );

  const rightColumnContent =
    (!isInstallerLoading && installer && isBUofCurrentTenant(installer.pflegende_bu)) || !isEIV ? (
      <SubscribeBox isEIV={isEIV} />
    ) : null;

  return <TwoColumnsLayout leftColumn={leftColumnContent} rightColumn={rightColumnContent} />;
};

MapTo('e-fix/components/content/newsandeventsoverview')(EventsAndArticlesOverview);
