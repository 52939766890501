import React from 'react';
import { SubTitle } from 'components/SubTitle/SubTitle';
import './GuestExtension.sass';
import PropTypes from 'prop-types';
import { FormSectionWrapper } from 'components/Form/Wrapper/MyData/FormSectionWrapper';
import { FormFieldWrapper } from 'components/Form/Wrapper/MyData/FormFieldWrapper';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { TENANT_ID, getTenantId } from 'utils/tenantUtil';
import { FormContactInfoTitleSection } from 'components/Form/FormContactInfoTitleSection';

const FormCompanyData = ({ values, touched, errors, handleBlur, handleChange }) => {
  return (
    <FormSectionWrapper>
      <div className="guest-extension__input-part-1">
        <SubTitle text="Firmendaten" notFramed />
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyName1"
            name="companyName1"
            label="Firmenname*"
            variant="standard"
            value={values.companyName1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyName1 && Boolean(errors.companyName1)}
            helperText={touched.companyName1 && errors.companyName1}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyName2"
            name="companyName2"
            label="Firmenname2"
            variant="standard"
            value={values.companyName2}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyName2 && Boolean(errors.companyName2)}
            helperText={touched.companyName2 && errors.companyName2}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyName3"
            name="companyName3"
            label="Firmenname3"
            variant="standard"
            value={values.companyName3}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyName3 && Boolean(errors.companyName3)}
            helperText={touched.companyName3 && errors.companyName3}
          />
        </FormFieldWrapper>
        {getTenantId() === TENANT_ID.BAG && (
          <FormFieldWrapper className="guest-extension__min-height-70">
            <ThemedTextField
              fullWidth
              id="companyOwner"
              name="companyOwner"
              label="Firmeninhaber"
              variant="standard"
              value={values.companyOwner}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.companyOwner && Boolean(errors.companyOwner)}
              helperText={touched.companyOwner && errors.companyOwner}
            />
          </FormFieldWrapper>
        )}
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyStreet"
            name="companyStreet"
            label="Straße*"
            variant="standard"
            value={values.companyStreet}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyStreet && Boolean(errors.companyStreet)}
            helperText={touched.companyStreet && errors.companyStreet}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyHouseNumber"
            name="companyHouseNumber"
            label="Hausnummer*"
            variant="standard"
            value={values.companyHouseNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyHouseNumber && Boolean(errors.companyHouseNumber)}
            helperText={touched.companyHouseNumber && errors.companyHouseNumber}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyPostal"
            name="companyPostal"
            label="PLZ*"
            variant="standard"
            value={values.companyPostal}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyPostal && Boolean(errors.companyPostal)}
            helperText={touched.companyPostal && errors.companyPostal}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyCity"
            name="companyCity"
            label="Ort*"
            variant="standard"
            value={values.companyCity}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyCity && Boolean(errors.companyCity)}
            helperText={touched.companyCity && errors.companyCity}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="companyRegion"
            name="companyRegion"
            label="Ortsteil"
            variant="standard"
            value={values.companyRegion || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.companyRegion && Boolean(errors.companyRegion)}
            helperText={touched.companyRegion && errors.companyRegion}
          />
        </FormFieldWrapper>
      </div>
      <div className="guest-extension__input-part-2">
        <FormContactInfoTitleSection />
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            disabled
            id="email"
            name="email"
            label="E-mail Adresse*"
            variant="standard"
            value={values.email}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="phone"
            name="phone"
            label="Telefon"
            variant="standard"
            value={values.phone}
            disabled
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="main-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="mobile"
            name="mobile"
            label="Mobiltelefon"
            variant="standard"
            value={values.mobile}
            disabled
          />
        </FormFieldWrapper>
        <SubTitle text="Netzbetreiber Strom" notFramed />
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="gridProviderEnergy"
            name="gridProviderEnergy"
            label="Netzbetreiber Strom"
            variant="standard"
            value={values.gridProviderEnergy}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.gridProviderEnergy && Boolean(errors.gridProviderEnergy)}
            helperText={touched.gridProviderEnergy && errors.gridProviderEnergy}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="foreignCompanyIdEnergy"
            name="foreignCompanyIdEnergy"
            label="Ausweisnummer"
            variant="standard"
            value={values.foreignCompanyIdEnergy}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.foreignCompanyIdEnergy && Boolean(errors.foreignCompanyIdEnergy)}
            helperText={touched.foreignCompanyIdEnergy && errors.foreignCompanyIdEnergy}
          />
        </FormFieldWrapper>
        <SubTitle text="Netzbetreiber Gas" notFramed />
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="gridProviderGas"
            name="gridProviderGas"
            label="Netzbetreiber Gas"
            variant="standard"
            value={values.gridProviderGas}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.gridProviderGas && Boolean(errors.gridProviderGas)}
            helperText={touched.gridProviderGas && errors.gridProviderGas}
          />
        </FormFieldWrapper>
        <FormFieldWrapper className="guest-extension__min-height-70">
          <ThemedTextField
            fullWidth
            id="foreignCompanyIdGas"
            name="foreignCompanyIdGas"
            label="Ausweisnummer"
            variant="standard"
            value={values.foreignCompanyIdGas}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.foreignCompanyIdGas && Boolean(errors.foreignCompanyIdGas)}
            helperText={touched.foreignCompanyIdGas && errors.foreignCompanyIdGas}
          />
        </FormFieldWrapper>
      </div>
    </FormSectionWrapper>
  );
};

FormCompanyData.propTypes = {
  values: PropTypes.shape({
    companyName1: PropTypes.string.isRequired,
    companyName2: PropTypes.string,
    companyName3: PropTypes.string,
    companyOwner: PropTypes.string.isRequired,
    companyStreet: PropTypes.string.isRequired,
    companyHouseNumber: PropTypes.string.isRequired,
    companyPostal: PropTypes.string.isRequired,
    companyCity: PropTypes.string.isRequired,
    companyRegion: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    gridProviderEnergy: PropTypes.string,
    foreignCompanyIdEnergy: PropTypes.string,
    gridProviderGas: PropTypes.string,
    foreignCompanyIdGas: PropTypes.string,
  }).isRequired,
  touched: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.array])).isRequired,
  errors: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export { FormCompanyData };
