import { VALIDATION_CONSTANTS } from 'utils/FormUtils';
import { YDate } from 'utils/YupUtils';
import * as Yup from 'yup';

export const YMainInstaller = Yup.object().shape({
  isDeleted: Yup.boolean(),
  deletedDate: YDate,
  deletedReason: Yup.string()
    .min(3, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(100, VALIDATION_CONSTANTS.TOO_LONG),
});
