/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import './css/Subnav.sass';
import { isExternal } from '../utils/UrlUtil';
import { getTenantId, TENANT_ID } from '../utils/tenantUtil';

const SubnavButton = ({ link, name, clickCookieDisclaimer }) => {
  const handleOnClick = () => {
    name === 'Cookie Disclaimer' && clickCookieDisclaimer();
  };

  const getName = () => {
    if (name === 'Cookie Disclaimer') {
      return getTenantId() === TENANT_ID.AVA ? 'Cookie Einstellungen' : 'Cookie Disclaimer';
    }

    return name;
  };

  return isExternal(link) ? (
    <a href={link} className="subnav-button-wrapper">
      <button onClick={handleOnClick} className="subnav-button">
        {getName()}
      </button>
    </a>
  ) : (
    <Link to={{ pathname: link }} className="subnav-button-wrapper">
      <button onClick={handleOnClick} className="subnav-button">
        {getName()}
      </button>
    </Link>
  );
};

export default SubnavButton;
