import React from 'react';

import './NeForm.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import PropTypes from 'prop-types';
import { NEStepsContainer } from 'components/NEComponents/NEStepsContainer/NEStepsContainer';
import { NEStepEmailInput } from 'components/NEComponents/NESteps/NEStepEmailInput/NEStepEmailInput';
import { NEStepBranchSelection } from 'components/NEComponents/NESteps/NEStepBranchSelection/NEStepBranchSelection';
import { NEStepForm } from 'components/NEComponents/NESteps/NEStepForm/NEStepForm';
import { NEStepFormUpload } from 'components/NEComponents/NESteps/NEStepFormUpload/NEStepFormUpload';
import { ACTIVE_STEP } from 'utils/NEUtis';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

export const NeForm = (props) => {
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="NewEntryForm" />;
  }
  return (
    <div className="ne-form">
      <div className="ne-form__container">
        <NEStepsContainer>
          <NEStepEmailInput key={ACTIVE_STEP.EMAIL_INPUT} />
          <NEStepBranchSelection key={ACTIVE_STEP.BRANCH_SELCTION} />
          <NEStepForm key={ACTIVE_STEP.FORM} />
          <NEStepFormUpload key={ACTIVE_STEP.FORM_UPLOAD} />
        </NEStepsContainer>
      </div>
    </div>
  );
};

NeForm.displayName = 'NeForm';

NeForm.propTypes = {
  expertQualificationCertificateGasLink: PropTypes.string,
  expertQualificationCertificateEnergyLink: PropTypes.string,
  installerContractMeterInstallationGasLink: PropTypes.string,
  installerContractMeterInstallationEnergyLink: PropTypes.string,
  installerContractSealing: PropTypes.string,
  installerContractLink: PropTypes.string,
  installerContractLinkEENG: PropTypes.string,
  installerContractLinkNNNG: PropTypes.string,
  installerContractLinkHANSG: PropTypes.string,
  selfDeclarationCompanyFacilityLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  energyAgreementLink: PropTypes.string,
  datapolicyEDILink: PropTypes.string,
  datapolicyAVALink: PropTypes.string,
  datapolicySHNGLink: PropTypes.string,
  datapolicyBAGLink: PropTypes.string,
  datapolicyEENGLink: PropTypes.string,
  datapolicyHANSGLink: PropTypes.string,
  datapolicyNNNGLink: PropTypes.string,
  datapolicyEDILabel: PropTypes.string,
  datapolicyAVALabel: PropTypes.string,
  datapolicySHNGLabel: PropTypes.string,
  datapolicyBAGLabel: PropTypes.string,
  datapolicyEENGLabel: PropTypes.string,
  datapolicyHANSGLabel: PropTypes.string,
  datapolicyNNNGLabel: PropTypes.string,
};

NeForm.defaultProps = {};

MapTo(['e-fix/components/content/newentry'])(NeForm);
