/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import OuterBox from '../../OuterBox/OuterBox';
import Pagination from '../../Pagination/Pagination';
import EventCard from '../EventCard/EventCard';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { SubTitle } from '../../SubTitle/SubTitle';
import { Separator } from '../../Separator/Separator';
import { getTenantId, TENANT_ID } from '../../../utils/tenantUtil';
import { parse, format } from 'date-fns';
import { getRelevantEventDay } from 'utils/EventDateUtils';
import { Utils } from '@adobe/aem-react-editable-components';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const EventsOverview = ({
  eventData,
  loading,
  setPaginationVars,
  currentPage,
  totalPages,
  isPastEvent,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="EventsOverview" />;
  }
  const renderPagination = () => {
    const lastEventDay =
      eventData &&
      eventData[eventData.length - 1] &&
      getRelevantEventDay(eventData[eventData.length - 1].eventDays);

    const lastEventDate =
      lastEventDay &&
      parse(lastEventDay.date + lastEventDay.endTime, 'yyyy-MM-ddHH:mm', new Date());

    const firstEventDay = eventData[0] && getRelevantEventDay(eventData[0].eventDays);

    const firstEventDate =
      firstEventDay &&
      parse(firstEventDay.date + firstEventDay.startTime, 'yyyy-MM-ddHH:mm', new Date());

    const eventsNumber = eventData?.length;

    return (
      <Pagination
        selectVarsForPag={setPaginationVars}
        lastItemDate={lastEventDate && format(lastEventDate, "yyyy-MM-dd'T'HH:mm:ss")}
        firstItemDate={firstEventDate && format(firstEventDate, "yyyy-MM-dd'T'HH:mm:ss")}
        totalPages={totalPages}
        itemsNumber={eventsNumber}
        currentPage={currentPage}
        text={'Veranstaltungen pro Seite'}
      />
    );
  };

  return (
    <>
      <OuterBox>
        {loading && <LoadingIndicator />}
        {!loading && (
          <>
            <SubTitle text="Veranstaltungen" notFramed />
            <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
            <div>
              {eventData.map((data) => (
                <EventCard key={data.id} data={data} isPastEvent={isPastEvent} />
              ))}
            </div>
          </>
        )}
      </OuterBox>
      {setPaginationVars && renderPagination()}
    </>
  );
};

export default EventsOverview;
