/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import '../../../global/style/pages.sass';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import OuterBox from '../../OuterBox/OuterBox';
import ArticleCard from '../ArticleCard/ArticleCard';
import Pagination from '../../Pagination/Pagination';
import { Separator } from 'components/Separator/Separator';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { getTenantId, TENANT_ID } from 'utils/tenantUtil';

const ArticleOverview = ({
  data: articleData,
  loading,
  setPaginationVars,
  totalPages,
  headline,
  currentPage,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const lastArticleDate =
    articleData &&
    articleData[articleData.length - 1] &&
    articleData[articleData.length - 1].creationDate;

  const firstArticleDate = articleData[0]?.creationDate;
  const articlesNumber = articleData?.length;

  return (
    <>
      <OuterBox>
        {loading && <LoadingIndicator />}
        {!loading && (
          <>
            <SubTitle text={headline} notFramed />
            <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
            <div>
              {articleData.map((data) => (
                <ArticleCard key={data.id} article={data} />
              ))}
            </div>
          </>
        )}
      </OuterBox>
      <Pagination
        selectVarsForPag={setPaginationVars}
        lastItemDate={lastArticleDate}
        firstItemDate={firstArticleDate}
        totalPages={totalPages}
        itemsNumber={articlesNumber}
        currentPage={currentPage}
        text={'Fachbeiträge pro Seite'}
      />
    </>
  );
};

export default ArticleOverview;
